import { merge } from 'lodash';
import {
  defaultFormData
} from '../../pages/stores/StoreLocalization/components/VariablesForm/default-values';
import { ActionCreator } from '../actions/actions-types';
import { useGet, usePut } from '../fetch/fetch-resolver';
import { setStoreLocalization } from './store-localization-actions';
import { StoreLocalization } from './store-localization-types';

export const useGetStoreLocalization = (storeId: number) => {
  return useGet<StoreLocalization>({
    url: `stores/${storeId}/localization`,
    version: 'v2',
    action: (data => setStoreLocalization({
      storeId,
      data: merge({}, defaultFormData, data)
    })) as ActionCreator,
    selector: (state) => storeId === state.storeLocalization?.storeId
      ? state.storeLocalization.data
      : undefined,
  });
};

export const usePutStoreLocalization = (storeId: number) => {
  return usePut({
    url: `stores/${storeId}/localization`,
    version: 'v2',
  });
};