import styled from 'styled-components';
import { IonBackButton, IonButtons } from '@ionic/react';

export const BackButtonContainer = styled(IonButtons).attrs({
  slot: 'start'
})`
  margin-top: 10px;
  margin-bottom: auto;
  margin-left: 2.5%;
`;

export const BackButton = styled(IonBackButton)`
  margin-bottom: 10px;
`;

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;