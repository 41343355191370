import React from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { SubmitButton, Centered } from '../../styles';
import { BusinessEntityItem } from '../BusinessEntityItem';
import { BusinessEntity } from '../../../../../features/business-entities/business-entities-types';
import { usePutMergeBusinesses } from '../../../../../features/business-entities/business-entities-resolver';
import { useHistory } from 'react-router-dom';
import { Modal } from './styles';

interface MergeConfirmationModalProps {
  sourceBusinessEntityId: string;
  targetBusinessId: string;
  businessEntities: BusinessEntity[];
  isOpen: boolean;
  onClose: () => void;
}

const MergeConfirmationModal: React.FC<MergeConfirmationModalProps> = ({
  sourceBusinessEntityId,
  targetBusinessId,
  businessEntities,
  isOpen,
  onClose
}) => {
  const history = useHistory();
  
  const [
    {
      isLoading: isPutMergeBusinessesLoading,
      error: putMergeBusinessesError
    },
    putMergeBusinesses
  ] = usePutMergeBusinesses();

  const handleClose = () => {
    onClose();
  }

  const handleConfirmation = async () => {
      const response = await putMergeBusinesses({
        targetBusinessId,
        businessEntityIds: businessEntities.map(businessEntity => businessEntity.id)
      });
      
      if (response.errorCode) {
        return;
      }

      history.push(`/business-entities/${sourceBusinessEntityId}/business`);
  }
  
  return (
    <Modal isOpen={isOpen} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Confirm Merge</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {businessEntities
          .map(businessEntity => (
            <BusinessEntityItem
              key={businessEntity.id}
              businessEntity={businessEntity}
            />
          ))}
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <Centered style={{ flexDirection: 'row' }}>
            <SubmitButton
              color="danger"
              onClick={handleClose}
            >
              Cancel
            </SubmitButton>
            <SubmitButton
              color="success"
              onClick={handleConfirmation}
            >
              Confirm Merge
            </SubmitButton>
          </Centered>
        </IonToolbar>
      </IonFooter>

      <IonAlert
        isOpen={!!putMergeBusinessesError}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading
        isOpen={isPutMergeBusinessesLoading}
      />
    </Modal>
  );
};

export default MergeConfirmationModal;
