import { IonImg, IonLabel } from "@ionic/react";
import { Brand } from "../../../../../../../features/brands/brands-types";
import { BrandItemContainer, Checkbox, CheckboxContainer, Thumbnail } from "./styles";

type BrandProps = {
  brand: Brand;
  checked: boolean;
  onCheckChange: (value: boolean) => void;
}

const BrandItem: React.FC<BrandProps> = ({ brand, checked, onCheckChange }) => {
  return (
    <BrandItemContainer>
      <Thumbnail>
        <IonImg src={brand.image} />
      </Thumbnail>
      <CheckboxContainer>
        <Checkbox
          checked={checked}
          onIonChange={(e) => onCheckChange(e.detail.checked)}
        />
        <IonLabel>{brand.name}</IonLabel>
      </CheckboxContainer>
    </BrandItemContainer>
  );
};

export default BrandItem;