import { IonSearchbar } from "@ionic/react";
import styled from "styled-components";

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const Searchbar = styled(IonSearchbar)`
  margin-bottom: auto;
`;