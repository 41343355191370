import {
  getPlatforms
} from '@ionic/react';

export const useIsDesktopPlatform = () => {
  const platforms = getPlatforms();
  return platforms.includes('desktop');
}

export const usePaginationMode = () => {
  const isDesktopPlatform = useIsDesktopPlatform();

  return isDesktopPlatform ? 'pagination' : 'infinite';
};