import styled from "styled-components";
import { IonItem, IonCol, IonCheckbox } from '@ionic/react';

export const Item = styled(IonItem)`
  --background: transparent;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  width: 100%;
`;

export const Column = styled(IonCol)`
  margin: 10px;
`;

export const ItemText = styled.p`
  padding: 0px;
  margin: 5px 0;
  overflow-wrap: break-word;
`;

export const TextLabel = styled.span`
  font-weight: bold;
`;

export const Checkbox = styled(IonCheckbox)`
  margin-left: 15px;
  margin-right: 0;
`;
