import { brandsActionTypes } from './brands-actions';
import { Brand } from './brands-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

type BrandsStore = {
  all: Brand[];
  store: {
    id: string | undefined;
    data: Brand[];
  };
};

export const brandsInitialState = (): BrandsStore => ({
  all: [],
  store: {
    id: undefined,
    data: []
  },
});

export const brandsReducer = (
  state = brandsInitialState(),
  action: Action<BrandsStore>,
): any => {
  switch (action.type) {
    case brandsActionTypes.SET_ALL_BRANDS: {
      return {
        ...state,
        all: action.payload
      };
    }

    case brandsActionTypes.SET_STORE_BRANDS: {
      return {
        ...state,
        store: action.payload
      };
    }

    case brandsActionTypes.CLEAR_BRANDS:
    case actionTypes.CLEAR_STATE:
      return brandsInitialState();

    default:
      return state;
  }
};
