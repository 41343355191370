import { format, parseISO } from "date-fns";
import { BusinessEntityWithDetails } from "../../../../../features/business-entities/business-entities-types";
import { EditBusinessButton, EditBusinessIcon } from "../../../../businesses/BusinessDetails/styles";
import { TextLabel, Column, ColumnTitle, Row, Text, SectionHeader } from "./styles";

interface BusinessInfoProps {
  businessEntityDetails: BusinessEntityWithDetails;
  entitiesCount: number;
  onOpenEditModal: () => void;
  isDesktopPlatform: boolean;
}

const BusinessInfo: React.FC<BusinessInfoProps> = ({
  businessEntityDetails,
  entitiesCount,
  onOpenEditModal,
  isDesktopPlatform
}) => {
  return (
    <Row>
      <Column>
        <SectionHeader>
          <ColumnTitle>Business Info</ColumnTitle>
          <EditBusinessButton
            isDesktopPlatform={isDesktopPlatform}
            onClick={onOpenEditModal}
            data-testid="edit-business-info-btn"
          >
            {isDesktopPlatform
              ? 'Edit'
              : <EditBusinessIcon isDesktopPlatform={false} />
            }
          </EditBusinessButton>
        </SectionHeader>
        <Text data-testid="b-id">
          <TextLabel>ID</TextLabel>: {businessEntityDetails.businessId}
        </Text>
        <Text data-testid="b-name">
          <TextLabel>Name</TextLabel>: {businessEntityDetails.businessName}
        </Text>
        <Text data-testid="b-created-at">
          <TextLabel>Created at</TextLabel>: {format(parseISO(businessEntityDetails.businessCreatedAt), 'MM/dd/yyyy')}
        </Text>
        <Text data-testid="b-expected-locations-count">
          <TextLabel>Expected # of Locations</TextLabel>: {businessEntityDetails.businessExpectedLocationsCount || 'N/A'}
        </Text>
        <Text data-testid="b-entities-count">
          <TextLabel># of Entities</TextLabel>: {entitiesCount}
        </Text>
      </Column>
    </Row>
  );
}

export default BusinessInfo;