import { IonSearchbar, IonButton } from "@ionic/react";
import styled from "styled-components";
import { IonCol, IonRow } from '@ionic/react';

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const Searchbar = styled(IonSearchbar)`
  margin-bottom: auto;
`;

export const BorderedIonCol = styled(IonCol)`
  border: 0.5px solid var(--ion-color-light-tint);
  padding: 10px;
`;

export const SubmitButtonContainer = styled.div.attrs({
  className: 'ion-padding'
})`
  display: flex;
  align-items: center;
  justify-content: end;

  width: 100%;
  gap: 8px;
  margin: 0 0 16px 0;
`;

export const SubmitButton = styled(IonButton)`
  max-width: 500px;
  flex-grow: 1;
  text-transform: uppercase;
`;

export const FinancialInstitutionsContainer = styled.div.attrs({
  className: 'ion-padding',
})`
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  padding-top: 5px;
  width: 100%;
  max-width: 100%;
`;


export const FinancialInstitutionsButtonsContainer = styled.div`
  display: flex;
  gap: 20px;

  width: 100%;

  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
  }
`;

export const CleanButton = styled(IonButton)`
  max-width: 150px;
  font-size: 12px;
  flex-grow: 1;
`;

export const FilterButton = styled(IonButton)`
  max-width: 200px;
  flex-grow: 1;
  text-transform: uppercase;
`;

export const RowTop = styled(IonRow)`
  background: var(--plink-blue);
  border: 1px;
  border-radius: 10px 10px 0 0;
`

export const HeaderColumn = styled(IonCol)`
  font-family: 'Montserrat', serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  font-weight: 700;
  text-align: center;

  font-size: 14px;
  padding: 4px 0 4px 0;

  span {
    font-family: 'Montserrat', serif;
    font-size: 10px;
    text-transform: uppercase;
  }

  &:not(:last-child) {
    border-right: 0.5px solid var(--ion-color-light-tint);
  }
`;
