import { ActionCreator } from '../actions/actions-types';

export const financialInstitutionsActionTypes = {
  SET_FINANCIAL_INSTITUTIONS: 'setFinancialInstitutions',
  SET_FINANCIAL_INSTITUTIONS_ALL: 'setFinancialInstitutionsAll',
  SET_SELECTED_FINANCIAL_INSTITUTION: 'setSelectedFinancialInstitution',
  CLEAR_FINANCIAL_INSTITUTION: 'clearFinancialInstitution',
  SET_FINANCIAL_INSTITUTIONS_SEARCH_QUERY: 'setFinancialInstitutionsSearchQuery',
  UPDATE_SELECTED_FINANCIAL_INTITUTION_INFO: 'updateSelectedFinancialInstitutionInfo',
  SET_RESOLUTION_TYPES: 'setResolutionTypes',
};

export const setFinancialInstitutions: ActionCreator = (payload) => ({
  type: financialInstitutionsActionTypes.SET_FINANCIAL_INSTITUTIONS,
  payload: {
    list: payload,
  }
});

export const setAllFinancialInstitutions: ActionCreator = (payload) => ({
  type: financialInstitutionsActionTypes.SET_FINANCIAL_INSTITUTIONS_ALL,
  payload: {
    listAll: payload,
  }
});

export const setSelectedFinancialInstitution: ActionCreator = (payload) => ({
  type: financialInstitutionsActionTypes.SET_SELECTED_FINANCIAL_INSTITUTION,
  payload: {
    selected: payload,
  }
});

export const updateSelectedFinancialInstituionInfo: ActionCreator = (payload) => ({
  type: financialInstitutionsActionTypes.UPDATE_SELECTED_FINANCIAL_INTITUTION_INFO,
  payload: {
    updatedFinancialInstitution: payload,
  }
});

export const clearFinancialInstitutions: ActionCreator = () => ({
  type: financialInstitutionsActionTypes.CLEAR_FINANCIAL_INSTITUTION,
});

export const setFinancialInstitutionsSearchQuery: ActionCreator = (payload) => ({
  type: financialInstitutionsActionTypes.SET_FINANCIAL_INSTITUTIONS_SEARCH_QUERY,
  payload: {
    searchQuery: payload,
  }
});

export const setResolutionTypes: ActionCreator = (payload) => ({
  type: financialInstitutionsActionTypes.SET_RESOLUTION_TYPES,
  payload: {
    resolutionTypes: payload,
  }
});
