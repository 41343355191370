import { IonBackButton, IonButton, IonButtons, IonModal, IonSearchbar } from "@ionic/react";
import styled from "styled-components";

export const BackButtonContainer = styled(IonButtons).attrs({
  slot: 'start'
})`
  margin-top: 10px;
  margin-bottom: auto;
  margin-left: 2.5%;
`;

export const BackButton = styled(IonBackButton)`
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 18px;
  margin-left: 16px;
`;

export const SearchBar = styled(IonSearchbar)`
  margin-bottom: auto;
`;

export const SubmitButton = styled(IonButton)`
  text-transform: capitalize;
  min-width: 150px;
`;

export const Centered = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;