import { useGet } from '../fetch/fetch-resolver';
import { setCampaignSets } from './campaign-sets-actions';
import { CampaignSet } from './campaign-sets-types';

export const useGetCampaignSets = () => {
  return useGet<CampaignSet[]>({
    url: 'campaign-sets',
    version: 'v2',
    action: setCampaignSets,
    selector: (state) => state.campaignSets,
  });
};

