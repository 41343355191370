import styled from "styled-components";
import { StoreItem } from "../../../StoresList/styles";

export const StoreFile = styled(StoreItem)`
  margin-top: 10px;
  margin-left: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;