import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast
} from '@ionic/react';
import { Modal } from './styles';
import { useDeleteStoreFile, usePostStoreFile } from '../../../../../features/stores/stores-resolver';
import { useAppState } from '../../../../../shared/AppContext';
import { Store, StoreFileType } from '../../../../../features/stores/stores-types';
import StoreFileItem from './components/StoreFileItem';
import { updateStore } from '../../../../../features/stores/stores-actions';

const FILE_SIZE_LIMIT = 1024 * 1024 // 1MB

interface StoreFilesModalProps {
  store: Store | undefined;
  onClose: () => void;
}

const StoreFilesModal: React.FC<StoreFilesModalProps> = ({
  store,
  onClose
}) => {
  const { dispatch: appStateDispatch } = useAppState();
  
  const [presentToast] = useIonToast();

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const [updatedFiles, setUpdatedFiles] = useState<{
    logo: string | null;
    primary: string | null;
    secondary: string | null;
  }>({
    logo: null,
    primary: null,
    secondary: null,
  });

  const [{ isLoading: isPostStoreFileLoading }, postStoreFile] =
    usePostStoreFile(store?.id || 0);

  const [{ isLoading: isDeleteStoreFileLoading }, deleteStoreFile] =
    useDeleteStoreFile(store?.id || 0);

  useEffect(() => {
    setUpdatedFiles({
      logo: store?.files.logo || null,
      primary: store?.files.primary || null,
      secondary: store?.files.secondary || null,
    });
  }, [store]);

  const handleClose = () => {
    onClose();
  }

  const handleSelectedFile = (fileType: StoreFileType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const file = event.target.files[0];

      if (file.size > FILE_SIZE_LIMIT) {
        setFormErrorMessage('File too large. Max file size allowed: 1MB');
        return;
      }

      const handler = updatedFiles[fileType] ? replaceFile : addFile;
      handler(fileType, file);
    }
  };

  const addFile = async (fileType: StoreFileType, file: File) => {
    try {
      const formData = new FormData();
      formData.append('type', fileType);
      formData.append('file', file);
      const response = await postStoreFile(formData, { isFormData: true });
      
      if (response.errorCode) {
        throw new Error(response.message);
      }

      presentToast({
        color: 'success',
        message: 'File updated!',
        position: 'top',
        duration: 3000
      });

      setUpdatedFiles(fs => ({
        ...fs,
        [fileType]: response.url,
      }))
      
      appStateDispatch(updateStore({
        id: store?.id || 0,
        files: {
          ...updatedFiles,
          [fileType]: response.url,
        }
      }));
    } catch (err) {
      setFormErrorMessage((err as any).message);
    }
  }

  const removeFile = (fileType: StoreFileType) => async (notify: boolean = true) => {
    try {
      const response = await deleteStoreFile({ type: fileType });
      
      if (response.errorCode) {
        throw new Error(response.message);
      }
      
      if (notify) presentToast({
        color: 'success',
        message: 'File removed',
        position: 'top',
        duration: 3000
      });

      setUpdatedFiles(fs => ({
        ...fs,
        [fileType]: null,
      }))
      
      appStateDispatch(updateStore({
        id: store?.id || 0,
        files: {
          ...updatedFiles,
          [fileType]: null,
        }
      }));

      return true;
    } catch (err) {
      if (notify) setFormErrorMessage((err as any).message);

      return false;
    }
  }

  const replaceFile = async (fileType: StoreFileType, file: File) => {
    const removed = await removeFile(fileType)(false);
    if (removed) await addFile(fileType, file);
  }

  return (
    <Modal isOpen={!!store} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{`${store?.name} Store Images`}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {Object.values(StoreFileType).map(type => 
          <StoreFileItem
            key={type}
            type={type}
            src={updatedFiles[type]}
            onFileSelect={handleSelectedFile(type)}
            onRemove={removeFile(type)}
            isLoading={isPostStoreFileLoading || isDeleteStoreFileLoading}
          />
        )}
      </IonContent>

      <IonToast
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonLoading isOpen={isPostStoreFileLoading || isDeleteStoreFileLoading} />
    </Modal>
  );
};

export default StoreFilesModal;
