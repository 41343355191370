import { useGet, usePatch } from '../fetch/fetch-resolver';
import {
  setMerchantSuggestions,
  updateMerchantSuggestion
} from './merchant-suggestions-actions';
import {
  MerchantSuggestion,
  GroupedMerchantSuggestions,
  PatchRequest
} from './merchant-suggestions-types';
import { PaginatedResponse } from '../../types/api';
import { UseGetOptions } from '../fetch/fetch-types';
import { useAppState } from '../../shared/AppContext';

export const useGetMerchantSuggestions = (
  options = {} as Partial<UseGetOptions>
) => {
  return useGet<PaginatedResponse<GroupedMerchantSuggestions>>({
    url: 'merchant-suggestions',
    version: 'v2',
    action: setMerchantSuggestions,
    selector: (state) => state.merchantSuggestions,
    initialParams: {
      page: 1,
      limit: 30
    },
    ...options
  });
};

export const usePatchMerchantSuggestions = () => {
  return usePatch<PatchRequest>({
    url: 'merchant-suggestions',
    version: 'v2',
    action: updateMerchantSuggestion
  });
};

export const useMerchantSuggestionsByDescriptionId = (
  descriptionId: string
): MerchantSuggestion[] => {
  const { state } = useAppState();

  return state.merchantSuggestions.data
    ? state.merchantSuggestions.data[descriptionId]
    : [];
};
