import { IonImg } from "@ionic/react";
import React from "react";
import { Store } from "../../../../../features/stores/stores-types";
import {
  TextLabel,
  Column,
  ColumnTitle,
  Row,
  Text,
  StoreItem,
  LogoContainer,
  HeaderText,
  StoreButton,
  ButtonsContainer
} from "./styles";

interface StoresProps {
  businessId: number;
  stores: Store[];
  onOpenStoreDetailsModal: (store: Store) => void;
  onOpenStoreFilesModal: (store: Store) => void;
  onOpenStoreBrandsModal: (store: Store) => void;
  onOpenStoreDemographicsModal: (store: Store) => void;
  isDesktopPlatform: boolean;
}

const StoresList: React.FC<StoresProps> = ({
  businessId,
  stores,
  onOpenStoreDetailsModal,
  onOpenStoreFilesModal,
  onOpenStoreBrandsModal,
  onOpenStoreDemographicsModal,
  isDesktopPlatform
}) => {
  return (
    <Row>
      <Column>
        <ColumnTitle>Stores</ColumnTitle>

        {stores.length > 0
          ? stores.map(store => (
            <StoreItem key={store.id}>
              <HeaderText data-testid="business-stores-id">
                <TextLabel>Store ID</TextLabel>: {store.id}
              </HeaderText>
              {store.files.logo &&
                <LogoContainer>
                  <IonImg data-testid="business-stores-logo" src={store.files.logo} />
                </LogoContainer>
              }
              <Text data-testid="business-stores-name">
                <TextLabel>Name</TextLabel>: {store.name}
              </Text>
              <Text data-testid="business-stores-category">
                <TextLabel>Category</TextLabel>: {store.categoryName
                  ? `${store.categoryName} > ${store.subcategoryName}`
                  : store.subcategoryName || 'N/A'
                }
              </Text>
              <Text data-testid="business-stores-address">
                <TextLabel>Address</TextLabel>: {`${store.streetAddress}, ${store.city}, ${store.state} ${store.zipCode}`}
              </Text>

              <ButtonsContainer>
                <StoreButton
                  isDesktopPlatform={isDesktopPlatform}
                  onClick={() => onOpenStoreDetailsModal(store)}
                >
                  Details
                </StoreButton>
                <StoreButton
                  isDesktopPlatform={isDesktopPlatform}
                  onClick={() => onOpenStoreFilesModal(store)}
                >
                  Images
                </StoreButton>
                <StoreButton
                  isDesktopPlatform={isDesktopPlatform}
                  onClick={() => onOpenStoreBrandsModal(store)}
                >
                  Brands
                </StoreButton>
                <StoreButton
                  isDesktopPlatform={isDesktopPlatform}
                  routerLink={`/businesses/${businessId}/stores/${store.id}/localization/variables`}
                >
                  Localization
                </StoreButton>
                <StoreButton
                  isDesktopPlatform={isDesktopPlatform}
                  onClick={() => onOpenStoreDemographicsModal(store)}
                >
                  Demographics
                </StoreButton>
              </ButtonsContainer>
            </StoreItem>
          ))
          : <Text>No stores registered</Text>
        }
      </Column>
    </Row>
  );
}

export default StoresList;