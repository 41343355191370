import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useEffect } from 'react';
import { EditableFinancialInstitutionInfo } from '../../../../../features/financial-institutions/financial-institutions-types';

const SCROLL_DURATION = 800;

export interface FormValues {
  name: string;
  priority: number;
  ibEnrolledCount: number;
  resolutionType: string;
}

const financialInsitutionEditSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Invalid Name'),
  priority: yup
    .number()
    .typeError('Invalid Priority'),
  ibEnrolledCount: yup
    .number()
    .typeError('Invalid Internet Banking Enrolled Consumers'),
  resolutionType: yup
    .string()
    .typeError('Invalid Resolution Type'),
});

export const useFinancialInstitutionalForm = (
  setFormErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  modalContentRef: React.RefObject<HTMLIonContentElement>,
  fiiCurrentInfo: EditableFinancialInstitutionInfo | undefined,
) => {
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(financialInsitutionEditSchema),
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const errors = Object.keys(formErrors);
    if (errors.length > 0) {
      const modalContentElement = modalContentRef.current;
      if (modalContentElement) {
        modalContentElement.scrollToTop(SCROLL_DURATION);
      }
      setFormErrorMessage('Invalid data. Please verify all fields.');
    } else {
      setFormErrorMessage('');
    }
  }, [formErrors]);


  useEffect(() => {
    if (fiiCurrentInfo) {

      reset({
        name: fiiCurrentInfo?.name || '',
        priority: fiiCurrentInfo?.priority || 0,
        ibEnrolledCount: fiiCurrentInfo?.ibEnrolledCount || 0,
        resolutionType: fiiCurrentInfo?.resolutionType || '',
      });
    }
  }, [fiiCurrentInfo]);

  return {
    control,
    handleSubmit,
    reset,
  };
};
