import { IonSelectOption } from '@ionic/react';
import { selectValues } from '../../value-options';
import {
  FormLabel,
  Select,
  SelectContainer,
  Row,
  SelectButton,
} from './styles';

interface DefaultValuesSelectProps {
  value: string;
  onChange: (value: string) => void;
  onSetAll: () => void;
  isLoading: boolean;
  isDesktopPlatform: boolean;
}

const DefaultValuesSelect: React.FC<DefaultValuesSelectProps> = ({
  value,
  onChange,
  onSetAll,
  isLoading,
  isDesktopPlatform,
}) => (
  <SelectContainer isDesktopPlatform={isDesktopPlatform}>
    <FormLabel>Defaults</FormLabel>
    <Row>
      <Select
        placeholder=""
        value={value}
        onIonChange={e => onChange(e.detail.value)}
      >
        {selectValues.map(c => (
          <IonSelectOption key={c} value={c}>{c}</IonSelectOption>
        ))}
      </Select>
      <SelectButton
        type="button"
        onClick={onSetAll}
        disabled={isLoading}
      >
        Set All
      </SelectButton>
    </Row>
  </SelectContainer>
);

export default DefaultValuesSelect;