import { actionsInitialState } from '../features/actions/actions-reducer';
import { authInitialState } from '../features/auth/auth-reducer';
import { adminInitialState } from '../features/admin/admin-reducer';
import {
  merchantSuggestionsInitialState
} from '../features/merchant-suggestions/merchant-suggestions-reducer';
import { businessesInitialState } from '../features/businesses/businesses-reducer';
import {
  consumersInitialState,
  consumerTransactionsInitialState
} from '../features/consumers/consumers-reducer';
import {
  storeCategoriesInitialState
} from '../features/store-categories/store-categories-reducer';
import { brandsInitialState } from '../features/brands/brands-reducer';
import {
  storeLocalizationInitialState
} from '../features/store-localization/store-localization-reducer';
import {
  storeDemographicsInitialState
} from '../features/store-demographics/store-demographics-reducer';
import { storesInitialState } from '../features/stores/stores-reducer';
import { businessEntitiesInitialState } from '../features/business-entities/business-entities-reducer';
import { appsInitialState } from '../features/apps/apps-reducer';
import { businessPromoCodesInitialState } from '../features/business-promo-codes/business-promo-codes-reducer';
import { CampaignSetsInitialState } from '../features/campaign-sets/campaign-sets-reducer';
import { descriptorsInitialState } from '../features/descriptors/descriptors-reducer';
import { financialInstitutionsInitialState } from '../features/financial-institutions/financial-institutions-reducer';

export const initialState = {
  actions: actionsInitialState(),
  auth: authInitialState(),
  admin: adminInitialState(),
  merchantSuggestions: merchantSuggestionsInitialState(),
  businesses: businessesInitialState(),
  consumers: consumersInitialState(),
  consumerTransactions: consumerTransactionsInitialState(),
  stores: storesInitialState(),
  storeCategories: storeCategoriesInitialState(),
  brands: brandsInitialState(),
  storeLocalization: storeLocalizationInitialState(),
  storeDemographics: storeDemographicsInitialState(),
  businessEntities: businessEntitiesInitialState(),
  apps: appsInitialState(),
  businessPromoCodes: businessPromoCodesInitialState(),
  campaignSets: CampaignSetsInitialState(),
  descriptors: descriptorsInitialState(),
  financialInstitutions: financialInstitutionsInitialState(),
};

export type AppState = typeof initialState;
