import { BaseMap } from '../../types/generic';
import { Admin } from './admin-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { adminActionTypes } from './admin-actions';

export const adminInitialState = () => ({} as Admin);

export const adminReducer = (
  state = adminInitialState(),
  action: Action<BaseMap>
): any => {
  switch (action.type) {
    case adminActionTypes.SET_ADMIN:
      return action.payload;

    case actionTypes.CLEAR_STATE:
      return adminInitialState();

    default:
      return state;
  }
};
