export type Descriptor = {
  id: number;
  parsedDescriptor: string;
  descriptorText: string;
  matchConfidenceScore: number;
  cleansedMerchantName: string | null;
  cleansedStreetAddr: string | null;
  cleansedCityName: string | null;
  cleansedStateProvidenceCode: string | null;
  txCountLast180Days: number;
};

export const DescriptorReviewTypeMap = {
  'accept': 'accepted',
  'reject': 'rejected',
  'skip': 'skipped'
} as const;