import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton
} from '@ionic/react';
import React from 'react';

const CampaignManagerHome: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton data-testid="menu-button"></IonMenuButton>
          </IonButtons>
          <IonTitle>Campaign Manager</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonButton
        routerLink="/campaign-manager/bulk-deactivation"
        style={{
          margin: '20px 20px auto 20px',
          maxWidth: '600px',
        }}
      >
        Bulk Deactivation
      </IonButton>
    </IonPage>
  );
};

export default CampaignManagerHome;
