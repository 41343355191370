import { defaultFormData } from "./default-values";
import { FormValues } from "./types";

interface FormDataOption {
  name: string;
  formData: FormValues;
}

export const selectInputFormValues: FormDataOption[] = [{
  name: 'Shop',
  formData: {
    "industry": {
      ...defaultFormData.industry,
      "presentParticipleAdverbPrepositions": ["shopping local at", "shopping local with"],
      "verbPrepositionPronoun": "shop with us",
      "presentParticiplePrepositionPronoun": "shopping with us",
      "verbAdverbPreposition": "shop local at",
      "verbPreposition": "shop at",
      "verbAdverbPrepositionPronoun": "shop local with us"
    },
    "consumer": {
      "noun": "customer",
      "nounPlural": "customers"
    },
    "transaction": {
      ...defaultFormData.transaction,
      "nouns": ["purchase", "purchase"],
      "nounPlural": "purchases",
      "verbs": ["make", "spend"],
      "presentParticiple": "spending",
      "verbPastTense": "made",
      "prepositions": ["a purchase of", "$t(transaction.verbs.1) a purchase with"],
      "verbNounPlural": "make purchases",
      "verbPrepositionPronoun": "spend with us",
      "nounPronounVerb": "purchase you make",
      "nounPluralPronounVerb": "purchases you make",
      "presentParticiplePreposition": "purchasing from",
      "verbPronoun": "buy our"
    },
    "verbPhrase1": "$t(verb) {{name}}",
    "verbPhrase2": "Shop at",
    "verbPhrase3": "Shop local at",
    "verbPhrase4": "Shopping local with",
    "verbPhrase5": "Shop local with us",
    "verbPhrase6": "Shop local with us",
    "verbPhrase7": "shop at",
    "verbPhrase8": "Shop local with us",
    "verbPhrase9": "shop local with us",
    "sentence1": "{{neighborhoodPossessive}} best $t(establishmentNoun)",
    "sentence2": "{{neighborhoodPossessive}} favorite $t(establishmentNoun)",
    "adjective": "favorite",
    "establishmentNoun": "store",
    "productNoun": "goods and services",
    "verb": '$t(industry.presentParticipleAdverbPreposition)',
    "verbWithUs": "shop with us",
    "inStoreVerb": "shopping with us",
    "ctaVerb": "visit us",
    "ctaLocation": "{{streetAddress}}",
    "ctaVerbLocation": "$t(ctaVerb) at $t(ctaLocation)",
    "colorfillHexCode": '#000000',
  }
}, {
  name:'Eat',
  formData: {
      "industry": {
        ...defaultFormData.industry,
        "presentParticipleAdverbPrepositions": ["eating local at", "eating local with"],
        "verbPrepositionPronoun": "dine with us",
        "presentParticiplePrepositionPronoun": "dining with us",
        "verbAdverbPreposition": "eat local at",
        "verbPreposition": "eat at",
        "verbAdverbPrepositionPronoun":"eat local with us"
      },
      "consumer": {
        "noun": "guest",
        "nounPlural": "guests"
      },
      "transaction": {
        ...defaultFormData.transaction,
        "nouns": ["order", "order"],
        "nounPlural": "orders",
        "verbs": ["place", "spend"],
        "presentParticiple": "spending",
        "verbPastTense": "placed",
        "prepositions": ["an order worth", "spend with"],
        "verbNounPlural": "spend",
        "verbPrepositionPronoun": "spend with us",
        "nounPronounVerb": "order you place",
        "nounPluralPronounVerb": "transactions you make",
        "presentParticiplePreposition": "ordering from",
        "verbPronoun": "order our"
      },
      "verbPhrase1": "$t(verb) {{name}}",
      "verbPhrase2": "Eat at",
      "verbPhrase3": "Eat local at",
      "verbPhrase4": "Eating local with",
      "verbPhrase5": "Eat local with us",
      "verbPhrase6": "Eat local with us",
      "verbPhrase7": "dine at",
      "verbPhrase8": "Eat local with us",
      "verbPhrase9": "eat local with us",
      "sentence1": "{{neighborhoodPossessive}} best $t(establishmentNoun)",
      "sentence2": "{{neighborhoodPossessive}} favorite $t(establishmentNoun)",
      "adjective": "favorite",
      "establishmentNoun": "resturant",
      "productNoun": "food and drinks",
      "verb": "eating local at",
      "verbWithUs": "dine with us",
      "inStoreVerb": "dining with us",
      "ctaVerb": "visit us",
      "ctaLocation": "{{streetAddress}}",
      "ctaVerbLocation": "$t(ctaVerb) at $t(ctaLocation)",
      "colorfillHexCode": '#000000',
  }
}, {
  name: 'Visit',
  formData: {
    "industry": {
      ...defaultFormData.industry,
      "presentParticipleAdverbPrepositions": ["visiting", "visiting"],
      "verbPrepositionPronoun": "visit us",
      "presentParticiplePrepositionPronoun": "visiting us",
      "verbAdverbPreposition": "visit",
      "verbPreposition": "visit",
      "verbAdverbPrepositionPronoun": "visit us"
    },
    "consumer": {
      "noun": "client",
      "nounPlural": "clients"
    },
    "transaction": {
      ...defaultFormData.transaction,
      "nouns": ["transaction", "purchase"],
      "nounPlural": "transactions",
      "verbs": ["buy", "spend"],
      "presentParticiple": "spending",
      "verbPastTense": "made",
      "prepositions": ["a service worth", "a service with"],
      "verbNounPlural": "purchase services",
      "verbPrepositionPronoun": "spend with us",
      "nounPronounVerb": "transaction you make",
      "nounPluralPronounVerb": "transactions you make",
      "presentParticiplePreposition": "spending with",
      "verbPronoun": "book our"
    },
    "verbPhrase1": "$t(verb) {{name}}",
    "verbPhrase2": "Visit",
    "verbPhrase3": "Visit",
    "verbPhrase4": "Visiting",
    "verbPhrase5": "Visit us",
    "verbPhrase6": "Visit us",
    "verbPhrase7": "visit",
    "verbPhrase8": "Visit us",
    "verbPhrase9": "visit us",
    "sentence1": "{{neighborhoodPossessive}} best $t(establishmentNoun)",
    "sentence2": "{{neighborhoodPossessive}} favorite $t(establishmentNoun)",
    "adjective": "favorite",
    "establishmentNoun": "store",
    "productNoun": "goods and services",
    "verb": "visiting",
    "verbWithUs": "visit us",
    "inStoreVerb": "visiting us",
    "ctaVerb": "visit us",
    "ctaLocation": "{{streetAddress}}",
    "ctaVerbLocation": "$t(ctaVerb) at $t(ctaLocation)",
    "colorfillHexCode": '#000000',
  }
}, {
  name: 'Hire',
  formData: {
    "industry": {
      ...defaultFormData.industry,
      "presentParticipleAdverbPrepositions": ["hiring", "hiring"],
      "verbPrepositionPronoun": "hire us",
      "presentParticiplePrepositionPronoun": "hiring us",
      "verbAdverbPreposition": "hire",
      "verbPreposition": "hire",
      "verbAdverbPrepositionPronoun": "hire us"
    },
    "consumer": {
      "noun": "client",
      "nounPlural": "clients"
    },
    "transaction": {
      ...defaultFormData.transaction,
      "nouns": ["transaction", "purchase"],
      "nounPlural": "transactions",
      "verbs": ["buy", "schedule"],
      "presentParticiple": "scheduling",
      "verbPastTense": "made",
      "prepositions": ["a service worth", "a service with"],
      "verbNounPlural": "purchase services",
      "verbPrepositionPronoun": "hire us",
      "nounPronounVerb": "transaction you make",
      "nounPluralPronounVerb": "transactions you make",
      "presentParticiplePreposition": "hiring",
      "verbPronoun": "schedule our"
    },
    "verbPhrase1": "$t(verb) {{name}}",
    "verbPhrase2": "Hire",
    "verbPhrase3": "Hire",
    "verbPhrase4": "Hiring",
    "verbPhrase5": "Hire us",
    "verbPhrase6": "Hire us",
    "verbPhrase7": "hire",
    "verbPhrase8": "Hire us",
    "verbPhrase9": "hire us",
    "sentence1": "{{neighborhoodPossessive}} best $t(establishmentNoun)",
    "sentence2": "{{neighborhoodPossessive}} favorite $t(establishmentNoun)",
    "adjective": "favorite",
    "establishmentNoun": "licensed, bonded, and insured general contractor",
    "productNoun": "residential remodeling, structural engineering, and architectural designs",
    "verb": "hiring",
    "verbWithUs": "hire us",
    "inStoreVerb": "hiring us",
    "ctaVerb": "call us",
    "ctaLocation": "{{phone}}",
    "ctaVerbLocation": "$t(ctaVerb) at $t(ctaLocation) for your residential remodeling, structural engineering, and architectural designs needs",
    "colorfillHexCode": '#000000',
  }
}];

export const selectValues = selectInputFormValues.map(v => v.name);