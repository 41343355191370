import { FormInputItem } from "../../types";

export const getInputParams = (
  field: FormInputItem,
  selectedDefaultFormData: any
) => {
  let inputName = '' as any, placeholder = '', label = '';

  const setArrayIndexValueParams = (index: number) => {
    inputName = (field.group
      ? `${field.group}.${field.name}[${index}]`
      : `${field.name}[${index}]`
    ) as any;

    placeholder = field.group
      ? (selectedDefaultFormData as any)[field.group][field.name][index]
      : (selectedDefaultFormData as any)[field.name][index];

    label = `.${field.name}.${index}`;
  };

  const setStringValueParams = () => {
    inputName = (field.group
      ? `${field.group}.${field.name}`
      : field.name
    ) as any;

    placeholder = field.group
      ? (selectedDefaultFormData as any)[field.group][field.name]
      : (selectedDefaultFormData as any)[field.name];

    label = `.${field.name}`;
  };
  
  const inputs = [];
  if (Array.isArray(field.defaultValue)) {
    Array(field.defaultValue.length)
      .fill(null)
      .map((_, i) => {
        setArrayIndexValueParams(i);
        inputs.push({ inputName, placeholder, label });
      });
  } else {
    setStringValueParams();
    inputs.push({ inputName, placeholder, label });
  }

  return inputs;
};