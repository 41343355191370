import { ActionCreator } from '../actions/actions-types';
import { usePost, usePatch, useDelete, useGet } from '../fetch/fetch-resolver';
import { setStores } from './stores-actions';
import { Store } from './stores-types';

export const useGetStores = (
  { businessId }: { businessId: number },
) => {
  return useGet<Store[]>({
    url: `businesses/${businessId}/stores`,
    version: 'v2',
    action: (data => setStores({
      businessId,
      data
    })) as ActionCreator,
    selector: (state) => businessId === state.stores?.businessId
      ? state.stores.data
      : undefined,
  });
};

export const usePostStore = () => {
  return usePost({
    url: 'stores',
    version: 'v2'
  });
};

export const usePatchStore = () => {
  return usePatch({
    url: 'stores',
    version: 'v2'
  });
};

export const usePostStoreFile = (storeId: number) => {
  return usePost({
    url: `stores/${storeId}/files`,
    version: 'v2',
  });
};

export const useDeleteStoreFile = (storeId: number) => {
  return useDelete<any>({
    url: `stores/${storeId}/files?type=:type`,
    version: 'v2',
  });
};