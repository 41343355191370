import { Descriptor } from './descriptors-types';

export const descriptorsActionTypes = {
  SET_DESCRIPTORS: 'setDescriptors',
  CLEAR_DESCRIPTORS: 'clearDescriptors',
};

export const setDescriptors = (descriptors: Descriptor[]) => ({
  type: descriptorsActionTypes.SET_DESCRIPTORS,
  payload: {
    list: descriptors,
  },
});


export const clearDescriptors = () => ({
  type: descriptorsActionTypes.CLEAR_DESCRIPTORS,
});
