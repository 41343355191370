import { Auth } from './auth-types';
import { authActionTypes } from './auth-actions';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

export const authInitialState = () => ({} as Auth);

export const authReducer = (
  state = authInitialState(),
  action: Action<Auth>
): any => {
  switch (action.type) {
    case authActionTypes.SET_AUTH:
      return action.payload;
    case actionTypes.CLEAR_STATE:
      return authInitialState();
    default:
      return state;
  }
};
