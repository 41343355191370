import React, { useEffect, useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast
} from '@ionic/react';
import { AllBrandsContainer, AllBrandsTitle, ButtonsContainer, Modal, NoBrandsText, SelectedBrandsTitle } from './styles';
import { useAppState } from '../../../../../shared/AppContext';
import { useGetBrands, useGetStoreBrands, usePostStoreBrands } from '../../../../../features/brands/brands-resolver';
import BrandItem from './components/BrandItem';
import SelectedBrandItem from './components/SelectedBrandItem';
import { useIsDesktopPlatform } from '../../../../../utils/hooks';
import { setStoreBrands } from '../../../../../features/brands/brands-actions';
import { Store } from '../../../../../features/stores/stores-types';

interface StoreBrandsModalProps {
  store: Store | undefined;
  onClose: () => void;
}

const StoreBrandsModal: React.FC<StoreBrandsModalProps> = ({
  store,
  onClose
}) => {
  const isDesktopPlatform = useIsDesktopPlatform();
  
  const { dispatch: appStateDispatch } = useAppState();

  const [{
    data: allBrands,
    error: allBrandsError,
    isLoading: areAllBrandsLoading
  }, getAllBrands] = useGetBrands();

  const [{
    data: storeBrands,
    error: storeBrandsError,
    isLoading: areStoreBrandsLoading
  }, getStoreBrands] = useGetStoreBrands(store?.id);

  const [{ isLoading: isPostStoreBrandsLoading }, postStoreBrands] =
    usePostStoreBrands(store?.id);
  
  const [presentToast] = useIonToast();

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [selectedBrandIds, setSelectedBrandIds] = useState<number[]>([]);

  const handleClose = () => {
    setSelectedBrandIds([]);
    onClose();
  }

  useEffect(() => {
    if (store) {
      getAllBrands();
      getStoreBrands();
    }
  }, [store]);

  useEffect(() => {
    if (storeBrands) {
      setSelectedBrandIds(storeBrands.map(b => b.id));
    }
  }, [storeBrands]);

  const handleRemovedBrand = (brandId: number) => {
    setSelectedBrandIds(brandIds => brandIds.filter(bId => bId !== brandId));
  };

  const handleCheckChanged = (brandId: number, shouldAdd: boolean) => {
    if (shouldAdd) {
      setSelectedBrandIds(brandIds => brandIds.includes(brandId)
        ? brandIds
        : [...brandIds, brandId]
      );
    } else {
      handleRemovedBrand(brandId);
    }
  }

  const selectedBrands = allBrands?.filter(b => selectedBrandIds.includes(b.id)) || [];

  const handleSaveBrandIds = async () => {
    try {
      const response = await postStoreBrands({
        brandIds: selectedBrandIds
      });
        
      if (response.errorCode) {
        throw new Error(response.message);
      }
      
      presentToast({
        color: 'success',
        message: `Store brands successfully updated`,
        position: 'top',
        duration: 3000
      });

      appStateDispatch(setStoreBrands({
        id: store?.id,
        data: selectedBrands
      }));
    } catch (err) {
      setFormErrorMessage('Could not update brands. Try again later');
    }
  };
  
  const isModalLoading = areAllBrandsLoading
    || areStoreBrandsLoading
    || isPostStoreBrandsLoading;
  
  return (
    <Modal isOpen={!!store} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{`${store?.name} Store Brands`}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <SelectedBrandsTitle>
          Selected Brands
        </SelectedBrandsTitle>
        {selectedBrands && selectedBrands.length > 0
          ? selectedBrands.map(b => (
            <SelectedBrandItem
              key={b.id}
              brand={b}
              onRemove={() => handleRemovedBrand(b.id)}
            />
          ))
          : <NoBrandsText>
              No brands selected
            </NoBrandsText>
        }

        <ButtonsContainer>
          <IonButton
            fill="outline"
            color="danger"
            disabled={!selectedBrandIds.length || isModalLoading}
            onClick={() => setSelectedBrandIds([])}
          >
            Remove all
          </IonButton>
          <IonButton
            fill="solid"
            color="success"
            onClick={handleSaveBrandIds}
            disabled={isModalLoading}
          >
            Save changes
          </IonButton>
        </ButtonsContainer>

        <AllBrandsTitle>
          All Brands
        </AllBrandsTitle>
        <AllBrandsContainer isDesktopPlatform={isDesktopPlatform}>
          {allBrands?.map(b => (
            <BrandItem
              key={b.id}
              brand={b}
              checked={selectedBrandIds.includes(b.id)}
              onCheckChange={v => handleCheckChanged(b.id, v)}
            />
          ))}
        </AllBrandsContainer>
        
      </IonContent>

      <IonToast
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonAlert
        isOpen={!!allBrandsError || !!storeBrandsError}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading
        isOpen={isModalLoading}
      />
    </Modal>
  );
};

export default StoreBrandsModal;
