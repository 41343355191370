import { ActionCreator } from '../actions/actions-types';
import { EditableBusinessInfo } from './business-entities-types';

export const businessEntitiesActionTypes = {
  SET_BUSINESS_ENTITIES: 'setBusinessEntities',
  SET_SELECTED_BUSINESS_ENTITY: 'setSelectedBusinessEntity',
  CLEAR_BUSINESS_ENTITIES: 'clearBusinessEntity',
  SET_BUSINESS_ENTITIES_SEARCH_QUERY: 'setBusinessEntitiesSearchQuery',
  UPDATE_SELECTED_ENTITY_BUSINESS_INFO: 'updateSelectedBusinessInfo',
};

export const setBusinessEntities: ActionCreator = (payload) => ({
  type: businessEntitiesActionTypes.SET_BUSINESS_ENTITIES,
  payload: {
    list: payload,
  }
});

export const setSelectedBusinessEntity: ActionCreator = (payload) => ({
  type: businessEntitiesActionTypes.SET_SELECTED_BUSINESS_ENTITY,
  payload: {
    selected: payload,
  }
});

export const clearBusinessEntities: ActionCreator = () => ({
  type: businessEntitiesActionTypes.CLEAR_BUSINESS_ENTITIES,
});

export const setBusinessEntitiesSearchQuery: ActionCreator = (payload) => ({
  type: businessEntitiesActionTypes.SET_BUSINESS_ENTITIES_SEARCH_QUERY,
  payload: {
    searchQuery: payload,
  }
});

export const updateSelectedEntityBusinessInfo: ActionCreator = (payload: EditableBusinessInfo) => ({
  type: businessEntitiesActionTypes.UPDATE_SELECTED_ENTITY_BUSINESS_INFO,
  payload: {
    updatedEntityBusinessInfo: {
      businessExpectedLocationsCount: payload.expectedLocationsCount,
    },
  }
});
