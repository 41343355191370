import React, { ChangeEvent, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Descriptor, DescriptorReviewTypeMap } from '../../../../../features/descriptors/descriptors-types';
import {
  Container,
  ButtonsContainer,
  Button,
  NotesContainer,
  NotesInput,
  NotesSize,
  SpanInfo,
  ActionsContainer
} from './styles';
import { IonRow, IonIcon } from '@ionic/react';
import { documentText, chevronDown, chevronUp } from 'ionicons/icons';
import { BorderedIonCol } from '../../styles';

type DescriptorItemProps = {
  descriptor: Descriptor;
  onAction: (
    id: number,
    action: keyof typeof DescriptorReviewTypeMap
  ) => void;
  selectedAction: keyof typeof DescriptorReviewTypeMap | null;
  onChangeNotes: (
    id: number,
    notes: string
  ) => void;
  notesLength: number,
  isLoading: boolean;
  isDesktop: boolean;
};

const DescriptorItem: React.FC<DescriptorItemProps> = ({
  descriptor,
  onAction,
  selectedAction,
  onChangeNotes,
  notesLength,
  isLoading,
  isDesktop,
}) => {
  const [openNotes, setOpenNotes] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    onChangeNotes(id, event.target.value);
  };

  const actionButtons = (
    <ActionsContainer>
      <ButtonsContainer>
        <Button
          color="success"
          fill={selectedAction === 'accept' ? 'solid' : 'outline'}
          onClick={() => onAction(descriptor.id, 'accept')}
          expand="block"
          disabled={isLoading}
        >
          Accept
        </Button>
        <Button
          color="danger"
          fill={selectedAction === 'reject' ? 'solid' : 'outline'}
          onClick={() => onAction(descriptor.id, 'reject')}
          expand="block"
          disabled={isLoading}
        >
          Reject
        </Button>
        <Button
          color="medium"
          fill={selectedAction === 'skip' ? 'solid' : 'outline'}
          onClick={() => onAction(descriptor.id, 'skip')}
          expand="block"
          disabled={isLoading}
        >
          Skip
        </Button>
      </ButtonsContainer>
      { !isDesktop && <SpanInfo>* All Reviews must have a Action before Submitting.</SpanInfo> }
    </ActionsContainer>
  );

  return (
    <>
      {isDesktop ? (
        <>
          <IonRow data-testid="descriptor-item">
            <BorderedIonCol size="1.5" data-testid="parsed-descriptor">
              {descriptor.parsedDescriptor}
            </BorderedIonCol>
            <BorderedIonCol size="2" data-testid="descriptor-text">
              {descriptor.descriptorText}
            </BorderedIonCol>
            <BorderedIonCol size="0.5" data-testid="match-confidence-score">
              {descriptor.matchConfidenceScore}
            </BorderedIonCol>
            <BorderedIonCol size="2" data-testid="suggested-merchant-name">
              {descriptor.cleansedMerchantName || 'N/A'}
            </BorderedIonCol>
            <BorderedIonCol size="2" data-testid="suggested-address">
              {descriptor.cleansedStreetAddr || 'N/A'}
            </BorderedIonCol>
            <BorderedIonCol size="1" data-testid="suggested-city">
              {descriptor.cleansedCityName || 'N/A'}
            </BorderedIonCol>
            <BorderedIonCol size="0.5" data-testid="suggested-state">
              {descriptor.cleansedStateProvidenceCode || 'N/A'}
            </BorderedIonCol>
            <BorderedIonCol
              size="0.5"
              data-testid="notes"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <IonIcon
                icon={documentText}
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => setOpenNotes(!openNotes)}
              />
              <IonIcon
                icon={openNotes ? chevronUp : chevronDown}
                style={{ fontSize: '14px', cursor: 'pointer' }}
                onClick={() => setOpenNotes(!openNotes)}
              />
            </BorderedIonCol>
            <BorderedIonCol size="2">
              {actionButtons}
            </BorderedIonCol>
          </IonRow>
          <Collapse isOpened={openNotes}>
            <NotesContainer>
              <NotesInput onChange={(event) => handleChange(event, descriptor.id)}/>
              <NotesSize>{notesLength}/256</NotesSize>
            </NotesContainer>
          </Collapse>
        </>
      ) : (
        <Container data-testid="descriptor-item">
          <p data-testid="parsed-descriptor">
            <strong>Parsed Descriptor:</strong> {descriptor.parsedDescriptor}
          </p>
          <p data-testid="descriptor-text">
            <strong>MC Descriptor:</strong> {descriptor.descriptorText}
          </p>
          <p data-testid="match-confidence-score">
            <strong>MC Confidence Score:</strong> {descriptor.matchConfidenceScore}
          </p>
          <p data-testid="suggested-merchant-name">
            <strong>Suggested Merchant Name:</strong> {descriptor.cleansedMerchantName || 'N/A'}
          </p>
          <p data-testid="suggested-address">
            <strong>Suggested Address:</strong> {descriptor.cleansedStreetAddr || 'N/A'}
          </p>
          <p data-testid="suggested-city">
            <strong>Suggested City:</strong> {descriptor.cleansedCityName || 'N/A'}
          </p>
          <p data-testid="suggested-state">
            <strong>Suggested State:</strong> {descriptor.cleansedStateProvidenceCode || 'N/A'}
          </p>
          <p style={{ display: 'flex', gap: '10px' }} data-testid="notes">
            <strong>Notes: </strong>
            <IonIcon
              icon={documentText}
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => setOpenNotes(!openNotes)}
            />
          </p>
          <Collapse isOpened={openNotes}>
            <NotesContainer>
              <NotesInput onChange={(event) => handleChange(event, descriptor.id)}/>
              <NotesSize>{notesLength}/256</NotesSize>
            </NotesContainer>
          </Collapse>
          {actionButtons}
        </Container>
      )}
    </>
  );
};

export default DescriptorItem;
