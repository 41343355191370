import { IonButton, IonInput, IonSelect } from "@ionic/react";
import styled from "styled-components";

export const FormLabel = styled.label`
  color: var(--ion-color-medium-shade);
  font-weight: bold;
  font-size: 14px;
  width: 250px;
  margin-right: 5px;

  @media (max-width: 715px) {
    margin-bottom: 10px;
  }
`;

interface InputProps {
  error?: boolean;
}

export const Select = styled(IonSelect)<InputProps>`
  background: var(--ion-color-light-shade);
  border: 1px solid var(--ion-color-medium-shade);
  border-radius: 3px;
  width: 200px;

  &:not(:last-child) {
    margin-right: 20px;

    @media (max-width: 715px) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 825px) {
    width: 150px;
  }

  @media (max-width: 715px) {
    width: 100%;
  }
`;

interface SelectContainerProps {
  isDesktopPlatform: boolean;
}
export const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: ${props => props.isDesktopPlatform ? 'row' : 'column'};
`;

export const SelectButton = styled(IonButton)`
  height: 40px;
  margin-bottom: auto;
  margin-top: 0;
`;

export const Row = styled.div`
  flex: 1;
  display: flex;
`;