import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { phoneFormatter } from '../../../../../../utils/format';
import { FormValues } from '../form-validation';
import { FormInput, FormItem, FormLabel } from '../styles';
import {
  IonItem,
  IonSelect,
  IonLabel,
  IonSelectOption,
  IonItemOption
} from '@ionic/react';

interface FormItemsProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormValues>;
}

const FormItems: React.FC<FormItemsProps> = ({ control }) => {
  return (
    <>
      <FormItem>
        <FormLabel>Collinson ID</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="collinsonId"
        />
      </FormItem>

      <FormItem>
        <FormLabel>Slug</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="slug"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Business Name</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="businessName"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Corporate Name</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="corporateName"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Primary Email</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="email"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="primaryEmail"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Contact Email</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="email"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="contactEmail"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Contact First Name</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="contactFirstName"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Contact Last Name</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="contactLastName"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Contact Phone</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={phoneFormatter.format(value || '')}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="contactPhone"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Offer Type</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <IonSelect
              value={value}
              onIonChange={onChange}
            >
              <IonSelectOption value={false}>
                Store Specific
              </IonSelectOption>
              <IonSelectOption value={true}>
                Business Wide
              </IonSelectOption>
            </IonSelect>
          )}
          control={control}
          name="businessWideOffers"
          rules={{ required: true }}
        />
      </FormItem>
    </>
  );
};

export default FormItems;
