import { useGet, usePatch } from '../fetch/fetch-resolver';
import { setBusinesses, setSelectedBusiness } from './businesses-actions';
import { Business, BusinessWithDetails } from './businesses-types';
import { PaginatedResponse } from '../../types/api';
import { UseGetOptions } from '../fetch/fetch-types';

export const useGetBusinesses = (
  options = {} as Partial<UseGetOptions>
) => {
  return useGet<PaginatedResponse<Business[]>>({
    url: 'businesses',
    version: 'v2',
    action: setBusinesses,
    selector: (state) => state.businesses.list,
    initialParams: {
      page: 1,
      limit: 30
    },
    ...options
  });
};

export const useGetBusinessInfo = (
  { id }: { id: number },
) => {
  return useGet<BusinessWithDetails>({
    url: `businesses/${id}`,
    version: 'v2',
    action: setSelectedBusiness,
    selector: (state) => id === state.businesses.selected?.business.id
      ? state.businesses.selected
      : undefined,
  });
};

export const usePatchBusiness = () => {
  return usePatch({
    url: 'businesses',
    version: 'v2'
  });
};

