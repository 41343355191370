import React, { useState, useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';

import {
  EditableFinancialInstitutionInfo,
  ResolutionType,
} from '../../../../../features/financial-institutions/financial-institutions-types';
import { FormValues, useFinancialInstitutionalForm } from './form-validation';
import { useAppState } from '../../../../../shared/AppContext';
import { usePatchFinancialIntitutionInfo } from '../../../../../features/financial-institutions/financial-institutions-resolver';
import { updateSelectedFinancialInstituionInfo } from '../../../../../features/financial-institutions/financial-institutions-actions';
import { FormItems } from './FormItems';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast
} from '@ionic/react';

import {
  Modal,
  ModalContent,
} from './styles';

interface EditFinancialInstitutionModalProps {
  id: Number;
  financialInstitutionInfo: EditableFinancialInstitutionInfo | undefined;
  resolutionTypes: ResolutionType[];
  isOpen: boolean;
  onClose: () => void;
}

export const EditFinancialInstitutionModal: React.FC<EditFinancialInstitutionModalProps> = ({
  id,
  financialInstitutionInfo,
  resolutionTypes,
  isOpen,
  onClose,
}) => {
  const { dispatch: appStateDispatch } = useAppState();

  const [presentToast] = useIonToast();

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const modalContentRef = useRef<HTMLIonContentElement>(null);

  const [{ isLoading: isPatchFIInfoLoading }, patchFIInfo] = usePatchFinancialIntitutionInfo();

  const {
    control,
    handleSubmit,
    reset,
  } = useFinancialInstitutionalForm(
    setFormErrorMessage,
    modalContentRef,
    financialInstitutionInfo
  );

  const handleClose = () => {
    reset();
    onClose();
  }

  const handleEditSubmit: SubmitHandler<FormValues> = async (formPayload) => {
    try {
      const payloadFormEdited = {
        ...formPayload,
        resolutionType: resolutionTypes.find(({ name }) => name === formPayload.resolutionType)?.id || 1, // 1 -> First ID of the resolution_types table.
      }

      const response = await patchFIInfo({ id, ...payloadFormEdited });

      if (response.errorCode) {
        throw new Error('Could not edit Financial Institution. Try again later.');
      }

      presentToast({
        color: 'success',
        message: 'Financial Institution updated!',
        position: 'top',
        duration: 3000
      });

      appStateDispatch(updateSelectedFinancialInstituionInfo(formPayload));

      handleClose();
    } catch (err: any) {
      setFormErrorMessage(err.message);
    }
  }
  return (
    <Modal isOpen={isOpen} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Financial Institution</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={modalContentRef}>
        <ModalContent onSubmit={handleSubmit(handleEditSubmit)}>
          <FormItems
            control={control}
            resolutionTypes={resolutionTypes}
          />

          <IonButton
            expand="full"
            type="submit"
            disabled={isPatchFIInfoLoading}
            style={{ marginTop: 30 }}
          >
            Confirm edit
          </IonButton>
        </ModalContent>
      </IonContent>

      <IonToast
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonLoading isOpen={isPatchFIInfoLoading} />
    </Modal>
  );
}
