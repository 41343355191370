import { Store } from "../../../../../features/stores/stores-types";
import { CopyItemProps } from "../CopyItem";

export const getStoreParams = (store: Store): CopyItemProps[] => [
  {
    name: "Name",
    value: store.name,
    textToClipboard: '{{name}}'
  },
  {
    name: "Street Address",
    value: store.streetAddress,
    textToClipboard: '{{streetAddress}}'
  },
  {
    name: "City",
    value: store.city,
    textToClipboard: '{{city}}'
  },
  {
    name: "State",
    value: store.state,
    textToClipboard: '{{state}}'
  },
  {
    name: "ZipCode",
    value: store.zipCode,
    textToClipboard: '{{zipCode}}'
  },
  {
    name: "Neighorhood",
    value: store.neighborhood || 'N/A',
    textToClipboard: '{{neighborhood}}'
  },
  {
    name: "Neighorhood Possessive",
    value: store.neighborhood
        ? (store.neighborhood?.endsWith('s')
          ? store.neighborhood + "'"
          : store.neighborhood + "'s" )
        : 'N/A',
    textToClipboard: '{{neighborhoodPossessive}}'
  },
  {
    name: "Phone",
    value: store.phone || 'N/A',
    textToClipboard: '{{phone}}'
  },
  {
    name: "WebsiteUrl",
    value: store.websiteUrl || 'N/A',
    textToClipboard: '{{websiteUrl}}'
  },
  {
    name: "FacebookUrl",
    value: store.facebookUrl || 'N/A',
    textToClipboard: '{{facebookUrl}}'
  }
];