import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { ResolutionType } from '../../../../../features/financial-institutions/financial-institutions-types';
import { phoneFormatter } from '../../../../../utils/format';
import { FormValues } from './form-validation';
import { FormInput, FormItem, FormLabel } from './styles';
import {
  IonItem,
  IonSelect,
  IonLabel,
  IonSelectOption,
  IonItemOption
} from '@ionic/react';

interface FormItemsProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormValues>;
  resolutionTypes: ResolutionType[]
}

export const FormItems: React.FC<FormItemsProps> = ({ control, resolutionTypes }) => {
  return (
    <>
      <FormItem>
        <FormLabel>Name</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="text"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="name"
        />
      </FormItem>

      <FormItem>
        <FormLabel>Internet Banking Enrolled Consumers</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="number"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="ibEnrolledCount"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Priority</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormInput
              type="number"
              placeholder=""
              value={value}
              onIonChange={onChange}
              error={!!error}
              clearInput
            />
          )}
          control={control}
          name="priority"
          rules={{ required: true }}
        />
      </FormItem>

      <FormItem>
        <FormLabel>Resolution Type</FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <IonSelect
              value={value}
              onIonChange={onChange}
            >
              {resolutionTypes.map(({ id, name }) => (
                <IonSelectOption key={id} value={name}>
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
          control={control}
          name="resolutionType"
          rules={{ required: true }}
        />
      </FormItem>
    </>
  );
};
