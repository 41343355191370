import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormValues } from '../form-validation';
import { FormInput, FormItem, FormLabel } from '../styles';

interface FormItemsProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormValues>;
}

const FormItems: React.FC<FormItemsProps> = ({
  control,
}) => (
  <>
    <FormItem>
      <FormLabel>Expected # of Locations</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step="1"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="expectedLocationsCount"
      />
    </FormItem>
  </>
);

export default FormItems;
