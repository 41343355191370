export enum DemographicsGender {
  WOMEN = 0,
  MEN = 1,
  WOMEN_AND_MEN = 2
}

export type StoreDemographics = {
  territoryRadius: number | null;
  gender: DemographicsGender | null;
  ageMax: number | null;
  ageMin: number | null;
  interests: string | null;
  latitude: number | null;
  longitude: number | null;
}