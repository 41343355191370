import {
  IonAlert,
  IonButton,
  IonLoading,
  IonText,
  IonToast,
  useIonToast,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import _ from 'lodash';
import {
  setStoreLocalization
} from '../../../../../features/store-localization/store-localization-actions';
import {
  useGetStoreLocalization,
  usePutStoreLocalization
} from '../../../../../features/store-localization/store-localization-resolver';
import { useAppState } from '../../../../../shared/AppContext';
import { useIsDesktopPlatform } from '../../../../../utils/hooks';
import { useStoreLocalizationForm } from './form-validation';
import { ContentContainer } from '../../styles';
import { GroupLabel } from './styles';
import FormItem from './components/FormItem';
import DefaultValuesSelect from './components/DefaultValuesSelect';
import { selectInputFormValues, selectValues } from './value-options';
import { FormValues } from './types';
import { groupedFormInputs } from './form-inputs';
import { localize } from '../../../../../utils/localization';
import { defaultFormData } from './default-values';
import { Store } from '../../../../../features/stores/stores-types';

interface VariablesFormProps {
  store: Store | undefined;
  currentFormValues: FormValues | undefined;
  setCurrentFormValues: React.Dispatch<React.SetStateAction<FormValues | undefined>>;
}

const VariablesForm: React.FC<VariablesFormProps> = ({
  store,
  currentFormValues,
  setCurrentFormValues
}) => {
  const { dispatch: appStateDispatch } = useAppState();

  const isDesktopPlatform = useIsDesktopPlatform();

  const [presentToast] = useIonToast();

  const [{
    data: storeLocalization,
    error: getStoreLocalizationError,
    isLoading: isGetStoreLocalizationLoading
  }, getStoreLocalization] = useGetStoreLocalization(store?.id || 0);

  useEffect(() => {
    if (store?.id) {
      getStoreLocalization();
    }
  }, [store]);

  const [{ isLoading: isPostStoreLocalizationLoading }, postStoreLocalization] =
    usePutStoreLocalization(store?.id || 0);

  const contentContainerRef = useRef<HTMLIonContentElement>(null);

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const [
    translatedData,
    setTranslatedData
  ] = useState<FormValues>(defaultFormData);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formValues
  } = useStoreLocalizationForm(setFormErrorMessage, contentContainerRef, currentFormValues || storeLocalization);

  const formValuesRef = useRef(formValues);

  const [selectedDefault, setSelectedDefault] = useState(selectValues[0]);  
  const selectedDefaultFormData = selectInputFormValues.filter(v => v.name === selectedDefault)[0].formData;

  const updateTranslatedData = async () => {
    try {
      if (store && formValues) {
        const translationResult = await localize(formValues, store) as any as FormValues;
        setTranslatedData(translationResult);
      }
    } catch {
      presentToast({
        color: 'danger',
        message: 'Could not translate variables',
        position: 'top',
        duration: 3000
      });
    }
  };

  useEffect(() => {
    formValuesRef.current = formValues;
    updateTranslatedData();
  }, [store, formValues]);

  useEffect(() => {
    return () => {
      setCurrentFormValues(formValuesRef.current);
    };
  }, []);
  
  const onSetAllValues = (values: FormValues) => reset(values);

  const onSetValue = (name: any, value: string) => setValue(name,value);

  const handleStoreSubmit: SubmitHandler<FormValues> = async (formPayload) => {
    try {
      const response = await postStoreLocalization({ localization: formPayload });
        
      if (response.errorCode) {
        throw new Error(response.message);
      }
      
      presentToast({
        color: 'success',
        message: `Variables were successfully updated`,
        position: 'top',
        duration: 3000
      });

      appStateDispatch(setStoreLocalization({
        storeId: store?.id,
        data: formPayload
      }));
    } catch (err) {
      setFormErrorMessage('Could not update variables. Try again later');
    }
  }

  const getValueTranslation = (fieldPath: string) => _.get(translatedData, fieldPath);

  const isLoading = isGetStoreLocalizationLoading || isPostStoreLocalizationLoading;
  
  return (
    <ContentContainer isDesktopPlatform={isDesktopPlatform} ref={contentContainerRef}>
      <IonText>
        <h2>Variables</h2>
        <h5>These are the fields that are embedded inside emails, websites and assets. Combine your own text, Params and References in these fields to make complex customized text for each store.</h5>
      </IonText>
    
      <form onSubmit={handleSubmit(handleStoreSubmit)}>
        <DefaultValuesSelect
          value={selectedDefault}
          onChange={value => setSelectedDefault(value)}
          onSetAll={() => onSetAllValues(selectedDefaultFormData)}
          isLoading={isLoading}
          isDesktopPlatform={isDesktopPlatform}
        />

        {Object.keys(groupedFormInputs).map(group => (
          <React.Fragment key={`group-${group}`}>
            <GroupLabel>
              {group !== 'null'
                ? group
                : <span>&nbsp;</span>
              }
            </GroupLabel>

            {groupedFormInputs[group].map(field => (
              <FormItem
                key={`${field.group}.${field.name}`}
                control={control}
                field={field}
                getValueTranslation={getValueTranslation}
                onSetValue={onSetValue}
                selectedDefaultFormData={selectedDefaultFormData}
              />
            ))}
          </React.Fragment>
        ))}

        <IonButton
          size="large"
          type="submit"
          disabled={isLoading}
          style={{ marginTop: 30 }}
        >
          Submit
        </IonButton>
      </form>

      <IonToast
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonAlert
        isOpen={getStoreLocalizationError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading isOpen={isLoading} />
    </ContentContainer>
  )
};

export default VariablesForm;
