import React, { useMemo, useRef, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast
} from '@ionic/react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalContent,
} from './styles';
import { FormValues, useBusinessEditForm } from './form-validation';
import FormItems from './components/FormItems';
import { EditableBusinessInfo } from '../../../../../features/businesses/businesses-types';
import { usePatchBusiness } from '../../../../../features/businesses/businesses-resolver';
import { useAppState } from '../../../../../shared/AppContext';
import { updateSelectedBusinessInfo } from '../../../../../features/businesses/businesses-actions';
import { phoneFormatter } from '../../../../../utils/format';

interface EditBusinessModalProps {
  businessId: number;
  businessInfo: EditableBusinessInfo | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const EditBusinessModal: React.FC<EditBusinessModalProps> = ({
  businessId,
  businessInfo,
  isOpen,
  onClose
}) => {
  const { dispatch: appStateDispatch } = useAppState();
  
  const [presentToast] = useIonToast();

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const modalContentRef = useRef<HTMLIonContentElement>(null);
  
  const {
    control,
    handleSubmit,
    reset,
  } = useBusinessEditForm(
    setFormErrorMessage,
    modalContentRef,
    businessInfo
  );

  const [{ isLoading: isPatchBusinessLoading }, patchBusiness] = usePatchBusiness();

  const handleEditSubmit: SubmitHandler<FormValues> = async (formPayload) => {    
    const strippedPayload = {
      ...formPayload,
      contactPhone: phoneFormatter.strip(formPayload.contactPhone)
    };
    
    try {
      const response = await patchBusiness({ businessId, ...strippedPayload });
      
      if (response.errorCode) {
        if (response.statusCode && response.statusCode !== 500) {
          throw new Error(response.message);
        } else {
          throw new Error('Could not edit business. Try again later.');
        }
      }
      
      presentToast({
        color: 'success',
        message: 'Business updated!',
        position: 'top',
        duration: 3000
      });

      appStateDispatch(updateSelectedBusinessInfo(formPayload));
      
      handleClose();
    } catch (err: any) {
      setFormErrorMessage(err.message);
    }
  }

  const handleClose = () => {
    reset();
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Business</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={modalContentRef}>
        <ModalContent onSubmit={handleSubmit(handleEditSubmit)}>
          <FormItems
            control={control}
          />
          
          <IonButton
            expand="full"
            type="submit"
            disabled={isPatchBusinessLoading}
            style={{ marginTop: 30 }}
          >
            Confirm edit
          </IonButton>
        </ModalContent>
      </IonContent>

      <IonToast
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonLoading isOpen={isPatchBusinessLoading} />
    </Modal>
  );
};

export default EditBusinessModal;
