import { ActionCreator } from '../actions/actions-types';

export const appsActionTypes = {
  SET_APPS: 'setStoreCategories',
  CLEAR_APPS: 'clearStoreCategories',
};

export const setApps: ActionCreator = (payload) => ({
  type: appsActionTypes.SET_APPS,
  payload,
});

export const clearApps: ActionCreator = () => ({
  type: appsActionTypes.CLEAR_APPS,
});
