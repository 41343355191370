import React, { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import StoreParams from './components/StoreParams';
import VariablesForm from './components/VariablesForm';
import VariablesReferences from './components/VariablesReferences';
import { TabLabel } from './styles';
import { arrowUndoOutline, listOutline, pencilSharp } from 'ionicons/icons';
import { useIsDesktopPlatform } from '../../../utils/hooks';
import { FormValues } from './components/VariablesForm/types';
import { useGetStores } from '../../../features/stores/stores-resolver';

const StoreLocalization: React.FC = () => {
  const { businessId, storeId } = useParams() as any;
  
  const [{
    isLoading: areStoresLoading,
    error: storesError,
    data: stores,
  }, getStores] = useGetStores({ businessId: Number(businessId) });

  const [currentFormValues, setCurrentFormValues] = useState<FormValues>();

  useEffect(() => {
    if (businessId) {
      getStores();
    }
  }, [businessId]);

  const store = stores?.find(
    store => store.id === Number(storeId)
  );

  const isDesktopPlatform = useIsDesktopPlatform();

  const tabs = [{
    name: 'Variables',
    getComponent: () => <VariablesForm
      store={store}
      currentFormValues={currentFormValues}
      setCurrentFormValues={setCurrentFormValues}
    />,
    icon: pencilSharp
  }, {
    name: 'Params',
    getComponent: () => store ? <StoreParams store={store} /> : null,
    icon: listOutline
  }, {
    name: 'References',
    getComponent: () => <VariablesReferences />,
    icon: arrowUndoOutline
  }];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/businesses/${businessId}`} />
          </IonButtons>
          <IonTitle>{store?.name} Localization</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {stores && <IonTabs>
            <IonTabBar slot="top" style={{ height: isDesktopPlatform ? "50px" : "45px" }}>
              {tabs.map(tab => (
                <IonTabButton
                  key={tab.name}
                  tab={tab.name.toLowerCase()}
                  href={`/businesses/${businessId}/stores/${storeId}/localization/${tab.name.toLowerCase()}`}
                >
                  <TabLabel>{tab.name}</TabLabel>
                  <IonIcon size="small" icon={tab.icon} style={{ marginTop: -5 }} />
                </IonTabButton>
              ))}
            </IonTabBar>

            <IonRouterOutlet>
              {tabs.map(tab => 
                <Route
                  key={tab.name}
                  path={`/businesses/:businessId/stores/:storeId/localization/${tab.name.toLowerCase()}`}
                  render={tab.getComponent}
                  exact={true}
                />
              )}
            </IonRouterOutlet>
          </IonTabs>
        }
      </IonContent>

      <IonAlert
        isOpen={storesError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading isOpen={areStoresLoading} />
    </IonPage>
  );
};

export default StoreLocalization;
