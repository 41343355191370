import { useState, useEffect } from 'react'
import { useGet } from '../fetch/fetch-resolver'
import { UseGetOptions } from '../fetch/fetch-types'
import { PaginatedResponse } from '../../types/api'
import { 
  setConsumers, 
  setSelectedConsumer,  
  setConsumerTransactions
} from './consumers-actions'
import { Consumer } from './consumers-types'
import { useAppState } from '../../shared/AppContext'


export function useGetConsumers(
  options = {} as Partial<UseGetOptions>
){
  return useGet<PaginatedResponse<Consumer[]>>({
    url: 'consumers',
    version: 'v2',
    action: setConsumers,
    selector: (state) => (state.consumers.list),
    initialParams: {
      page: 1,
      limit: 50
    },
    ...options
  });
}


export function useGetConsumer(
  id: number, 
  options = {} as Partial<UseGetOptions>
){
  return useGet<Consumer>({
    url: `consumers/${id}`,
    version: 'v2',
    action: setSelectedConsumer,
    selector: (state) => (state.consumers.selected),
    paginated: false,
    ...options
  })
}

type UseGetConsumerTransactionOptions = {
  consumerId: number
} & Partial<UseGetOptions>

  export function useGetConsumerTransactions(
    options: UseGetConsumerTransactionOptions
  ){
    return useGet<PaginatedResponse<any>>({
      url: `consumers/${options.consumerId}/transactions`,
      version: 'v2',
      action: setConsumerTransactions,
      selector: (state) => (state.consumerTransactions.list),
      initialParams: {
        page: 1,
        limit: 50
      },
      ...options
    })
  }
