import { storeCategoriesActionTypes } from './store-categories-actions';
import { Category } from './store-categories-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

type StoreCategoriesStore = Category[];

export const storeCategoriesInitialState = (): StoreCategoriesStore => [];

export const storeCategoriesReducer = (
  state = storeCategoriesInitialState(),
  action: Action<StoreCategoriesStore>,
): any => {
  switch (action.type) {
    case storeCategoriesActionTypes.SET_STORE_CATEGORIES: {
      return action.payload;
    }

    case storeCategoriesActionTypes.CLEAR_STORE_CATEGORIES:
    case actionTypes.CLEAR_STATE:
      return storeCategoriesInitialState();

    default:
      return state;
  }
};
