import { IonText } from "@ionic/react";
import { Column, ContentContainer } from "../../styles";
import { useIsDesktopPlatform } from "../../../../../utils/hooks";
import React from "react";
import ReferenceItem from "./components/ReferenceItem";
import { groupedFormInputs } from "../VariablesForm/form-inputs";

const VariablesReferences: React.FC = () => {
  const isDesktopPlatform = useIsDesktopPlatform();
  
  return (
    <ContentContainer isDesktopPlatform={isDesktopPlatform}>
      <IonText>
        <h2>References</h2>
        <h5>You can reference a variables text inside another variables text by using the below notation.</h5>
      </IonText>
      
      <Column style={{ marginTop: 30 }}>
        {Object.keys(groupedFormInputs).map(group => (
          <React.Fragment key={group}>
            {groupedFormInputs[group].map(field => (
              <ReferenceItem
                key={`${field.group}-${field.name}`}
                field={field}
              />
            ))}
          </React.Fragment>
        ))}
      </Column>
    </ContentContainer>
  );
}

export default VariablesReferences;