import { ActionCreator } from '../actions/actions-types';

export const adminActionTypes = {
  SET_ADMIN: 'setAdmin'
};

export const setAdmin: ActionCreator = (payload) => ({
  type: adminActionTypes.SET_ADMIN,
  payload
});
