import React from 'react';
import { FinancialInstitutionWithDetails } from '../../../../../features/financial-institutions/financial-institutions-types';

import { Breadcrumb } from '../../../../../components/global';

import { IonIcon } from '@ionic/react';
import { globeOutline } from 'ionicons/icons';

import {
  TextLabel,
  Column,
  ColumnTitle,
  Row,
  Text,
  SectionHeader
} from "./styles";
import { EditFIButton, EditFIIcon } from '../../styles';

interface FinancialInstitutionInfoProps {
  fiinfo: FinancialInstitutionWithDetails,
  onOpenEditModal: () => void;
  isDesktopPlatform: boolean;
}

export const FinancialInstitutionInfo: React.FC<FinancialInstitutionInfoProps> = ({
  fiinfo,
  onOpenEditModal,
  isDesktopPlatform,
}) => {
  return (
    <Row>
      <Column>
        <SectionHeader>
          <EditFIButton
            isDesktopPlatform={isDesktopPlatform}
            onClick={onOpenEditModal}
            data-testid="edit-financial-instutition-info-btn"
            >
              {isDesktopPlatform
                ? 'Edit'
                : <EditFIIcon isDesktopPlatform={false} />
              }
          </EditFIButton>
          <ColumnTitle>
            <IonIcon icon={globeOutline} /> Financial Institution Info:
          </ColumnTitle>
        </SectionHeader>
        <Text data-testid="financial-instutition-defails-fi-id">
          <TextLabel>- ID:</TextLabel> {fiinfo.id}
        </Text>
        <Text data-testid="financial-instutition-details-fi-fiid">
          <TextLabel>- FIID:</TextLabel> {fiinfo.fiid}
        </Text>
        <Text data-testid="financial-instutition-details-fi-name">
          <TextLabel>- Name:</TextLabel> {fiinfo.name}
        </Text>
        <Text data-testid="financial-instutition-details-fi-ibenrolledcount">
          <TextLabel>- Internet Banking Enrolled Consumers:</TextLabel> {fiinfo.ibEnrolledCount}
        </Text>
        <Text data-testid="financial-instutition-details-fi-resolutiontype">
          <TextLabel>- Resolution Type:</TextLabel> <Breadcrumb text={fiinfo.resolutionType} />
        </Text>
        <Text data-testid="financial-instutition-details-fi-priority">
          <TextLabel>- Priority:</TextLabel> {fiinfo.priority}
        </Text>
        <Text data-testid="financial-instutition-details-fi-consumerbankaccount">
          <TextLabel>- Purchase Rewards Enrolled Consumers:</TextLabel> {fiinfo.consumerAccountsCount || 'N/A'}
        </Text>
        <Text data-testid="financial-instutition-details-fi-bankbranches">
          <TextLabel>- Number of Branches:</TextLabel> {fiinfo.numberOfBranches}
        </Text>
      </Column>
    </Row>
  )
}
