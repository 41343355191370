import { IonButton, IonItem, IonLabel } from "@ionic/react";
import styled from "styled-components";

export const CopyItemContainer = styled(IonItem)`
  --background: transparent;
  --padding-start: 0;
  --inner-border-width: 0;

  margin-bottom: 20px;

  ::part(native) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CopyButton = styled(IonButton)`
  text-transform: none;
  width: auto;
  margin: 1px 0 0 0;
`;

export const Label = styled(IonLabel)`
  margin: 0;
  width: 100%;
`;