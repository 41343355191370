import styled from 'styled-components'

export const VerticalLayout = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
`
export const ConsumersFoundLayout = styled.div`
  color: #AAA;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: .2rem 3rem;
`
