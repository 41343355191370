import { useGet, usePatch, usePut } from '../fetch/fetch-resolver';
import { setBusinessEntities, setSelectedBusinessEntity } from './business-entities-actions';
import { BusinessEntity, BusinessEntityWithDetails } from './business-entities-types';
import { PaginatedResponse } from '../../types/api';
import { UseGetOptions } from '../fetch/fetch-types';

export const useGetBusinessEntities = (
  options = {} as Partial<UseGetOptions>
) => {
  return useGet<PaginatedResponse<BusinessEntity[]>>({
    url: 'business-entities',
    version: 'v2',
    action: setBusinessEntities,
    selector: (state) => state.businessEntities.list,
    initialParams: {
      page: 1,
      limit: 30
    },
    ...options
  });
};

export const useGetBusinessEntityInfo = (
  { id }: { id: string },
) => {
  return useGet<BusinessEntityWithDetails>({
    url: `business-entities/${id}`,
    version: 'v2',
    action: setSelectedBusinessEntity,
    selector: (state) => id === state.businessEntities.selected?.id
      ? state.businessEntities.selected
      : undefined,
  });
};

export const usePutMergeBusinesses = () => {
  return usePut({
    url: `business-entities/merge`,
    version: 'v2',
  });
};

export const usePatchEntityBusiness = (id: string) => {
  return usePatch({
    url: `business-entities/businesses/${id}`,
    version: 'v2'
  });
};
