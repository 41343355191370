import {IonButton, IonIcon} from '@ionic/react';
import React  from 'react';
import {caretBackOutline, caretForwardOutline} from 'ionicons/icons';
import styled from 'styled-components';

type ArrowProps = {
  changePage: (event: any) => void;
  side: 'left' | 'right';
}

const ArrowButton = ({ side, changePage }: ArrowProps) => {
  return (
    <IonButton fill='clear' onClick={changePage}>
      <IonIcon 
        icon={
          side === 'left' 
            ? caretBackOutline 
            : caretForwardOutline
        }
      />
    </IonButton>
  )
}

const ArrowsLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  .arrows-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem
  }
  .total-pages{
    color: #777;
    font-size: 1rem;
    text-align: center;
  }
`

type ConsumersPaginationProps = {
  changePage: (newPage: number) => void
  page: number
  pagination: {
    last: number
    total: number
    limit: number,
    page: number
  }
}

export default function PaginationArrows(
  {changePage, page, pagination}: ConsumersPaginationProps
){ 


  const nextPage = ()=>{
    if(page < pagination.last){
      changePage(page + 1)
    }
  }

  const prevPage = ()=>{
    if(page > 1){
      changePage(page - 1)
    }
  }

  if (pagination.total === 0){
    return null
  }

  return(
    <ArrowsLayout>
      <div className='arrows-container'>
        <ArrowButton 
          side='left' 
          changePage={prevPage}
        />
        <div>{page}</div>
        <ArrowButton 
          side='right' 
          changePage={nextPage}
        />
      </div>
      <div className='total-pages'> {`pages: `}{pagination.last}</div>
    </ArrowsLayout>
  )
}
