import styled from 'styled-components'
import {
  IonLabel,
  IonList,
  IonPage,
  IonListHeader,
  IonSkeletonText,
  IonItem,
  IonThumbnail
} from '@ionic/react'


const SkeletonItem = styled(IonItem)`
  border-bottom: 1px var(--ion-color-light-shade) solid;
  --inner-border-width: 0px;
  --inner-padding-top: 34px;
  --inner-padding-bottom: 34px;
`

const Thumbnail = styled(IonThumbnail)`
  width: 76px;
  height: 76px;
`

export default function Skeleton(){
  return(
    <IonPage data-testid='skeleton'>
      <IonList>
        <IonListHeader>
          <IonSkeletonText animated style={{width: '70%' }}/>
        </IonListHeader>
    {
      Array.from({length: 2}, (a, i)=>(
        <SkeletonItem key={i}>
          <Thumbnail slot='start'>
            <IonSkeletonText animated />
          </Thumbnail>
        </SkeletonItem>
      ))
    }
    <IonListHeader>
      <IonSkeletonText animated style={{ width: '70%'}} />
    </IonListHeader>
    {
      Array.from({length: 8}, (a, i) => (
        <SkeletonItem key={i}>
          <IonLabel>
            <IonSkeletonText animated style={{width:'20%'}} />
            <IonSkeletonText animated style={{width:'10%'}} />
            <IonSkeletonText animated style={{width:'30%'}} />
          </IonLabel>
        </SkeletonItem>
      ))
    }
  </IonList>
</IonPage>
  )
}
