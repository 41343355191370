import styled from 'styled-components';
import { IonBackButton, IonButton, IonButtons, IonIcon } from '@ionic/react';
import { gitPullRequestOutline } from 'ionicons/icons';

export const BackButtonContainer = styled(IonButtons).attrs({
  slot: 'start'
})`
  margin-top: 10px;
  margin-bottom: auto;
  margin-left: 2.5%;
`;

export const BackButton = styled(IonBackButton)`
  margin-bottom: 10px;
`;

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export interface MergeBusinessEntityButtonProps {
  isDesktopPlatform: boolean;
}

export const MergeBusinessEntityButton = styled(IonButton).attrs({
  color: 'light',
  slot: 'end'
})<MergeBusinessEntityButtonProps>`
  --padding-start: 10px;
  --padding-end: 10px;
  margin: 5px 10px;
  height: ${props => props.isDesktopPlatform ? '45px' : '35px'};
`;

export const MergeBusinessEntityIcon = styled(IonIcon).attrs<MergeBusinessEntityButtonProps>(props => ({
  size: props.isDesktopPlatform ? 'large' : 'medium',
  slot: 'start',
  icon: gitPullRequestOutline
}))<MergeBusinessEntityButtonProps>`
  margin: ${props => props.isDesktopPlatform ? '10px 15px 10px 0' : '0'};
`;