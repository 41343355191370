import { IonCheckbox, IonItem, IonThumbnail } from "@ionic/react";
import styled from "styled-components";

export const BrandItemContainer = styled(IonItem)`
  --background: transparent;
  --padding-start: 0;
  --inner-border-width: 0;

  margin-bottom: 25px;

  ::part(native) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Thumbnail = styled(IonThumbnail)`
  width: 80px;
  height: 80px;
  --border-radius: 10px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Checkbox = styled(IonCheckbox)`
  margin-right: 10px;
`;