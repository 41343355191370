import { IonIcon } from '@ionic/react';
import { refresh } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormInputItem, FormValues } from '../../types';
import {
  FormInput,
  FormItemContainer,
  FormLabel,
  InputContainer,
  TranslatedValueText,
  ResetButton,
  Row,
} from './styles';
import { getInputParams } from './utils';

interface FormItemContainerProps {
  control: Control<FormValues>;
  field: FormInputItem;
  getValueTranslation: (fieldPath: string) => any;
  onSetValue: (name: any, value: string) => void;
  selectedDefaultFormData: any;
} 

const FormItem: React.FC<FormItemContainerProps> = ({
  control,
  field,
  getValueTranslation,
  onSetValue,
  selectedDefaultFormData,
}) => {              
  const inputs = useMemo(() =>
    getInputParams(field, selectedDefaultFormData),
    [field, selectedDefaultFormData]
  );

  if (field.isHidden) return null;

  return (
    <>
      {inputs.map(({ inputName, placeholder, label }) => (
        <FormItemContainer key={inputName}>
        <FormLabel>{label} {field.isLegacy && ' (Deprecated)'}</FormLabel>
  
        <Row>
          <InputContainer>
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <FormInput
                    value={value as any}
                    type="text"
                    placeholder={placeholder}
                    onIonChange={onChange}
                    error={!!error}
                    clearInput
                  />
                  <TranslatedValueText>
                    Translation: {getValueTranslation(inputName)}
                  </TranslatedValueText>
                </>
              )}
              control={control}
              name={inputName as any}
            />
          </InputContainer>
          
          <ResetButton
            size="small"
            onClick={() => onSetValue(inputName, placeholder)}
          >
            <IonIcon slot="icon-only" icon={refresh} />
          </ResetButton>
        </Row>
      </FormItemContainer>
      ))}
    </>
  );
}

export default FormItem;