import { IonButtons, IonItemGroup, IonModal } from "@ionic/react";
import styled from "styled-components";

export const Modal = styled(IonModal)`
  --width: 80vw;
  --height: 80vh;

  @media (max-width: 765px) {
    --width: 100vw;
    --height: 100vh;
  }
`;

export const SelectedBrandsTitle = styled.p`
  font-size: 20px;
  margin-left: 20px;
`;

export const NoBrandsText = styled.p`
  font-size: 14px;
  margin-left: 16px;
`;

export const ButtonsContainer = styled(IonButtons)`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const AllBrandsTitle = styled.p`
  font-size: 24px;
  margin-left: 20px;
`;

interface AllBrandsContainerProps {
  isDesktopPlatform: boolean;
}

export const AllBrandsContainer = styled(IonItemGroup)<AllBrandsContainerProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.isDesktopPlatform ? '4' : '2' }, 1fr);
`;
