import { IonModal } from "@ionic/react";
import styled from "styled-components";

export const Modal = styled(IonModal)`
  --width: 80vw;
  --height: 80vh;

  @media (max-width: 765px) {
    --width: 100vw;
    --height: 100vh;
  }
`;
