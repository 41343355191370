import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useGetBusinessEntityInfo } from '../../../features/business-entities/business-entities-resolver';
import BusinessEntityInfo from './components/BusinessEntityInfo';
import { BackButton, BackButtonContainer, Centered } from './styles';
import { useIsDesktopPlatform } from '../../../utils/hooks';

const BusinessEntityDetails: React.FC = () => {
  const { id } = useParams() as any;

  const isDesktopPlatform = useIsDesktopPlatform();

  const [{
    isLoading: isBusinessEntityInfoLoading,
    error: businessEntityInfoError,
    data: businessEntityInfo,
  }, getBusinessEntityInfo] = useGetBusinessEntityInfo({ id });

  useEffect(() => {
    if (id) {
      getBusinessEntityInfo();
    }
  }, [id]);

  const businessInfoLoaded = businessEntityInfo && !isBusinessEntityInfoLoading;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {isDesktopPlatform
              ? <IonMenuButton data-testid="menu-button"></IonMenuButton>
              : <IonBackButton defaultHref={'/business-entities'}></IonBackButton>
            }
          </IonButtons>
          <IonTitle>Business Entity</IonTitle>
        </IonToolbar>
      </IonHeader>

      {isDesktopPlatform &&
        <BackButtonContainer data-testid="back-button">
          <BackButton text="Back" defaultHref={'/business-entities'} />
        </BackButtonContainer>
      }

      {(isBusinessEntityInfoLoading) && (
        <Centered>
          <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
        </Centered>
      )}

      {(businessInfoLoaded) && (
        <IonContent>
          {businessInfoLoaded &&
            <BusinessEntityInfo
              businessEntityDetails={businessEntityInfo}
            />
          }
        </IonContent>
      )}

      <IonAlert
        isOpen={!!businessEntityInfoError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />
    </IonPage>
  );
};

export default BusinessEntityDetails;
