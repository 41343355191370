import React, {ReactNode, useState} from 'react';
import {
  IonItem, 
  IonLabel, 
  IonButton, 
  IonList,
  IonItemDivider, 
  IonCol, 
  IonIcon,
  IonRouterLink
} from '@ionic/react'
import styled from 'styled-components';
import {Consumer, Panel, PanelOptions} from '../../../../features/consumers/consumers-types';
import {format, parseISO} from 'date-fns';
import { arrowBackOutline } from 'ionicons/icons';
import Labels from '../Labels'
// ---------------------------------

const ItemContentLayout = styled.div`
  padding: .8rem;
  width: 100vw;
  transition: all .2s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
`

const MainFields = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: center;
  gap: 1rem;
  &:hover{
    cursor: pointer;
    color: var(--ion-color-secondary);
  }
`

const formatDate = (dte:string)=>{
  return format(parseISO(dte), 'MM/dd/yyyy')
}

type ItemProps = {
  consumer: Consumer
  children?: ReactNode
}

export default function Item(props: ItemProps){
  const { consumer } = props


  return <>
    <IonItem 
      button
      routerLink={`/consumer/${consumer.consumerId}/details`}
      data-testid='consumer-item'
    >
      <ItemContentLayout>
        <MainFields className='main-fields'>
          <Labels
            consumer={consumer}
            fields={[
              'consumerId',
              'bankAccountId',
              'email',
            ]}
            useFullName={false}
          />
        </MainFields>
      </ItemContentLayout>
    </IonItem>
  </>
}
