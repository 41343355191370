import { IonAlert, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import InfiniteScroll from "../../../../../components/InfiniteScroll";
import PaginationArrows from "../../../../../components/PaginationArrows";
import { clearBusinessEntities } from "../../../../../features/business-entities/business-entities-actions";
import { useGetBusinessEntities } from "../../../../../features/business-entities/business-entities-resolver";
import { BusinessEntity } from "../../../../../features/business-entities/business-entities-types";
import { useAppState } from "../../../../../shared/AppContext";
import { usePaginationMode } from "../../../../../utils/hooks";
import { Centered } from "../../../BusinessEntities/styles";
import { BusinessEntityItem } from "./components/BusinessEntityItem";
import { Column, ColumnTitle, Row, SectionHeader, Text } from "./styles";

interface BusinessEntitiesListProps {
  businessId: string;
  setEntitiesCount: React.Dispatch<React.SetStateAction<number>>
}

const BusinessEntitiesList: React.FC<BusinessEntitiesListProps> = ({
  businessId,
  setEntitiesCount,
}) => {
  const {
    dispatch: appStateDispatch,
  } = useAppState();
  
  const [
    {
      data: { data: businessesEntities, pagination },
      isLoading: areBusinessEntitiesLoading,
      error: businessesError
    },
    getBusinessEntities
  ] = useGetBusinessEntities({ paginated: true });

  const paginationMode = usePaginationMode();

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [isInfiniteScrollDisabled, setIsInfiniteScrollDisabled] = useState(false);

  useEffect(() => {
    appStateDispatch(clearBusinessEntities());
    const defaultPage = pagination?.page || 1;
    setPage(defaultPage);
    getBusinessEntities({
      refresh: true,
      params: {
        page: defaultPage,
        limit,
        businessId
      }
    });

    return () => {
      appStateDispatch(clearBusinessEntities());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pagination) {
      setEntitiesCount(pagination?.total);
    }
  }, [pagination, setEntitiesCount]);

  const changePage = (newPage: number) => {
    appStateDispatch(clearBusinessEntities());
    setPage(newPage);
    getBusinessEntities({
      refresh: true,
      params: {
        page: newPage,
        limit,
        businessId,
      }
    });
  }

  const changePageInfinite = (event: any) =>{
    setPage(page + 1);
    setTimeout(() => {
      getBusinessEntities({
        refresh: true,
        params: {
          page: page + 1,
          limit,
          businessId
        }
      });
      event.target.complete();
    }, 500);

    if ((businessesEntities?.length || 0) >= pagination?.total) {
      setIsInfiniteScrollDisabled(true);
    }
  }

  const generateBusinessEntitiesList = () => businessesEntities?.map(businessEntities => (
    <BusinessEntityItem
      key={businessEntities.id}
      businessEntity={businessEntities}
    />
  ));
  
  return (
    <Row>
      <Column>
        <SectionHeader>
          <ColumnTitle>Entities</ColumnTitle>
        </SectionHeader>

        {generateBusinessEntitiesList()}

        <Centered>
          {
            pagination && paginationMode === 'pagination'
              ? <PaginationArrows 
                changePage={changePage}
                page={page} 
                pagination={pagination}
              />
              : <InfiniteScroll 
                changePage={changePageInfinite}
                disabled={isInfiniteScrollDisabled}
              />
          }
        </Centered>

        {
          areBusinessEntitiesLoading && page === 1 && (
            <Centered>
              <IonSpinner data-testid="page-spinner-be-list" title="page-spinner" name="crescent" />
            </Centered>
          )
        }

        {
          !areBusinessEntitiesLoading && businessesEntities?.length === 0 && (
            <Text>No entities registered</Text>
          )
        }
      </Column>

      <IonAlert
        isOpen={!!businessesError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />
    </Row>
  );
}

export default BusinessEntitiesList;