import { IonCheckbox, IonInput, IonItem, IonSelect } from "@ionic/react";
import styled from "styled-components";

export const FormItem = styled.div`
  display: flex;
  margin-bottom: 15px;

  @media (max-width: 715px) {
    flex-direction: column;
  }
`;

export const FormLabel = styled.label`
  color: var(--ion-color-medium-shade);
  font-weight: bold;
  width: 260px;

  @media (max-width: 715px) {
    margin-bottom: 10px;
  }
`;

interface InputProps {
  error?: boolean;
}

export const FormInput = styled(IonInput)<InputProps>`
  background: var(--ion-color-light-shade);
  border: 1px solid var(${props => props.error
    ? '--ion-color-danger'
    : '--ion-color-medium-shade'
  });
  border-radius: 3px;
`;

export const Select = styled(IonSelect)<InputProps>`
  background: var(--ion-color-light-shade);
  border: 1px solid var(${props => props.error
    ? '--ion-color-danger'
    : '--ion-color-medium-shade'
  });
  border-radius: 3px;
  width: 600px;

  &:not(:last-child) {
    margin-right: 20px;

    @media (max-width: 715px) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 825px) {
    width: 150px;
  }

  @media (max-width: 715px) {
    width: 100%;
  }
`;

export const CheckboxContainer = styled(IonItem)`
  @media (max-width: 450px) {
    --padding-start: 0;
  }
`;

interface CheckboxProps {
  error?: boolean;
}

export const Checkbox = styled(IonCheckbox)<CheckboxProps>`
  ${props => props.error ? '--border-color: var(--ion-color-danger)' : ''};
`;