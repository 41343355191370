import styled from "styled-components";

export const Row = styled.div`
--background: transparent;
--padding-start: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 95%;
  margin: 10px auto 10px auto;
  padding: 10px 5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  gap: 15px;

  padding: 0px;
  margin: 5px 0;
  overflow-wrap: break-word;
`;

export const ColumnTitle = styled.p`
  font-size: 32px;
  margin: 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const TextLabel = styled.span`
  font-weight: bold;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 20px;
`;
