import styled from 'styled-components';

export const BreadcrumbText = styled.p`
  margin: 0;
  padding: 1px 10px;
  border: 1px solid white;
  border-radius: 12px;

  background: white;
  color: black;

`;
