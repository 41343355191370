import React from "react";
import { BusinessEntity } from "../../../../../../../features/business-entities/business-entities-types";
import { Column, Item, ItemText, TextLabel } from "./styles";

interface BusinessEntityItemProps {
  businessEntity: BusinessEntity;
}

export const BusinessEntityItem: React.FC<BusinessEntityItemProps> = ({
  businessEntity: {
    id,
    merchantName,
    streetAddress,
    city,
    state,
  },
}) => {  
  return (
    <Item
      data-testid="business-entity-item"
      button
      routerLink={`/business-entities/${id}`}
    >
      <Column>
        <ItemText data-testid="be-item-id">
          <TextLabel>ID:</TextLabel> {id}
        </ItemText>
        <ItemText data-testid="be-item-merchant-name">
          <TextLabel>Merchant Name:</TextLabel> {merchantName || 'N/A'}
        </ItemText>
        <ItemText data-testid="be-item-street-address">
          <TextLabel>Street Address:</TextLabel> {streetAddress || 'N/A'}
        </ItemText>
        <ItemText data-testid="be-item-city">
          <TextLabel>City:</TextLabel> {city || 'N/A'}
        </ItemText>
        <ItemText data-testid="be-item-state">
          <TextLabel>State:</TextLabel> {state || 'N/A'}
        </ItemText>
      </Column>
    </Item>
  )
};