import { IonInput, IonModal } from "@ionic/react";
import styled from "styled-components";

export const Modal = styled(IonModal)`
  --width: 80vw;
  --height: 80vh;

  @media (max-width: 765px) {
    --width: 100vw;
    --height: 100vh;
  }
`;

export const ModalContent = styled.form`
  padding: 30px 100px;
  overflow: scroll;

  @media (max-width: 1100px) {
    padding: 20px 50px;
  }

  @media (max-width: 500px) {
    padding: 20px 30px;
  }
`;

export const FormItem = styled.div`
  display: flex;
  margin-bottom: 15px;

  @media (max-width: 715px) {
    flex-direction: column;
  }
`;

export const FormLabel = styled.label`
  color: var(--ion-color-medium-shade);
  font-weight: bold;
  width: 260px;

  @media (max-width: 715px) {
    margin-bottom: 10px;
  }
`;

interface InputProps {
  error?: boolean;
}

export const FormInput = styled(IonInput)<InputProps>`
  background: var(--ion-color-light-shade);
  border: 1px solid var(${props => props.error
    ? '--ion-color-danger'
    : '--ion-color-medium-shade'
  });
  border-radius: 3px;
`;
