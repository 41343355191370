import { DateTime } from 'luxon';
import { TransactionType } from '../features/transaction/transaction-types';
import IMask from 'imask';

export const formatDatetime = (str: string) => {
  return DateTime.fromISO(str).toLocaleString()
}

export const formatTransactionType = (type: TransactionType) => {
  switch(type){
    case TransactionType.SETTLEMENT:
      return 'Complete'
    case TransactionType.AUTHORIZATION:
      return 'Pending'
    default:
      return '';
  }
}

const phoneMaskFactory = () => IMask.createMask({ mask: '(000) 000-0000' });
export const phoneFormatter = {
  format: (value: string) => phoneMaskFactory().resolve(value),
  strip: (value: string) => {
    const phoneMask = phoneMaskFactory();
    phoneMask.resolve(value);
    return phoneMask.unmaskedValue;
  }
}