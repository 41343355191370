import React, { useEffect, useRef, useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast
} from '@ionic/react';
import { SubmitHandler } from 'react-hook-form';
import {
  Modal,
  ModalContent,
} from './styles';
import { useGetStoreCategories } from '../../../../../features/store-categories/store-categories-resolver';
import { usePatchStore, usePostStore } from '../../../../../features/stores/stores-resolver';
import { FormValues, useStoreForm } from './form-validation';
import FormItems from './components/FormItems';
import { phoneFormatter } from '../../../../../utils/format';
import { useAppState } from '../../../../../shared/AppContext';
import { Action } from '../../../../../features/actions/actions-types';
import { Store } from '../../../../../features/stores/stores-types';
import { addStore, updateStore } from '../../../../../features/stores/stores-actions';

interface StoreDetailsModalProps {
  businessId: number;
  selectedStore: Store | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const StoreDetailsModal: React.FC<StoreDetailsModalProps> = ({
  businessId,
  selectedStore,
  isOpen,
  onClose
}) => {
  const { dispatch: appStateDispatch } = useAppState();
  
  const [presentToast] = useIonToast();

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const modalContentRef = useRef<HTMLIonContentElement>(null);
  
  const {
    control,
    handleSubmit,
    reset,
    watchedCategoryId,
  } = useStoreForm(setFormErrorMessage, modalContentRef, selectedStore);

  const [{
    data: categories,
    error: categoriesError,
    isLoading: areCategoriesLoading
  }, getStoreCategories] = useGetStoreCategories();

  const [{ isLoading: isPatchStoreLoading }, patchStore] =
    usePatchStore();

  const [{ isLoading: isPostStoreLoading }, postStore] =
    usePostStore();

  const handleStoreSubmit: SubmitHandler<FormValues> = async (formPayload) => {
    const categoryId = formPayload.categoryId;
    delete formPayload.categoryId;

    let strippedPayload = formPayload;
    if (formPayload.phone) {
      strippedPayload = {
        ...formPayload,
        phone: phoneFormatter.strip(formPayload.phone)
      };
    }
    
    try {
      let response: any;
      if (selectedStore) {
        response = await patchStore({ storeId: selectedStore.id, ...strippedPayload });
      } else {
        response = await postStore({ businessId, ...strippedPayload });
      }
      
      if (response.errorCode) {
        throw new Error(response.statusCode !== 500
          ? response.message
          : undefined);
      }
      
      presentToast({
        color: 'success',
        message: `Store was successfully ${selectedStore ? 'updated' : 'created'}`,
        position: 'top',
        duration: 3000
      });

      const category = categories.find(c => c.id === categoryId);
      const payload = {
        id: selectedStore?.id || response.storeId,
        ...strippedPayload,
        categoryId,
        categoryName: category?.name,
        subcategoryName: category?.subcategories?.find(sc => sc.id === strippedPayload.subcategoryId)?.name,
        acceptsVisa: strippedPayload.visaCardAccepted,
        acceptsMasterCard: strippedPayload.mastercardCardAccepted,
        acceptsAmericanExpress: strippedPayload.amexCardAccepted,
      } as any;
      delete payload.visaCardAccepted;
      delete payload.mastercardCardAccepted;
      delete payload.amexCardAccepted;

      let action: Action;
      if (selectedStore) {
        action = updateStore(payload);
      } else {
        payload.files = { logo: null };
        action = addStore(payload);
      }
      appStateDispatch(action);
      
      handleClose();
    } catch (err: any) {
      setFormErrorMessage(
        err.message?.length > 0
          ? err.message
          : `Could not ${selectedStore ? 'update' : 'create'} store. Try again later.`
      );
    }
  }

  const handleClose = () => {
    reset();
    onClose();
  }

  useEffect(() => {
    if (isOpen) {
      getStoreCategories();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{selectedStore ? 'Store Details' : 'New Store'}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={modalContentRef}>
        <ModalContent onSubmit={handleSubmit(handleStoreSubmit)}>
          <FormItems
            control={control}
            categories={categories}
            watchedCategoryId={watchedCategoryId}
          />
          
          <IonButton
            expand="full"
            type="submit"
            disabled={areCategoriesLoading || isPostStoreLoading}
            style={{ marginTop: 30 }}
          >
            Submit
          </IonButton>
        </ModalContent>
      </IonContent>

      <IonToast
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonAlert
        isOpen={!!categoriesError}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading isOpen={isPostStoreLoading || isPatchStoreLoading} />
    </Modal>
  );
};

export default StoreDetailsModal;
