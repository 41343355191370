import {ActionCreator} from '../actions/actions-types'

// ---------------------------------
// Consumers:

export const consumersActionTypes = {
  SET_CONSUMERS: 'SET_CONSUMERS',
  SET_SELECTED_CONSUMER: 'SET_SELECTED_CONSUMER',
  GET_SELECTED_CONSUMER: 'GET_SELECTED_CONSUMER',
  CLEAR_CONSUMERS: 'CLEAR_CONSUMERS',
}

export const setConsumers:ActionCreator = (payload)=>{
  return {
    type: consumersActionTypes.SET_CONSUMERS,
    payload: {
      list: payload
    }
  }  
}

export const setSelectedConsumer:ActionCreator = (consumer) => {
  return {
    type: consumersActionTypes.SET_SELECTED_CONSUMER,
    payload: consumer
  }
}

export const getSelectedConsumer:ActionCreator = () => {
  return {
    type: consumersActionTypes.GET_SELECTED_CONSUMER,
  }
}


export const clearConsumers:ActionCreator = () => {
  return {
    type: consumersActionTypes.CLEAR_CONSUMERS
  }
}

// ---------------------------------
// Consumer transactions:

export const consumerTransactionsActionTypes = {
  SET_CONSUMER_TRANSACTIONS: 'SET_CONSUMER_TRANSACTIONS',
  CLEAR_CONSUMER_TRANSACTIONS: 'CLEAR_CONSUMER_TRANSACTIONS' 
} 

export const setConsumerTransactions:ActionCreator = (payload)=>{
  return {
    type: consumerTransactionsActionTypes.SET_CONSUMER_TRANSACTIONS,
    payload: {
      list: payload
    }
  }
}

export const clearConsumerTransactions:ActionCreator = ()=>{
  return {
    type: consumerTransactionsActionTypes.CLEAR_CONSUMER_TRANSACTIONS
  }
}
