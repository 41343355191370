import { ActionCreator } from '../actions/actions-types';
import { useGet, usePost } from '../fetch/fetch-resolver';
import { setAllBrands, setStoreBrands } from './brands-actions';
import { Brand } from './brands-types';

export const useGetBrands = () => {
  return useGet<Brand[]>({
    url: 'brands',
    version: 'v2',
    action: setAllBrands,
    selector: (state) => state.brands.all,
  });
};

export const useGetStoreBrands = (storeId: number | undefined) => {
  return useGet<Brand[]>({
    url: `stores/${storeId}/brands`,
    version: 'v2',
    action: (data => setStoreBrands({ id: storeId, data })) as ActionCreator,
    selector: (state) => storeId === state.brands.store.id
      ? state.brands.store.data
      : undefined,
  });
};

export const usePostStoreBrands = (storeId: number | undefined) => {
  return usePost({
    url: `stores/${storeId}/brands`,
    version: 'v2',
  });
};