import { Nullable } from "../../types/generic";

export enum StoreFileType {
  LOGO = 'logo',
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export type Store = {
  id: number;
  businessId: number;
  collinsonLocationId?: string;
  name: string;
  email: string;
  phone: string;
  description: string;
  hoursOpen: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  neighborhood: string;
  files: {
    logo?: Nullable<string>;
    primary?: Nullable<string>;
    secondary?: Nullable<string>;
  };
  websiteUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  instagramUrl?: string;
  latitude: number;
  longitude: number;
  isStorefront: boolean;
  timezone: string;
  vendorId: number;
  campaignSetId: number;
  categoryId?: number;
  categoryName?: string;
  subcategoryId?: number;
  subcategoryName?: string;
  address: string;
  logo?: string;
  isSponsor: boolean;
  averageTransactionAmount?: number;
  averageTransactionFrequency?: number;
  averageGrossMargin?: number;
  averageTransactionFrequencyPerMonth?: number;
  averageAnnualChurnPercent?: number;
  acceptsVisa?: boolean;
  acceptsMasterCard?: boolean;
  acceptsAmericanExpress?: boolean;
}