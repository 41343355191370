import React from "react";
import { BusinessPromoCode } from "../../../../../features/business-promo-codes/business-promo-codes-types";
import { Column, Item, ItemText } from "./styles";

interface PromoCodeItemProps {
  promoCode: BusinessPromoCode;
  isLoading: boolean;
}

export const PromoCodeItem: React.FC<PromoCodeItemProps> = ({
  promoCode: {
    id,
    name,
    value,
  },
  isLoading,
}) => {  
  return (
    <>
      <Item
        data-testid="promo-code-item"
        disabled={isLoading}
        button
        routerLink={`/businesses/promo-codes/${id}`}
      >
        <Column>
          <ItemText data-testid="promo-code-item-id">
            ID: {id}
          </ItemText>
          <ItemText data-testid="promo-code-item-name">
            Name: {name}
          </ItemText>
          <ItemText data-testid="promo-code-item-value">
            Value: ${value}
          </ItemText>
        </Column>
      </Item>
    </>
  )
};