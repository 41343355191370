import { ActionCreator } from '../actions/actions-types';
import { Store } from './stores-types';

export const storesActionTypes = {
  SET_STORES: 'setStores',
  UPDATE_STORE: 'updateStore',
  ADD_STORE: 'addStore',
  CLEAR_STORES: 'clearStores',
};

export const setStores: ActionCreator = (payload) => ({
  type: storesActionTypes.SET_STORES,
  payload
});

export const updateStore: ActionCreator<Partial<Store> & Pick<Store, 'id'>> = (payload) => ({
  type: storesActionTypes.UPDATE_STORE,
  payload: {
    store: payload,
  }
});

export const addStore: ActionCreator<Store> = (payload) => ({
  type: storesActionTypes.ADD_STORE,
  payload: {
    store: payload,
  }
});

export const clearStores: ActionCreator = (payload) => ({
  type: storesActionTypes.CLEAR_STORES,
});
