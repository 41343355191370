import { useGet } from '../fetch/fetch-resolver';
import { setApps } from './apps-actions';
import { App } from './apps-types';

export const useGetApps = () => {
  return useGet<App[]>({
    url: 'apps',
    version: 'v2',
    action: setApps,
    selector: (state) => state.apps,
  });
};

