import { IonButton, IonSelectOption } from '@ionic/react';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { DemographicsGender } from '../../../../../../features/store-demographics/store-demographics-types';
import { FormValues } from '../form-validation';
import { FormInput, FormItem, FormLabel, FormTextArea, FormContainer, Select } from '../styles';

interface FormProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormValues>;
  isLoading: boolean;
  onSubmit: (e: any) => void;
}

const Form: React.FC<FormProps> = ({
  control,
  isLoading,
  onSubmit,
}) => (
  <FormContainer onSubmit={onSubmit}>
    <FormItem>
      <FormLabel>Radius (Miles)</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step="1"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="territoryRadius"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Gender</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            value={value}
            placeholder=""
            onIonChange={onChange}
            error={!!error}
          >
            <IonSelectOption
              key={DemographicsGender.WOMEN_AND_MEN}
              value={DemographicsGender.WOMEN_AND_MEN}
            >Women and Men</IonSelectOption>

            <IonSelectOption
              key={DemographicsGender.WOMEN}
              value={DemographicsGender.WOMEN}
            >Women</IonSelectOption>

            <IonSelectOption
              key={DemographicsGender.MEN}
              value={DemographicsGender.MEN}
            >Men</IonSelectOption>
          </Select>
        )}
        control={control}
        name="gender"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Age Range</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step="1"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="ageMin"
      />
      <p style={{ margin: 10 }}>to</p>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step="1"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="ageMax"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Interests</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormTextArea
            value={value}
            placeholder=""
            autoGrow
            onIonChange={onChange}
            error={!!error}
          />
        )}
        control={control}
        name="interests"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Latitude</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step="0.000001"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="latitude"
      />
    </FormItem>
    
    <FormItem>
      <FormLabel>Longitude</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step="0.000001"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="longitude"
      />
    </FormItem>
          
    <IonButton
      expand="full"
      type="submit"
      disabled={isLoading}
      style={{ marginTop: 30 }}
    >
      Submit
    </IonButton>
  </FormContainer>
);

export default Form;
