import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../features/auth/auth-resolver';
import { FetchError } from '../../features/fetch/fetch-types';
import {
  useGetMerchantSuggestions,
  usePatchMerchantSuggestions
} from '../../features/merchant-suggestions/merchant-suggestions-resolver';
import { MerchantSuggestionHandler } from '../../features/merchant-suggestions/merchant-suggestions-types';
import { MerchantSuggestionItem } from './components/MerchantSuggestionItem';
import { useAppState } from '../../shared/AppContext';
import { removeGroupedMerchantSuggestions } from '../../features/merchant-suggestions/merchant-suggestions-actions';
import GroupedMerchantSuggestionItem from './components/GroupedMerchantSuggestionItem';

const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const LoadMoreSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em;
`;

const MerchantSuggestions: React.FC = () => {
  const auth = useAuth();
  const { dispatch } = useAppState();

  const [
    {
      data: { data: merchantSuggestions, pagination },
      isLoading: merchantSuggestionsLoading,
      error: merchantSuggestionsError
    },
    getMerchantSuggestions
  ] = useGetMerchantSuggestions({ paginated: true });

  const [
    { isLoading: isLoadingPatch, error: patchError },
    patchMerchantSuggestions
  ] = usePatchMerchantSuggestions();

  const [page, setPage] = useState(1);
  const [submitData, setSubmitData] = useState({ status: '', id: 0 });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    getMerchantSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: MerchantSuggestionHandler) => {
    const { id, status, merchantDescriptionId } = data;
    const patchRes = await patchMerchantSuggestions({
      id,
      status,
      adminId: auth.adminId
    });
    if (patchRes && !(patchRes as FetchError).errorCode) {
      dispatch(removeGroupedMerchantSuggestions(merchantDescriptionId));
    }
    setConfirmModalOpen(false);
  };

  const generateSuggestionsList = () => {
    const nodes: React.ReactNode[] = [];
    if (merchantSuggestions) {
      const descriptionIds = Object.keys(merchantSuggestions);
      descriptionIds.forEach((descriptionId: any) => {
        if (merchantSuggestions[descriptionId].length > 1) {
          const suggestions = merchantSuggestions[descriptionId];
          // grouped suggestions
          nodes.push(
            <GroupedMerchantSuggestionItem
              key={descriptionId}
              descriptionId={descriptionId}
              merchantSuggestions={suggestions}
              isLoading={isLoadingPatch}
            />
          );
        }

        if (merchantSuggestions[descriptionId].length === 1) {
          const [suggestion] = merchantSuggestions[descriptionId];
          nodes.push(
            <MerchantSuggestionItem
              key={descriptionId}
              merchantSuggestion={suggestion}
              onSubmitHandler={(data: MerchantSuggestionHandler) => {
                setSubmitData(data);
                setConfirmModalOpen(true);
              }}
              isLoading={isLoadingPatch}
              updatingSuggestionId={submitData.id}
            />
          );
        }
      });
    }

    return nodes;
  };

  const loadMoreSuggestions = async () => {
    if (!merchantSuggestionsLoading) {
      const nextPage = page + 1;
      if (nextPage <= pagination.last) {
        await getMerchantSuggestions({
          refresh: true,
          params: { page: nextPage }
        });
        setPage(nextPage);
        setShowToast(true);
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton data-testid="menu-button"></IonMenuButton>
          </IonButtons>
          <IonTitle>Merchant Suggestions</IonTitle>
        </IonToolbar>
      </IonHeader>

      {merchantSuggestionsLoading && page === 0 && (
        <Centered>
          <IonSpinner title="page-spinner" name="crescent" />
        </Centered>
      )}

      {merchantSuggestions && (
        <IonContent>
          {generateSuggestionsList()}
          <IonButton
            disabled={page >= pagination.last}
            onClick={loadMoreSuggestions}
            color="light"
            expand="full"
          >
            Load more suggestions
          </IonButton>
        </IonContent>
      )}

      {!merchantSuggestions && (
        <Centered>
          <p>There are no suggestions for you to review at the moment.</p>
        </Centered>
      )}

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Suggestions loaded successfully."
        position="top"
        duration={1000}
      />

      <IonAlert
        isOpen={!!merchantSuggestionsError || !!patchError}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonAlert
        isOpen={confirmModalOpen}
        cssClass="modal-dark-mode"
        header="Are you sure?"
        message="This action cannot be reverted."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-cancel-button',
            handler: () => {
              setConfirmModalOpen(false);
            }
          },
          {
            text: 'Confirm',
            handler: async () => {
              onSubmit(submitData as MerchantSuggestionHandler);
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default MerchantSuggestions;
