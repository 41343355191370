import { groupBy } from "lodash";
import { FormInputItem } from "./types";

export const formInputs: FormInputItem[] = [{
  // industry
  name: 'presentParticipleAdverbPreposition',
  isHidden: true,
  isLegacy: false,
  defaultValue: '$t(industry.presentParticipleAdverbPrepositions.0)',
  group: 'industry'
}, {
  name: 'presentParticipleAdverbPrepositions',
  isHidden: false,
  isLegacy: false,
  defaultValue: ['', ''],
  group: 'industry'
}, {
  name: 'verbPrepositionPronoun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'industry'
}, {
  name: 'presentParticiplePrepositionPronoun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'industry'
}, {
  name: 'verbAdverbPreposition',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'industry'
}, {
  name: 'verbPreposition',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'industry'
}, {
  name: 'verbAdverbPrepositionPronoun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'industry'
}, {
  // consumer
  name: 'noun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'consumer'
}, {
  name: 'nounPlural',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'consumer'
}, {
  // transaction
  name: 'noun',
  isHidden: true,
  isLegacy: false,
  defaultValue: '$t(transaction.nouns.0)',
  group: 'transaction'
}, {
  name: 'nouns',
  isHidden: false,
  isLegacy: false,
  defaultValue: ['', ''],
  group: 'transaction'
}, {
  name: 'verb',
  isHidden: true,
  isLegacy: false,
  defaultValue: '$t(transaction.verbs.0)',
  group: 'transaction'
}, {
  name: 'verbs',
  isHidden: false,
  isLegacy: false,
  defaultValue: ['', ''],
  group: 'transaction'
}, {
  name: 'presentParticiple',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  name: 'verbPastTense',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  name: 'preposition',
  isHidden: true,
  isLegacy: false,
  defaultValue: '$t(transaction.prepositions.0)',
  group: 'transaction'
}, {
  name: 'prepositions',
  isHidden: false,
  isLegacy: false,
  defaultValue: ['', ''],
  group: 'transaction'
}, {
  name: 'verbNounPlural',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  name: 'verbPrepositionPronoun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  name: 'nounPronounVerb',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  name: 'nounPluralPronounVerb',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  name: 'presentParticiplePreposition',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  name: 'verbPronoun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: 'transaction'
}, {
  // root-level
  name: 'verbPhrase1',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(verb) {{name}}',
  group: null
}, {
  name: 'verbPhrase2',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.verbPreposition)',
  group: null
}, {
  name: 'verbPhrase3',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.presentParticipleAdverbPreposition)',
  group: null
}, {
  name: 'verbPhrase4',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.presentParticipleAdverbPrepositions.1)',
  group: null
}, {
  name: 'verbPhrase5',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.verbAdverbPrepositionPronoun)',
  group: null
}, {
  name: 'verbPhrase6',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.verbAdverbPrepositionPronoun)',
  group: null
}, {
  name: 'verbPhrase7',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.verbPreposition)',
  group: null
}, {
  name: 'verbPhrase8',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.verbAdverbPrepositionPronoun)',
  group: null
}, {
  name: 'verbPhrase9',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.verbAdverbPrepositionPronoun)',
  group: null
}, {
  name: 'sentence1',
  isHidden: false,
  isLegacy: true,
  defaultValue: '{{neighborhoodPossessive}} best $t(establishmentNoun)',
  group: null
}, {
  name: 'sentence2',
  isHidden: false,
  isLegacy: true,
  defaultValue: '{{neighborhoodPossessive}} favorite $t(establishmentNoun)',
  group: null
}, {
  name: 'adjective',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: null
}, {
  name: 'establishmentNoun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: null
}, {
  name: 'productNoun',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: null
}, {
  name: 'verb',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.presentParticipleAdverbPreposition)',
  group: null
}, {
  name: 'verbWithUs',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.verbPrepositionPronoun)',
  group: null
}, {
  name: 'inStoreVerb',
  isHidden: false,
  isLegacy: true,
  defaultValue: '$t(industry.presentParticiplePrepositionPronoun)',
  group: null
}, {
  name: 'ctaVerb',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: null
}, {
  name: 'ctaLocation',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: null
}, {
  name: 'ctaVerbLocation',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: null
}, {
  name: 'colorfillHexCode',
  isHidden: false,
  isLegacy: false,
  defaultValue: '',
  group: null
}];

export const groupedFormInputs = groupBy(formInputs, 'group');