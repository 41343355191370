import { usePost } from '../fetch/fetch-resolver';
import { Auth, LoginRequest } from './auth-types';
import { useAppState } from '../../shared/AppContext';
import { setAuth } from './auth-actions';

export const useLogin = () => {
  const url = 'admins/login';
  return usePost<LoginRequest>({ url, version: 'v2', action: setAuth });
};

export const useAuth = (): Auth => {
  const { state } = useAppState();
  return state.auth;
};
