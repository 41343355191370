import { mergeWith, omit, isArray } from 'lodash';
import { merchantSuggestionsActionTypes } from './merchant-suggestions-actions';
import { GroupedMerchantSuggestions } from './merchant-suggestions-types';
import { PaginatedResponse } from '../../types/api';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

type RemoveSuggestionPayload = {
  id: number;
  descriptionId: string;
};

export const merchantSuggestionsInitialState = () =>
  ({
    data: undefined,
    pagination: {
      page: 0,
      last: 0,
      limit: 0,
      total: 0
    }
  } as PaginatedResponse<GroupedMerchantSuggestions>);

const suggestionMerger = (
  objValue: GroupedMerchantSuggestions,
  srcValue: GroupedMerchantSuggestions
) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const merchantSuggestionsReducer = (
  state = merchantSuggestionsInitialState(),
  action: Action<PaginatedResponse<GroupedMerchantSuggestions>>
): any => {
  switch (action.type) {
    case merchantSuggestionsActionTypes.SET_MERCHANT_SUGGESTIONS: {
      const { pagination, data } = action.payload;
      if (pagination.page === 1) {
        // setting the first page
        return { ...state, data, pagination };
      }
      if (pagination.page <= pagination.last) {
        // appending next merchant suggestions page
        const merged = mergeWith(state.data, data, suggestionMerger);

        return {
          ...state,
          data: merged,
          pagination
        };
      }
      return state;
    }
    case merchantSuggestionsActionTypes.UPDATE_MERCHANT_SUGGESTION:
      return {
        ...state,
        ...action.payload
      };
    case merchantSuggestionsActionTypes.REMOVE_GROUPED_MERCHANT_SUGGESTIONS: {
      const descriptionId = action.payload as unknown as string;
      const merchantSuggestions = omit(state.data, descriptionId);

      return {
        ...state,
        data: merchantSuggestions
      };
    }
    case merchantSuggestionsActionTypes.REMOVE_INDIVIDUAL_MERCHANT_SUGGESTION: {
      const { descriptionId, id } =
        action.payload as unknown as RemoveSuggestionPayload;
      const groupedSuggestions = state.data ? state.data[descriptionId] : [];

      const filteredSuggestions = groupedSuggestions.filter((suggestion) => {
        return suggestion.id !== id;
      });

      return {
        ...state,
        data: { ...state.data, [descriptionId]: filteredSuggestions }
      };
    }
    case actionTypes.CLEAR_STATE:
      return merchantSuggestionsInitialState();

    default:
      return state;
  }
};
