import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Store } from '../../../../../features/stores/stores-types';

const SCROLL_DURATION = 800;

export interface FormValues {
  categoryId?: number;
  subcategoryId?: number;
  collinsonLocationId?: string;
  visaCardAccepted?: boolean;
  mastercardCardAccepted?: boolean;
  amexCardAccepted?: boolean;
  averageTransactionFrequencyPerMonth?: string;
  averageTransactionAmount?: string;
  averageGrossMargin?: string;
  averageAnnualChurnPercent?: string;
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  neighborhood?: string;
  isStorefront?: boolean;
  description?: string;
  hoursOpen?: string;
  email?: string;
  phone?: string;
  websiteUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  instagramUrl?: string;
}

const storeSubmitionSchema = yup.object().shape({
  categoryId: yup
    .number()
    .typeError('Invalid category'),
  subcategoryId: yup
    .number()
    .typeError('Invalid subcategory'),
  collinsonLocationId: yup
    .string()
    .typeError('Invalid Collinson Location ID')
    .transform((_, value) => value === "" ? undefined : value),
  visaCardAccepted: yup
    .boolean()
    .typeError('Choose if VISA cards should be accepted'),
  mastercardCardAccepted: yup
    .boolean()
    .typeError('Choose if Mastercard cards should be accepted'),
  amexCardAccepted: yup
    .boolean()
    .typeError('Choose if AMEX cards should be accepted'),
  averageTransactionFrequencyPerMonth: yup
    .number()
    .typeError('Invalid average frequency per month')
    .transform((parsedValue, value) => value === "" ? undefined : parsedValue),
  averageTransactionAmount: yup
    .number()
    .typeError('Invalid average amount')
    .transform((parsedValue, value) => value === "" ? undefined : parsedValue),
  averageGrossMargin: yup
    .number()
    .typeError('Invalid average gross margin')
    .transform((parsedValue, value) => value === "" ? undefined : parsedValue / 100),
  averageAnnualChurnPercent: yup
    .number()
    .typeError('Invalid average annual churn')
    .transform((parsedValue, value) => value === "" ? undefined : parsedValue / 100),
  name: yup
    .string()
    .typeError('Invalid name')
    .required('Insert the name'),
  streetAddress: yup
    .string()
    .typeError('Invalid street address')
    .required('Insert the street address'),
  city: yup
    .string()
    .typeError('Invalid city')
    .required('Insert the city'),
  state: yup
    .string()
    .typeError('Invalid state')
    .required('Insert the state'),
  zipCode: yup
    .string()
    .typeError('Invalid ZIP code')
    .required('Insert the zip code'),
  neighborhood: yup
    .string()
    .typeError('Invalid neighborhood')
    .transform((_, value) => value === "" ? undefined : value),
  isStorefront: yup
    .boolean()
    .typeError('Choose if the store is a storefront'),
  description: yup
    .string()
    .typeError('Invalid description')
    .transform((_, value) => value === "" ? undefined : value),
  hoursOpen: yup
    .string()
    .typeError('Invalid "Hours Open"')
    .transform((_, value) => value === "" ? undefined : value),
  email: yup
    .string()
    .typeError('Invalid email')
    .transform((_, value) => value === "" ? undefined : value),
  phone: yup
    .string()
    .typeError('Invalid phone')
    .transform((_, value) => value === "" ? undefined : value),
  websiteUrl: yup
    .string()
    .typeError('Invalid website URL')
    .transform((_, value) => value === "" ? undefined : value),
  facebookUrl: yup
    .string()
    .typeError('Invalid Facebook URL')
    .transform((_, value) => value === "" ? undefined : value),
  twitterUrl: yup
    .string()
    .typeError('Invalid Twitter URL')
    .transform((_, value) => value === "" ? undefined : value),
  instagramUrl: yup
    .string()
    .typeError('Invalid Instagram URL')
    .transform((_, value) => value === "" ? undefined : value),
});

const defaultValues = {
  averageTransactionFrequencyPerMonth: '0.00',
  averageTransactionAmount: '0.00',
  averageGrossMargin: '0.00',
  averageAnnualChurnPercent: '0.00',
};

export const useStoreForm = (
  setFormErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  modalContentRef: React.RefObject<HTMLIonContentElement>,
  storeData: Store | undefined
) => {
  const [hasStoreDataLoaded, setHasStoreDataLoaded] = useState(false);
  
  useEffect(() => {
    setHasStoreDataLoaded(false);
  }, [storeData]);
  
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    watch,
    setValue
  } = useForm<FormValues>({
    resolver: yupResolver(storeSubmitionSchema),
    reValidateMode: 'onSubmit',
    defaultValues
  });
  const watchedCategoryId = watch('categoryId');

  useEffect(() => {
    if (hasStoreDataLoaded) {
      setValue('subcategoryId', undefined);
    } else {
      setHasStoreDataLoaded(true);
    }
  }, [watchedCategoryId]);

  useEffect(() => {
    const errors = Object.keys(formErrors);
    if (errors.length > 0) {
      const modalContentElement = modalContentRef.current;
      if (modalContentElement) {
        modalContentElement.scrollToTop(SCROLL_DURATION);
      }
      setFormErrorMessage('Invalid data. Please verify all fields.');
    } else {
      setFormErrorMessage('');
    }
  }, [formErrors]);

  useEffect(() => {
    if (!hasStoreDataLoaded) {
      reset(storeData
        ? {
          categoryId: storeData.categoryId || undefined,
          subcategoryId: storeData.subcategoryId || undefined,
          collinsonLocationId: storeData.collinsonLocationId || '',
          visaCardAccepted: storeData.acceptsVisa,
          mastercardCardAccepted: storeData.acceptsMasterCard,
          amexCardAccepted: storeData.acceptsAmericanExpress,
          averageTransactionFrequencyPerMonth: storeData.averageTransactionFrequencyPerMonth?.toFixed(2) || '0.00',
          averageTransactionAmount: storeData.averageTransactionAmount?.toFixed(2) || '0.00',
          averageGrossMargin: storeData.averageGrossMargin
            ? (storeData.averageGrossMargin * 100).toFixed(2)
            : '0.00',
          averageAnnualChurnPercent: storeData.averageAnnualChurnPercent
            ? (storeData.averageAnnualChurnPercent * 100).toFixed(2)
            : '0.00',
          name: storeData.name,
          streetAddress: storeData.streetAddress,
          city: storeData.city,
          state: storeData.state,
          zipCode: storeData.zipCode,
          neighborhood: storeData.neighborhood || '',
          isStorefront: storeData.isStorefront,
          description: storeData.description || '',
          hoursOpen: storeData.hoursOpen || '',
          email: storeData.email || '',
          phone: storeData.phone || '',
          websiteUrl: storeData.websiteUrl || '',
          facebookUrl: storeData.facebookUrl || '',
          twitterUrl: storeData.twitterUrl || '',
          instagramUrl: storeData.instagramUrl || '',
        }
        : defaultValues
      );
    }
  }, [storeData, hasStoreDataLoaded]);
  
  return {
    control,
    handleSubmit,
    reset,
    watchedCategoryId,
  };
};