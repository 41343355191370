import CopyItem from "../../CopyItem";
import { FormInputItem } from "../../VariablesForm/types";

interface ReferenceItemProps {
  field: FormInputItem;
}

const ReferenceItem: React.FC<ReferenceItemProps> = ({
  field,
}) => {
  let fieldPath = '';

  const getReferenceItem = () => (
    <CopyItem
      key={`store-${fieldPath.replace('.', '-')}`}
      name={''}
      value={fieldPath}
      textToClipboard={`$t(${fieldPath})`}
    />
  );
  
  if (Array.isArray(field.defaultValue)) {
    return (
      <>
        {Array(field.defaultValue.length).fill(null).map((_, i) => {
          fieldPath = (field.group
            ? `${field.group}.${field.name}.${i}`
            : `${field.name}.${i}`
          ) as any;
          
          return getReferenceItem();
        })}
      </>
    );
  } else {
    fieldPath = (field.group
      ? `${field.group}.${field.name}`
      : field.name
    ) as any;
  }

  return getReferenceItem();
}

export default ReferenceItem;