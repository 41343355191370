import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { useGetBusinessPromoCodeInfo, usePatchBusinessPromoCode } from '../../../features/business-promo-codes/business-promo-codes-resolver';
import PromoCodeInfo from './components/PromoCodeInfo';
import { ActionButton, BackButton, BackButtonContainer, Centered, Row } from './styles';
import { useIsDesktopPlatform } from '../../../utils/hooks';
import { useGetCampaignSets } from '../../../features/campaign-sets/campaign-sets-resolver';
import { useAppState } from '../../../shared/AppContext';
import { updateSelectedBusinessPromoCodeInfo } from '../../../features/business-promo-codes/business-promo-codes-actions';

const PromoCodeDetails: React.FC = () => {
  const { id } = useParams() as any;

  const isDesktopPlatform = useIsDesktopPlatform();
  
  const { dispatch: appStateDispatch } = useAppState();

  const [{
    isLoading: isPromoCodeInfoLoading,
    error: promoCodeInfoError,
    data: promoCodeInfo,
  }, getPromoCodeInfo] = useGetBusinessPromoCodeInfo({ id: Number(id) });

  const [{
    isLoading: areCampaignSetsLoading,
    error: campaignSetsError,
    data: campaignSets,
  }, getCampaignSets] = useGetCampaignSets();

  const [{
    isLoading: isPatchPromoCodeLoading,
    error: patchPromoCodeError,
  }, patchBusinessPromoCode] = usePatchBusinessPromoCode({ id: Number(id) });

  const selectRef = useRef<HTMLIonSelectElement>(null);

  useEffect(() => {
    if (id) {
      getPromoCodeInfo();
      getCampaignSets();
    }
  }, [id]);

  const handleAssignCampaignSetClick = () => {
    selectRef.current?.click();
  };

  const [toastMessage, setToastMessage] = useState('');
  const toastMessageToastRef = React.useRef<HTMLIonToastElement>(null);

  const sendAssignCampaignSet = async (campaignSetId: number | null): Promise<boolean> => {
    try {
      const res = await patchBusinessPromoCode({
        campaignSetId,
      });

      if (res.errorCode) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  const handleCampaignSetChange = async (event: CustomEvent<any>) => {
    const campaignSetId = event.detail.value;

    await toastMessageToastRef.current?.dismiss();

    if (Number(campaignSetId) === Number(promoCodeInfo?.campaignSet?.id)) {
      setToastMessage('This campaign set is already assigned to this promo code');
      return;
    }

    const success = await sendAssignCampaignSet(Number(campaignSetId));

    if (success) {
      const campaignSet = campaignSets?.find(cS => cS.id === campaignSetId);
      appStateDispatch(updateSelectedBusinessPromoCodeInfo({
        campaignSet: {
          id: campaignSet?.id,
          name: campaignSet?.name,
        }
      }));

      setToastMessage('Campaign set assigned successfully');
    }
  };

  const handleClearCampaignSetClick = async () => {
    const success = await sendAssignCampaignSet(null);

    await toastMessageToastRef.current?.dismiss();

    if (success) {
      appStateDispatch(updateSelectedBusinessPromoCodeInfo({
        campaignSet: null,
      }));

      setToastMessage('Campaign set cleared successfully');
    }
  }

  const isDataLoading = isPromoCodeInfoLoading || areCampaignSetsLoading ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {isDesktopPlatform
              ? <IonMenuButton data-testid="menu-button"></IonMenuButton>
              : <IonBackButton defaultHref={'/businesses/promo-codes'}></IonBackButton>
            }
          </IonButtons>
          <IonTitle>Promo Code</IonTitle>
        </IonToolbar>
      </IonHeader>

      {isDesktopPlatform &&
        <BackButtonContainer data-testid="back-button">
          <BackButton text="Back to all promo codes" defaultHref={'/businesses/promo-codes'} />
        </BackButtonContainer>
      }

      {(isDataLoading) && (
        <Centered>
          <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
        </Centered>
      )}

      {(promoCodeInfo && !isDataLoading) && (
        <IonContent>
          <PromoCodeInfo promoCodeInfo={promoCodeInfo} />

          <Row>
            <ActionButton
              isDesktopPlatform={isDesktopPlatform}
              onClick={handleAssignCampaignSetClick}
              data-testid="assign-campaign-set-btn"
              disabled={isPatchPromoCodeLoading}
            >
              Assign Campaign Set
            </ActionButton>

            <ActionButton
              isDesktopPlatform={isDesktopPlatform}
              onClick={handleClearCampaignSetClick}
              data-testid="clear-campaign-set-btn"
              disabled={!promoCodeInfo.campaignSet || isPatchPromoCodeLoading}
            >
              Clear Campaign Set
            </ActionButton>
          </Row>

          <IonSelect
            okText="Submit"
            cancelText="Dismiss"
            onIonChange={handleCampaignSetChange}
            style={{ display: 'none' }}
            interfaceOptions={{
              header: 'Campaign Sets',
              cssClass: 'campaign-set-select-modal',
            }}
            ref={selectRef}
          >
            {campaignSets?.map(cS => (
              <IonSelectOption key={cS.id} value={cS.id}>{cS.name} (ID: {cS.id})</IonSelectOption>
            ))}
          </IonSelect>
        </IonContent>
      )}

      <IonToast
        ref={toastMessageToastRef}
        isOpen={toastMessage.length > 0}
        color="success"
        onDidDismiss={() => setToastMessage('')}
        message={toastMessage}
        position="top"
        buttons={['OK']}
      />

      <IonAlert
        isOpen={!!promoCodeInfoError || !!campaignSetsError || !!patchPromoCodeError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading
        isOpen={isPatchPromoCodeLoading}
        message={'Please wait...'}
      />
    </IonPage>
  );
};

export default PromoCodeDetails;
