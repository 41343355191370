import { ActionCreator } from '../actions/actions-types';

export const brandsActionTypes = {
  SET_ALL_BRANDS: 'setAllBrands',
  SET_STORE_BRANDS: 'setStoreBrands',
  CLEAR_BRANDS: 'clearBrands',
};

export const setAllBrands: ActionCreator = (payload) => ({
  type: brandsActionTypes.SET_ALL_BRANDS,
  payload,
});

export const setStoreBrands: ActionCreator = (payload) => ({
  type: brandsActionTypes.SET_STORE_BRANDS,
  payload,
});

export const clearBrands: ActionCreator = () => ({
  type: brandsActionTypes.CLEAR_BRANDS,
});