import {
  IonInfiniteScroll, 
  IonInfiniteScrollContent,
} from '@ionic/react';
import React from 'react';

type InfiniteScrollProps = {
  changePage: any
  disabled: boolean
}

export default function InfiniteScroll(
  { changePage, disabled }: InfiniteScrollProps
){

  return (
    <>
      <IonInfiniteScroll
        onIonInfinite={changePage}
        threshold="100px"
        disabled={disabled}
      >
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="loading more data..."
        >
        </IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  )
}
