import React, {ReactNode} from 'react'
import {
  IonLabel, 
  IonListHeader,
  IonItem
} from '@ionic/react';
import styled from 'styled-components';
import {
  TransactionType,
} from '../../../../features/transaction/transaction-types' 
import { ConsumerTransaction } from '../../../../features/consumers/consumers-types'
import { formatDatetime, formatTransactionType } from '../../../../utils/format'
// -----------------------------------

const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  width: 100%;
  align-items: flex-start;
`

const Label = styled(IonLabel)`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 90%;
  margin: 0;
`
const Date = styled.div`
  font-size: .8rem;
  margin-bottom: 0.2rem;
`
const Amount = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
`
const Field = styled.div`
  font-size: 1.1rem;
`
const DetailsPanel = styled.div`
  flex-grow: 1;
`

type TransactionProps = {
  transaction: ConsumerTransaction
  children?: ReactNode
}

function Details(props: TransactionProps){
  const { transaction: tx } = props

  return (
    <DetailsPanel data-testid='transaction-details'>
      <Date>{formatDatetime(tx.timestamp)}</Date>
      <Amount>${Number(tx.data.amount)}</Amount>
      <Field>{tx.type}</Field>
      <Field>{tx.store.name}</Field>
      <Field>{tx.source}</Field>
    </DetailsPanel>
  )
}

export default function Transaction(props: TransactionProps){
  const { transaction: tx , ...rest} = props;

  return <IonItem data-testid='transaction-item' {...rest}>
    <Container>
      <Details transaction={tx}/>
    </Container>
  </IonItem>
}


