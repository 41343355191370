import React from "react";
import { FinancialInstitutions } from "../../../../../features/financial-institutions/financial-institutions-types";

import {
  Item,
  Column,
  ItemText,
  TextLabel,
} from './styles';

interface FinancialInstitutionItemProps {
  financialInstitution: FinancialInstitutions;
}

export const FinancialInstitutionItem: React.FC<FinancialInstitutionItemProps> = ({
  financialInstitution: {
    id,
    fiid,
    name,
  },
}) => {
  return (
    <>
      <Item
        data-testid="financial-institution-item"
        button
        routerLink={`/financial-institutions/${id}`}
      >
        <Column>
          <ItemText data-testid="financial-institution-id">
            <TextLabel>ID: </TextLabel> {id}
          </ItemText>
          <ItemText data-testid="financial-institution-fiid">
            <TextLabel>FIID: </TextLabel> {fiid}
          </ItemText>
          <ItemText data-testid="financial-institution-name">
            <TextLabel>Name: </TextLabel> {name}
          </ItemText>
        </Column>
      </Item>
    </>
  )
}
