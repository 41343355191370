import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useGetBusinessInfo } from '../../../features/businesses/businesses-resolver';
import { useGetStores } from '../../../features/stores/stores-resolver';
import { Store } from '../../../features/stores/stores-types';
import BusinessInfo from './components/BusinessInfo';
import StoreDetailsModal from './components/StoreDetailsModal';
import { AddStoreButton, AddStoreIcon, BackButton, BackButtonContainer, Centered } from './styles';
import { useIsDesktopPlatform } from '../../../utils/hooks';
import EditBusinessModal from './components/EditBusinessModal';
import StoresList from './components/StoresList';
import StoreFilesModal from './components/StoreFilesModal';
import StoreBrandsModal from './components/StoreBrandsModal';
import StoreDemographicsModal from './components/StoreDemographicsModal';

const useStore = () => useState<Store | undefined>(undefined);

const BusinessDetails: React.FC = () => {
  const { id } = useParams() as any;

  const isDesktopPlatform = useIsDesktopPlatform();

  const [showStoreDetailsModal, setShowStoreDetailsModal] = useState(false);
  const [showEditBusinessModal, setShowEditBusinessModal] = useState(false);
  const [selectedStoreForDetails, setSelectedStoreForDetails] = useStore();
  const [selectedStoreForFiles, setSelectedStoreForFiles] = useStore();
  const [selectedStoreForBrands, setSelectedStoreForBrands] = useStore();
  const [selectedStoreForDemographics, setSelectedStoreForDemographics] = useStore();

  const [{
    isLoading: isBusinessInfoLoading,
    error: businessInfoError,
    data: businessInfo,
  }, getBusinessInfo] = useGetBusinessInfo({ id: Number(id) });

  const [{
    isLoading: areStoresLoading,
    error: storesError,
    data: stores,
  }, getStores] = useGetStores({ businessId: Number(id) });

  useEffect(() => {
    if (id) {
      getBusinessInfo();
      getStores();
    }
  }, [id]);

  const businessInfoLoaded = businessInfo && !isBusinessInfoLoading;
  const storesLoaded = stores && !areStoresLoading;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {isDesktopPlatform
              ? <IonMenuButton data-testid="menu-button"></IonMenuButton>
              : <IonBackButton defaultHref={'/businesses'}></IonBackButton>
            }
          </IonButtons>
          <IonTitle>Business</IonTitle>
          <AddStoreButton
            isDesktopPlatform={isDesktopPlatform}
            onClick={() => setShowStoreDetailsModal(true)}
          >
            <AddStoreIcon isDesktopPlatform={isDesktopPlatform} />
            {isDesktopPlatform && 'Add a store'}
          </AddStoreButton>
        </IonToolbar>
      </IonHeader>

      {isDesktopPlatform &&
        <BackButtonContainer data-testid="back-button">
          <BackButton text="Back to all businesses" defaultHref={'/businesses'} />
        </BackButtonContainer>
      }

      {(isBusinessInfoLoading || areStoresLoading) && (
        <Centered>
          <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
        </Centered>
      )}

      {(businessInfoLoaded || storesLoaded) && (
        <IonContent>
          {businessInfoLoaded &&
            <BusinessInfo
              businessDetails={businessInfo}
              onOpenEditModal={() => setShowEditBusinessModal(true)}
              isDesktopPlatform={isDesktopPlatform}
            />
          }

          {storesLoaded &&
            <StoresList
              businessId={id}
              stores={stores}
              onOpenStoreDetailsModal={store => {
                setSelectedStoreForDetails(store);
                setShowStoreDetailsModal(true);
              }}
              onOpenStoreFilesModal={setSelectedStoreForFiles}
              onOpenStoreBrandsModal={setSelectedStoreForBrands}
              onOpenStoreDemographicsModal={setSelectedStoreForDemographics}
              isDesktopPlatform={isDesktopPlatform}
            />
          }
        </IonContent>
      )}

      <IonAlert
        isOpen={!!businessInfoError || !!storesError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <EditBusinessModal
        businessId={Number(id)}
        businessInfo={businessInfo?.business}
        isOpen={showEditBusinessModal}
        onClose={() => setShowEditBusinessModal(false)}
      />

      <StoreDetailsModal
        businessId={Number(id)}
        selectedStore={selectedStoreForDetails}
        isOpen={showStoreDetailsModal}
        onClose={() => {
          setShowStoreDetailsModal(false);
          setSelectedStoreForDetails(undefined);
        }}
      />

      <StoreFilesModal
        store={selectedStoreForFiles}
        onClose={() => {
          setSelectedStoreForFiles(undefined);
        }}
      />

      <StoreBrandsModal
        store={selectedStoreForBrands}
        onClose={() => {
          setSelectedStoreForBrands(undefined);
        }}
      />

      <StoreDemographicsModal
        store={selectedStoreForDemographics}
        onClose={() => {
          setSelectedStoreForDemographics(undefined);
        }}
      />
    </IonPage>
  );
};

export default BusinessDetails;
