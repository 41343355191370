import { useGet, usePatch, usePost } from '../fetch/fetch-resolver';
import { setBusinessPromoCodes, setSelectedBusinessPromoCode } from './business-promo-codes-actions';
import { BusinessPromoCode, BusinessPromoCodeWithDetails } from './business-promo-codes-types';
import { PaginatedResponse } from '../../types/api';
import { UseGetOptions } from '../fetch/fetch-types';

export const useGetBusinessPromoCodes = (
  options = {} as Partial<UseGetOptions>
) => {
  return useGet<PaginatedResponse<BusinessPromoCode[]>>({
    url: 'businesses/promo-codes',
    version: 'v2',
    action: setBusinessPromoCodes,
    selector: (state) => state.businessPromoCodes.list,
    initialParams: {
      page: 1,
      limit: 30
    },
    ...options
  });
};

export const useGetBusinessPromoCodeInfo = (
  { id }: { id: number },
) => {
  return useGet<BusinessPromoCodeWithDetails>({
    url: `businesses/promo-codes/${id}`,
    version: 'v2',
    action: setSelectedBusinessPromoCode,
    selector: (state) => id === state.businessPromoCodes.selected?.id
      ? state.businessPromoCodes.selected
      : undefined,
  });
};

export const usePatchBusinessPromoCode = (
  { id }: { id: number }
) => {
  return usePatch({
    url: `businesses/promo-codes/${id}`,
    version: 'v2'
  });
};

