import { ActionCreator } from '../actions/actions-types';

export const merchantSuggestionsActionTypes = {
  SET_MERCHANT_SUGGESTIONS: 'setMerchantSuggestions',
  UPDATE_MERCHANT_SUGGESTION: 'updateMerchantSuggestion',
  REMOVE_GROUPED_MERCHANT_SUGGESTIONS: 'removeGroupedMerchantSuggestions',
  REMOVE_INDIVIDUAL_MERCHANT_SUGGESTION: 'removeIndividualMerchantSuggestion'
};

export const setMerchantSuggestions: ActionCreator = (payload) => ({
  type: merchantSuggestionsActionTypes.SET_MERCHANT_SUGGESTIONS,
  payload
});

export const updateMerchantSuggestion: ActionCreator = (payload) => ({
  type: merchantSuggestionsActionTypes.UPDATE_MERCHANT_SUGGESTION,
  payload
});

export const removeGroupedMerchantSuggestions: ActionCreator = (payload) => ({
  type: merchantSuggestionsActionTypes.REMOVE_GROUPED_MERCHANT_SUGGESTIONS,
  payload
});

export const removeIndividualMerchantSuggestion: ActionCreator = (payload) => ({
  type: merchantSuggestionsActionTypes.REMOVE_INDIVIDUAL_MERCHANT_SUGGESTION,
  payload
});
