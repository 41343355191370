import React, {ReactNode, useState} from 'react';
import {v4 as uuid} from 'uuid'

import {
  IonItem, 
  IonLabel, 
  IonButton, 
  IonList,
  IonCol, 
  IonIcon,
  IonPage
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router' 
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { 
  Consumer, 
  Panel, 
  PanelOptions 
} from '../../../../features/consumers/consumers-types';
import Labels from '../Labels'
import Item from './item'
import ConsumerDetails from '../ConsumerDetails'
import ConsumerTransactions from '../ConsumerTransactions'
// ---------------------------------

type ConsumerItemsProps = {
  consumers: Consumer[]
  changePanel: (newPanel: Panel) => void
  panel: Panel
  [key: string]: any
}

export default function ConsumerItems(
  {consumers, changePanel, panel, ...rest}: ConsumerItemsProps
){
  const [details, setDetails] = useState< Consumer | null >(null)

  const toggleDetails = (options: PanelOptions) => {
    setDetails(options.consumer)
    changePanel(options.panel)
  }

  return <IonList>
    {
      consumers.map((c, i) => {
        return <Item key={c?.consumerId ? c.consumerId : uuid()} consumer={c}/>
      })
    }
  </IonList>
}
