import { PaginatedResponse } from "../../types/api";
import {
  FinancialInstitutions,
  FinancialInstitutionWithDetails,
  ResolutionType
} from "./financial-institutions-types";
import { financialInstitutionsActionTypes } from './financial-institutions-actions';

import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

interface FinancialInstitutionsStore {
  list: PaginatedResponse<FinancialInstitutions[]>;
  listAll: FinancialInstitutions[];
  searchQuery: string;
  selected: FinancialInstitutionWithDetails | undefined;
  resolutionTypes: ResolutionType[];
}

export const financialInstitutionsInitialState = (): FinancialInstitutionsStore =>
  ({
    list: {
      data: [],
      pagination: {
        page: 0,
        last: 0,
        limit: 0,
        total: 0
      }
    } as PaginatedResponse<FinancialInstitutions[]>,
    listAll: [],
    searchQuery: '',
    selected: undefined,
    resolutionTypes: [] as ResolutionType[],
  });

export const financialInstitutionsReducer = (
  state = financialInstitutionsInitialState(),
  action: Action<FinancialInstitutionsStore>,
): any => {
  switch(action.type) {
    case financialInstitutionsActionTypes.SET_FINANCIAL_INSTITUTIONS: {
      const { pagination, data } = action.payload.list;

      if (pagination.page === 1) {
        return {
          ...state,
          list: { data, pagination }
        };
      }

      if (pagination.page <= pagination.last) {
        const merged = (
          data ?
          state.list.data?.concat(data) || data :
          state.list.data
        )

        return {
          ...state,
          list: {
            data: merged,
            pagination
          }
        };
      }

      return state;
    }

    case financialInstitutionsActionTypes.SET_FINANCIAL_INSTITUTIONS_ALL: {
      const { listAll } = action.payload;

      if (listAll) {
        return {
          ...state,
          listAll
        }
      }

      return state
    }

    case financialInstitutionsActionTypes.SET_SELECTED_FINANCIAL_INSTITUTION: {
      const { selected } = action.payload;

      if (selected) {
        return {
          ...state,
          selected,
        };
      }

      return state;
    }

    case financialInstitutionsActionTypes.SET_FINANCIAL_INSTITUTIONS_SEARCH_QUERY: {
      const { searchQuery } = action.payload;

      if (searchQuery !== undefined) {
        return {
          ...state,
          searchQuery,
        };
      }

      return state;
    }

    case financialInstitutionsActionTypes.UPDATE_SELECTED_FINANCIAL_INTITUTION_INFO: {
      const { updatedFinancialInstitution } = action.payload as any;

      const { selected } = state;

      if (selected) {
        return {
          ...state,
          selected: {
            ...selected,
            ...updatedFinancialInstitution
          },
        };
      }

      return state;
    }

    case financialInstitutionsActionTypes.SET_RESOLUTION_TYPES: {
      const { resolutionTypes } =  action.payload;

      return {
        ...state,
        resolutionTypes,
      }
    }

    case financialInstitutionsActionTypes.CLEAR_FINANCIAL_INSTITUTION:
    case actionTypes.CLEAR_STATE:
      return {
        ...state,
        list: financialInstitutionsInitialState().list
      };

    default:
      return state;
  }
}
