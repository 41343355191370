import {
  IonAlert,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clearBusinessEntities, setBusinessEntitiesSearchQuery } from '../../../features/business-entities/business-entities-actions';
import { useGetBusinessEntities } from '../../../features/business-entities/business-entities-resolver';
import { useAppState } from '../../../shared/AppContext';
import { usePaginationMode } from '../../../utils/hooks';
import { BusinessEntityItem } from './components/BusinessEntityItem';
import InfiniteScroll from '../../../components/InfiniteScroll';
import PaginationArrows from '../../../components/PaginationArrows';
import { Centered, Searchbar, NoDataText } from './styles';

const BusinessEntities: React.FC = () => {
  const history = useHistory();

  const {
    dispatch: appStateDispatch,
    state: appState
  } = useAppState();

  const [
    {
      data: { data: businessesEntities, pagination },
      isLoading: areBusinessEntitiesLoading,
      error: businessesError
    },
    getBusinessEntities
  ] = useGetBusinessEntities({ paginated: true });

  const paginationMode = usePaginationMode();

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [isInfiniteScrollDisabled, setIsInfiniteScrollDisabled] = useState(false);
  const [query, setQuery] = useState<string>(history.action === 'POP'
    ? appState.businessEntities.searchQuery
    : ''
  );
  const [usePreviousBusinessesData, setUsePreviousBusinessesData] = useState(history.action === 'POP');

  useEffect(() => {
    if (!usePreviousBusinessesData) {
      setIsInfiniteScrollDisabled(false);
      setPage(1);
      appStateDispatch(clearBusinessEntities());
      appStateDispatch(setBusinessEntitiesSearchQuery(query));
      getBusinessEntities({
        refresh: true,
        params: {
          page: 1,
          limit,
          query
        }
      });
    } else {
      const defaultPage = pagination?.page || 1;
      setPage(defaultPage);
      setUsePreviousBusinessesData(false);
      getBusinessEntities({
        params: {
          page: defaultPage,
          limit,
          query
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const changePage = (newPage: number) => {
    appStateDispatch(clearBusinessEntities());
    setPage(newPage);
    getBusinessEntities({
      refresh: true,
      params: {
        page: newPage,
        limit,
        query
      }
    });
  }

  const changePageInfinite = (event: any) =>{
    setPage(page + 1);
    setTimeout(() => {
      getBusinessEntities({
        refresh: true,
        params: {
          page: page + 1,
          limit,
          query
      }
      });
      event.target.complete();
    }, 500);

    if ((businessesEntities?.length || 0) >= pagination?.total) {
      setIsInfiniteScrollDisabled(true);
    }
  }

  const generateBusinessEntitiesList = () => businessesEntities?.map(business => (
    <BusinessEntityItem
      key={business.id}
      businessEntity={business}
    />
  ));

  const updateSearchQuery = (e: any) => {
    const value = e.detail.value || '';
    setQuery(value.trim());
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton data-testid="menu-button"></IonMenuButton>
          </IonButtons>
          <IonTitle>Business Entities</IonTitle>
        </IonToolbar>
      </IonHeader>

      <Searchbar
        data-testid="search-input"
        value={query}
        onIonChange={updateSearchQuery}
        debounce={1000}
      />

      {businessesEntities && (
        <IonContent>
          {generateBusinessEntitiesList()}

          {
            !areBusinessEntitiesLoading && businessesEntities?.length === 0 && (
              <NoDataText>No entities were found</NoDataText>
            )
          }

          {
            paginationMode === 'pagination'
              ? <PaginationArrows
                changePage={changePage}
                page={page}
                pagination={pagination}
              />
              : <InfiniteScroll
                changePage={changePageInfinite}
                disabled={isInfiniteScrollDisabled}
              />
          }
        </IonContent>
      )}

      {
        areBusinessEntitiesLoading && page === 1 && (
          <Centered>
            <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
          </Centered>
        )
      }

      <IonAlert
        isOpen={!!businessesError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />
    </IonPage>
  );
};

export default BusinessEntities;
