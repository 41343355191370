import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useIsDesktopPlatform } from '../../../utils/hooks';
import { useGetApps } from '../../../features/apps/apps-resolver';
import { Checkbox, CheckboxContainer, FormInput, FormItem, FormLabel, Select } from './styles';
import { usePostCampaignsDeactivate } from '../../../features/campaigns/campaigns-resolver';

export interface FormValues {
  appId: string;
  deactivateAll: boolean;
  dataType: 'storeIds' | 'businessIds';
  data: string;
}

const campaignBulkDeactivationSchema = yup.object().shape({
  appId: yup
    .string()
    .typeError('Invalid app')
    .when('deactivateAll', {
      is: false,
      then: yup.string().required('App ID is required'),
      otherwise: yup.string()
    }),
  deactivateAll: yup
    .boolean()
    .typeError('Invalid value'),
  dataType: yup
    .string()
    .oneOf(['storeIds', 'businessIds'])
    .typeError('Invalid value type, needs to be storeIds or businessIds'),
  data: yup
    .string()
    .typeError('Invalid data')
    .required('Insert comma separated list of selected data type'),
});

const CampaignBulkDeactivation: React.FC = () => {
  const isDesktopPlatform = useIsDesktopPlatform();

  const [{
    data: apps,
    error: getAppsError,
    isLoading: areAppsLoading
  }, getApps] = useGetApps();

  const [
    { isLoading: isPostCampaignsLoading },
    postCampaignsDeactivate
  ] = usePostCampaignsDeactivate();

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(campaignBulkDeactivationSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      deactivateAll: false,
      dataType: 'businessIds',
    }
  });

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const errorMessageToastRef = React.useRef<HTMLIonToastElement>(null);
  const successMessageToastRef = React.useRef<HTMLIonToastElement>(null);

  useEffect(() => {
    const errors = Object.keys(formErrors);
    if (errors.length > 0) {
      setFormErrorMessage('Invalid data. Please verify all fields.');
    } else {
      setFormErrorMessage('');
    }
  }, [formErrors]);

  useEffect(() => {
    getApps();
  }, []);

  const handleDeactivateAllChange = (deactivateAll: boolean) => {
    if (deactivateAll) {
      setValue('appId', '');
    }
  };

  const handleAppIdSelected = (appId: string | undefined | null) => {
    if (appId && appId.length > 0) {
      setValue('deactivateAll', false);
    }
  };

  const handleStoreSubmit: SubmitHandler<FormValues> = async (formPayload) => {    
    try {
      const payload = {
        [formPayload.dataType]: formPayload.data.split(',').map(id => id.trim()),
        ...(formPayload.appId ? { appIds: [formPayload.appId] } : {}),
      } as any;

      await errorMessageToastRef.current?.dismiss();
      await successMessageToastRef.current?.dismiss();

      const res = await postCampaignsDeactivate(payload);

      if (res.errorCode) {
        if (res.statusCode && res.statusCode !== 500) {
          throw new Error(res.message);
        } else {
          throw new Error('Could not deactivate campaigns. Try again later.');
        }
      }

      if (res.campaigns > 0) {
        setSuccessMessage(`Deactivated ${res.campaigns} campaign(s) from ${res.businesses} business(es) and ${res.stores} store(s)`);
      } else {
        setSuccessMessage(`No campaigns were deactivated for ${payload[formPayload.dataType].length} ${formPayload.dataType === 'businessIds' ? 'business(es)' : 'store(s)'}`);
      }

      reset();
    } catch (err: any) {
      setFormErrorMessage(err.message);
    }
  }

  const isLoading = isPostCampaignsLoading || areAppsLoading;
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {isDesktopPlatform
              ? <IonMenuButton data-testid="menu-button"></IonMenuButton>
              : <IonBackButton defaultHref={'/businesses'}></IonBackButton>
            }
          </IonButtons>
          <IonTitle>Campaign Bulk Deactivation</IonTitle>
        </IonToolbar>
      </IonHeader>

      <form onSubmit={handleSubmit(handleStoreSubmit)} style={{ margin: '20px 20px auto 20px' }}>
        <FormItem>
          <FormLabel>App</FormLabel>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                placeholder="No app selected"
                value={value}
                onIonChange={(e) => {
                  handleAppIdSelected(e.detail.value);
                  onChange(e.detail.value);
                }}
                error={!!error}
                interfaceOptions={{
                  header: 'Apps',
                  cssClass: 'app-select-modal',
                }}
                disabled={isLoading}
              >
                {apps?.map(app => (
                  <IonSelectOption key={app.id} value={app.id}>{app.name} ({app.id})</IonSelectOption>
                ))}
              </Select>
            )}
            control={control}
            name="appId"
          />
        </FormItem>

        <FormItem>
          <FormLabel />
          <Controller
            render={({ field: { onChange, value } }) => (
              <CheckboxContainer>
                <Checkbox
                  slot="start"
                  checked={value}
                  onIonChange={(e) => {
                    handleDeactivateAllChange(e.detail.checked);
                    onChange(e.detail.checked);
                  }}
                  error={'appId' in formErrors}
                />
                <IonLabel>All active campaigns / All store campaigns</IonLabel>
              </CheckboxContainer>
            )}
            control={control}
            name="deactivateAll"
          />
        </FormItem>

        <FormItem>
          <FormLabel>Data Type</FormLabel>
          <Controller
            render={({ field: { onChange, value } }) => (
              <IonRadioGroup value={value} onIonChange={onChange} style={{ display: 'flex' }}>
                <IonItem style={{ marginRight: 10 }}>
                  <IonRadio slot="start" value="businessIds" style={{ marginRight: 20 }} />
                  <IonLabel>Business IDs</IonLabel>
                </IonItem>

                <IonItem>
                  <IonRadio slot="start" value="storeIds" style={{ marginRight: 20 }} />
                  <IonLabel>Store IDs</IonLabel>
                </IonItem>
              </IonRadioGroup>
            )}
            control={control}
            name="dataType"
          />
        </FormItem>
        
        <FormItem>
          <FormLabel>Data</FormLabel>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormInput
                value={value}
                type="text"
                placeholder="Comma separated list of selected data type"
                onIonChange={onChange}
                error={!!error}
                clearInput
                disabled={isLoading}
              />
            )}
            control={control}
            name="data"
          />
        </FormItem>

        <IonButton
          type="submit"
          disabled={isLoading}
          style={{ marginTop: 30 }}
        >
          Deactivate
        </IonButton>
      </form>

      <IonToast
        ref={errorMessageToastRef}
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonToast
        ref={successMessageToastRef}
        isOpen={successMessage.length > 0}
        color="success"
        onDidDismiss={() => setSuccessMessage('')}
        message={successMessage}
        position="top"
        buttons={['OK']}
      />

      <IonAlert
        isOpen={getAppsError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading isOpen={isLoading} />
    </IonPage>
  );
};

export default CampaignBulkDeactivation;
