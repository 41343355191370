import { storeLocalizationActionTypes } from './store-localization-actions';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { StoreLocalization } from './store-localization-types';

type StoreLocalizationStore = {
  storeId: number;
  data: StoreLocalization;
} | undefined;

export const storeLocalizationInitialState = (): StoreLocalizationStore => undefined;

export const storeLocalizationReducer = (
  state = storeLocalizationInitialState(),
  action: Action<StoreLocalizationStore>,
): any => {
  switch (action.type) {
    case storeLocalizationActionTypes.SET_STORE_LOCALIZATION: {
      return action.payload;
    }

    case storeLocalizationActionTypes.CLEAR_STORE_LOCALIZATION:
    case actionTypes.CLEAR_STATE:
      return storeLocalizationInitialState();

    default:
      return state;
  }
};
