import { ActionCreator } from '../actions/actions-types';

export const businessesActionTypes = {
  SET_BUSINESSES: 'setBusinesses',
  SET_SELECTED_BUSINESS: 'setSelectedBusiness',
  UPDATE_SELECTED_BUSINESS_INFO: 'updateSelectedBusinessInfo',
  CLEAR_BUSINESSES: 'clearBusinesses',
  SET_BUSINESSES_SEARCH_QUERY: 'setBusinessesSearchQuery'
};

export const setBusinesses: ActionCreator = (payload) => ({
  type: businessesActionTypes.SET_BUSINESSES,
  payload: {
    list: payload,
  }
});

export const setSelectedBusiness: ActionCreator = (payload) => ({
  type: businessesActionTypes.SET_SELECTED_BUSINESS,
  payload: {
    selected: payload,
  }
});

export const updateSelectedBusinessInfo: ActionCreator = (payload) => ({
  type: businessesActionTypes.UPDATE_SELECTED_BUSINESS_INFO,
  payload: {
    updatedBusinessInfo: payload,
  }
});

export const clearBusinesses: ActionCreator = (payload) => ({
  type: businessesActionTypes.CLEAR_BUSINESSES,
});

export const setBusinessesSearchQuery: ActionCreator = (payload) => ({
  type: businessesActionTypes.SET_BUSINESSES_SEARCH_QUERY,
  payload: {
    searchQuery: payload,
  }
});
