import {
  consumersActionTypes, 
  consumerTransactionsActionTypes
} from './consumers-actions';
import {Consumer, ConsumerTransaction} from './consumers-types';
import {PaginatedResponse} from '../../types/api'
import {actionTypes} from '../actions/actions'
import {Action} from '../actions/actions-types'

// ---------------------------
// Consumers:

type ConsumersStore = {
  list: PaginatedResponse<Consumer[]>
  selected: undefined
}

export function consumersInitialState(): ConsumersStore {
  const response: PaginatedResponse<Consumer[]> = {
    data: undefined,
    pagination: {
      page: 1,
      last: 0,
      limit: 50,
      total: 0
    }
  }

  return {
    list: response,
    selected: undefined
  }
}

export function consumersReducer(
  state = consumersInitialState(),
  action: Action<ConsumersStore>
): any{
  switch(action.type) {
    case consumersActionTypes.SET_CONSUMERS:{
      const {pagination, data} = action.payload.list;

      if (pagination.page === 1){
        return {...state, list: {data, pagination}}
      }
      // Current page is still not the last page:
      if (pagination.page <= pagination.last){
        const merged = (
          data ? 
          state.list.data?.concat(data) 
          || data : state.list.data
        ) 
        return {
          ...state,
          list: {
            data: merged,
            pagination
          }
        }; 
      }
      return state
    }

    case consumersActionTypes.SET_SELECTED_CONSUMER:{
      const consumer = action.payload
   
      return {
        ...state,
        selected: consumer
      }

    }

    case consumersActionTypes.CLEAR_CONSUMERS:{
      return consumersInitialState(); 
    }

    default: {
      return state;
    }
  }
};

// ---------------------------------------------------------------------------------
// Consumer Transactions:

type ConsumerTransactionsStore = {
  list: PaginatedResponse<ConsumerTransaction[]>
  selected: undefined
}

export function consumerTransactionsInitialState(): ConsumerTransactionsStore{
  const response: PaginatedResponse<ConsumerTransaction[]> = {
    data: undefined,
    pagination: {
      page: 1,
      last: 0,
      limit: 50,
      total: 0
    }
  }
  return {
    list: response,
    selected: undefined
  }
}

export function consumerTransactionsReducer(
  state = consumerTransactionsInitialState(),
  action: Action<ConsumerTransactionsStore>
): any{
  switch(action.type) {
    case consumerTransactionsActionTypes.SET_CONSUMER_TRANSACTIONS: 
      const {pagination, data} = action.payload.list;

      if (pagination.page === 1){
        return {...state, list: {data, pagination}}
      }
      // Current page is still not the last page:
      if (pagination.page <= pagination.last){
        const merged = (
          data ? 
          state.list.data?.concat(data) 
          || data : state.list.data
        ) 
        return {
          ...state,
          list: {
            data: merged,
            pagination
          }
        }; 
      }
      return state
    case consumerTransactionsActionTypes.CLEAR_CONSUMER_TRANSACTIONS:
      return consumerTransactionsInitialState(); 
    default: 
      return state;
  }
};

