import styled from "styled-components";
import { IonItem, IonCol } from '@ionic/react';

export const Item = styled(IonItem)`
  --background: transparent;
  --padding-end: 16px;
`;

export const Column = styled(IonCol)`
  margin: 10px;
`;

export const ItemText = styled.p`
  padding: 0px;
  margin: 5px 0;
  overflow-wrap: break-word;
`;

export const TextLabel = styled.span`
  font-weight: bold;
`;
