import { IonButton, IonImg, IonLabel, IonThumbnail } from "@ionic/react";
import React, { useRef } from "react";
import { StoreFileType } from "../../../../../../../features/stores/stores-types";
import { useIsDesktopPlatform } from "../../../../../../../utils/hooks";
import { ButtonsContainer, StoreFile } from "./styles";

const mapFileTypeData = (fileType: StoreFileType, isDesktopPlatform: boolean) => {
  switch (fileType) {
    case StoreFileType.LOGO:
      return {
        label: 'Logo (260x260)',
        style: isDesktopPlatform
          ? {
            width: 260,
            height: 260,
          }
          : {
            width: 130,
            height: 130,
          }
      };
    case StoreFileType.PRIMARY:
      return {
        label: 'Banner (960x420)',
        style: isDesktopPlatform
          ? {
            width: 960,
            height: 420,
          }
          : {
            width: 480,
            height: 210
          }
      };
    case StoreFileType.SECONDARY:
      return {
        label: 'Banner Alt (960x420)',
        style: isDesktopPlatform
          ? {
            width: 960,
            height: 420,
          }
          : {
            width: 480,
            height: 210
          }
      };
  }
};

interface StoreFileItemProps {
  type: StoreFileType;
  src: string | null;
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemove: () => void;
  isLoading: boolean;
}

const StoreFileItem: React.FC<StoreFileItemProps> = ({
  type,
  src,
  onFileSelect,
  onRemove,
  isLoading,
}) => {
  const isDesktopPlatform = useIsDesktopPlatform();
  
  const { label, style } = mapFileTypeData(type, isDesktopPlatform);
  
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <StoreFile>
      <IonLabel>{label}</IonLabel>

      {src &&
        <IonThumbnail style={style}>
          <IonImg src={src} />
        </IonThumbnail>
      }

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={onFileSelect}
      />

      <ButtonsContainer>
        <IonButton
          disabled={isLoading}
          onClick={() => fileInputRef.current?.click()}
        >
          {src ? 'Replace' : 'Add'}
        </IonButton>

        <IonButton
          disabled={!src || isLoading}
          onClick={onRemove}
        >
          Remove
        </IonButton>
      </ButtonsContainer>
    </StoreFile>
  );
}

export default StoreFileItem;