import { actionsReducer } from '../features/actions/actions-reducer';
import { BaseMap } from '../types/generic';
import { AppState, initialState } from './state';
import { Action } from '../features/actions/actions-types';
import { adminReducer } from '../features/admin/admin-reducer';
import { authReducer } from '../features/auth/auth-reducer';
import {
  merchantSuggestionsReducer
} from '../features/merchant-suggestions/merchant-suggestions-reducer';
import { businessesReducer } from '../features/businesses/businesses-reducer';
import {
  consumersReducer,
  consumerTransactionsReducer
} from '../features/consumers/consumers-reducer';
import {
  storeCategoriesReducer
} from '../features/store-categories/store-categories-reducer';
import { brandsReducer } from '../features/brands/brands-reducer';
import {
  storeLocalizationReducer
} from '../features/store-localization/store-localization-reducer';
import {
  storeDemographicsReducer
} from '../features/store-demographics/store-demographics-reducer';
import { storesReducer } from '../features/stores/stores-reducer';
import { businessEntitiesReducer } from '../features/business-entities/business-entities-reducer';
import { appsReducer } from '../features/apps/apps-reducer';
import { businessPromoCodesReducer } from '../features/business-promo-codes/business-promo-codes-reducer';
import { campaignSetsReducer } from '../features/campaign-sets/campaign-sets-reducer';
import { descriptorsReducer } from '../features/descriptors/descriptors-reducer';
import { financialInstitutionsReducer } from '../features/financial-institutions/financial-institutions-reducer';

export function combineReducers(reducers: BaseMap<Reducer>) {
  return (state: AppState = initialState, action: Action<never>) => {
    const newState: Partial<AppState> = {};
    for (const key in reducers) {
      // guarding against unnecessary prototype field iterations
      // see: https://eslint.org/docs/rules/guard-for-in
      if (Object.prototype.hasOwnProperty.call(reducers, key)) {
        newState[key as keyof AppState] = reducers[key](
          state[key as keyof AppState],
          action
        );
      }
    }
    return newState as AppState;
  };
}

export const reducer = combineReducers({
  actions: actionsReducer,
  auth: authReducer,
  admin: adminReducer,
  merchantSuggestions: merchantSuggestionsReducer,
  businesses: businessesReducer,
  consumers: consumersReducer,
  consumerTransactions: consumerTransactionsReducer,
  stores: storesReducer,
  storeCategories: storeCategoriesReducer,
  brands: brandsReducer,
  storeLocalization: storeLocalizationReducer,
  storeDemographics: storeDemographicsReducer,
  businessEntities: businessEntitiesReducer,
  apps: appsReducer,
  businessPromoCodes: businessPromoCodesReducer,
  campaignSets: campaignSetsReducer,
  descriptors: descriptorsReducer,
  financialInstitutions: financialInstitutionsReducer,
});

export type Reducer<T = any> = (state: T, action: Action<never>) => T;
