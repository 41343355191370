import { IonButton, IonIcon, IonImg, IonLabel } from "@ionic/react";
import { removeCircleOutline } from "ionicons/icons";
import { Brand } from "../../../../../../../features/brands/brands-types";
import { SelectedBrandItemContainer, Thumbnail } from "./style";

type SelectedBrandProps = {
  brand: Brand;
  onRemove: () => void;
}

const SelectedBrandItem: React.FC<SelectedBrandProps> = ({ brand, onRemove }) => {
  return (
    <SelectedBrandItemContainer>
      <Thumbnail>
        <IonImg src={brand.image} />
      </Thumbnail>
      <IonLabel>{brand.name}</IonLabel>
      <IonButton
        fill="clear"
        color="danger"
        slot="end"
        onClick={onRemove}
      >
        <IonIcon
          icon={removeCircleOutline}
          slot="icon-only"
        />
      </IonButton>
    </SelectedBrandItemContainer>
  );
};

export default SelectedBrandItem;