import { IonSelectOption } from '@ionic/react';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Category } from '../../../../../../features/store-categories/store-categories-types';
import { phoneFormatter } from '../../../../../../utils/format';
import { FormValues } from '../form-validation';
import { Checkbox, CheckboxItem, CheckboxLabel, FormInput, FormItem, FormLabel, FormTextArea, Select } from '../styles';

interface FormItemsProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormValues>;
  categories: Category[];
  watchedCategoryId: number | undefined;
}

const FormItems: React.FC<FormItemsProps> = ({
  control,
  categories,
  watchedCategoryId,
}) => (
  <>
    <FormItem>
      <FormLabel>Categories</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
          >
            {categories?.map(c => (
              <IonSelectOption key={c.id} value={c.id}>{c.name}</IonSelectOption>
            ))}
          </Select>
        )}
        control={control}
        name="categoryId"
      />
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            value={value}
            placeholder=""
            onIonChange={onChange}
            error={!!error}
          >
            {categories?.find(c => c.id === watchedCategoryId)?.subcategories.map(s => (
              <IonSelectOption key={s.id} value={s.id}>{s.name}</IonSelectOption>
            ))}
          </Select>
        )}
        control={control}
        name="subcategoryId"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Collinson Location Id</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="collinsonLocationId"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Accepted Card Types</FormLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
            <CheckboxItem>
              <Checkbox
                checked={value}
                onIonChange={(e) => {
                  onChange(e.detail.checked);
                }}
              />
              <CheckboxLabel>
                VISA
              </CheckboxLabel>
            </CheckboxItem>
        )}
        control={control}
        name="visaCardAccepted"
      />
      <Controller
        render={({ field: { onChange, value } }) => (
            <CheckboxItem>
              <Checkbox
                checked={value}
                onIonChange={(e) => {
                  onChange(e.detail.checked);
                }}
              />
              <CheckboxLabel>
                Mastercard
              </CheckboxLabel>
            </CheckboxItem>
        )}
        control={control}
        name="mastercardCardAccepted"
      />
      <Controller
        render={({ field: { onChange, value } }) => (
            <CheckboxItem>
              <Checkbox
                checked={value}
                onIonChange={(e) => {
                  onChange(e.detail.checked);
                }}
              />
              <CheckboxLabel>
                AMEX
              </CheckboxLabel>
            </CheckboxItem>
        )}
        control={control}
        name="amexCardAccepted"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Average Frequency / Month</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step=".01"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="averageTransactionFrequencyPerMonth"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Average Amount ($)</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step=".01"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="averageTransactionAmount"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Average Gross Margin (%)</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step=".01"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="averageGrossMargin"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Average Annual Churn (%)</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            type="number"
            step=".01"
            placeholder=""
            value={value}
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="averageAnnualChurnPercent"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Name *</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="name"
        rules={{ required: true }}
      />
    </FormItem>

    <FormItem>
      <FormLabel>Street Address *</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="streetAddress"
        rules={{ required: true }}
      />
    </FormItem>

    <FormItem>
      <FormLabel>City *</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="city"
        rules={{ required: true }}
      />
    </FormItem>

    <FormItem>
      <FormLabel>State *</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="state"
        rules={{ required: true }}
      />
    </FormItem>

    <FormItem>
      <FormLabel>Zip Code *</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="zipCode"
        rules={{ required: true }}
      />
    </FormItem>

    <FormItem>
      <FormLabel>Neighborhood</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="neighborhood"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Store Type</FormLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
            <CheckboxItem>
              <Checkbox
                checked={value}
                onIonChange={(e) => {
                  onChange(e.detail.checked);
                }}
              />
              <CheckboxLabel>
                Is this a store front?
              </CheckboxLabel>
            </CheckboxItem>
        )}
        control={control}
        name="isStorefront"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Description</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormTextArea
            value={value}
            placeholder=""
            onIonChange={onChange}
            error={!!error}
          />
        )}
        control={control}
        name="description"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Hours Open</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="hoursOpen"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Email</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="email"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Phone</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={phoneFormatter.format(value || '')}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="phone"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Website Url</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="websiteUrl"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Facebook Url</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="facebookUrl"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Twitter Url</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="twitterUrl"
      />
    </FormItem>

    <FormItem>
      <FormLabel>Instagram Url</FormLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormInput
            value={value}
            type="text"
            placeholder=""
            onIonChange={onChange}
            error={!!error}
            clearInput
          />
        )}
        control={control}
        name="instagramUrl"
      />
    </FormItem>
  </>
);

export default FormItems;
