import React from 'react';
import { BreadcrumbText } from './styles';

interface BreadcrumbProps {
  text: string
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  text
}) => {

  return (
    <BreadcrumbText>
      { text }
    </BreadcrumbText>
  )
}
