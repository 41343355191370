import styled from 'styled-components';
import { IonBackButton, IonButton, IonButtons } from '@ionic/react';

export const BackButtonContainer = styled(IonButtons).attrs({
  slot: 'start'
})`
  margin-top: 10px;
  margin-bottom: auto;
  margin-left: 2.5%;
`;

export interface AssignCampaignSetButtonProps {
  isDesktopPlatform: boolean;
}

export const ActionButton = styled(IonButton).attrs({
  color: 'light',
  slot: 'end'
})<AssignCampaignSetButtonProps>`
  --padding-start: 10px;
  --padding-end: 10px;
  margin: 0;
  margin-top: 5px;
  margin-right: 10px;
  height: ${props => props.isDesktopPlatform ? '45px' : '35px'};
`;

export const BackButton = styled(IonBackButton)`
  margin-bottom: 10px;
`;

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const Row = styled.div`
--background: transparent;
--padding-start: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
  margin: 5px auto 10px auto;
  padding: 0;
`;