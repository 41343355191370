import { IonButton, IonInput } from "@ionic/react";
import styled from "styled-components";

export const FormItemContainer = styled.div`
  display: flex;
  margin-bottom: 15px;

  @media (max-width: 715px) {
    flex-direction: column;
  }
`;

export const FormLabel = styled.label`
  color: var(--ion-color-medium-shade);
  font-weight: bold;
  font-size: 14px;
  width: 250px;
  margin-right: 5px;

  @media (max-width: 715px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

interface InputProps {
  error?: boolean;
}

export const FormInput = styled(IonInput)<InputProps>`
  margin-bottom: auto;
  background: var(--ion-color-light-shade);
  border: 1px solid var(${props => props.error
    ? '--ion-color-danger'
    : '--ion-color-medium-shade'
  });
  border-radius: 3px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TranslatedValueText = styled.p`
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: auto;
  flex: 1;
`;

export const Row = styled.div`
  flex: 1;
  display: flex;
`;

export const ResetButton = styled(IonButton)`
  --padding-start: 5px;
  --padding-end: 5px;
  margin-bottom: auto;
  margin-left: 5px;
`;