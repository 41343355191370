import { ActionCreator } from '../actions/actions-types';
import { useGet, usePatch } from '../fetch/fetch-resolver';
import { setStoreDemographics } from './store-demographics-actions';
import { StoreDemographics } from './store-demographics-types';

export const useGetStoreDemographics = (storeId: number) => {
  return useGet<StoreDemographics>({
    url: `stores/${storeId}/demographics`,
    version: 'v2',
    action: (data => setStoreDemographics({ storeId, data })) as ActionCreator,
    selector: (state) => storeId === state.storeDemographics?.storeId
      ? state.storeDemographics.data
      : undefined,
  });
};

export const usePatchStoreDemographics = (storeId: number) => {
  return usePatch({
    url: `stores/${storeId}/demographics`,
    version: 'v2',
  });
};