import { useGet, usePost } from '../fetch/fetch-resolver';
import { setDescriptors } from './descriptors-actions';
import { Descriptor } from './descriptors-types';
import { UseGetOptions } from '../fetch/fetch-types';

export const useGetDescriptors = (
  options = {} as Partial<UseGetOptions>,
) => {
  return useGet<Descriptor[]>({
    url: 'descriptors?limit=:limit&fiids=:fiids',
    version: 'v2',
    action: setDescriptors,
    selector: (state) => state.descriptors.list,
    initialParams: {
      limit: 10,
      fiids: '',
    },
    ...options
  });
};

export const usePostDescriptorsReview = () => {
  return usePost({
    url: 'descriptors/review',
    version: 'v2'
  });
};

