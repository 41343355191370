import { descriptorsActionTypes } from './descriptors-actions';
import { Descriptor } from './descriptors-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

interface DescriptorsStore {
  list: Descriptor[];
}

export const descriptorsInitialState = (): DescriptorsStore => ({
  list: [],
});

export const descriptorsReducer = (
  state = descriptorsInitialState(),
  action: Action<DescriptorsStore>,
): DescriptorsStore => {
  switch (action.type) {
    case descriptorsActionTypes.SET_DESCRIPTORS: {
      const { list } = action.payload;

      return {
        ...state,
        list,
      };
    }

    case descriptorsActionTypes.CLEAR_DESCRIPTORS:
    case actionTypes.CLEAR_STATE:
      return descriptorsInitialState();

    default:
      return state;
  }
};
