import React from 'react';
import { IonButton, IonItemDivider } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MerchantSuggestion } from '../../../features/merchant-suggestions/merchant-suggestions-types';

const Item = styled.div`
  --background: transparent;
  --padding-start: 0;
  display: flex;
  align-items: center;
  width: 95%;
  margin: 20px;
  justify-content: space-between;

  @media (max-width: 430px) {
    /* MOBILE */
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 5px;
    margin: 0 0 10px 10px;
  }
`;

const MerchantContainer = styled.div`
  width: 300px;

  @media (max-width: 430px) {
    width: 100%;
  }
`;

const MerchantDescription = styled.p`
  margin: 0px;
`;

const ReviewButton = styled(IonButton)`
  @media (max-width: 430px) {
    margin-top: 15px;
  }
`;

const Divider = styled(IonItemDivider)`
  min-height: 0px;
`;

export interface GroupedMerchantSuggestionItemProps {
  descriptionId: string;
  merchantSuggestions: MerchantSuggestion[];
  isLoading: Boolean;
}

const GroupedMerchantSuggestionItem: React.FC<GroupedMerchantSuggestionItemProps> =
  ({ descriptionId, merchantSuggestions, isLoading }) => {
    const history = useHistory();

    return (
      <>
        <Item data-testid="merchant-suggestion-item">
          <MerchantContainer>
            <MerchantDescription>
              {merchantSuggestions[0].merchantDescription}
            </MerchantDescription>
          </MerchantContainer>
          <ReviewButton
            onClick={() => {
              history.push(`/merchant-suggestions/${descriptionId}`);
            }}
            disabled={Boolean(isLoading)}
          >
            Review suggestions
          </ReviewButton>
        </Item>
        <Divider />
      </>
    );
  };

export default GroupedMerchantSuggestionItem;
