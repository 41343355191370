import React, {ReactNode, useState, useEffect} from 'react';
import {
  IonItem, 
  IonContent,
  IonLabel, 
  IonButton, 
  IonList,
  IonCol, 
  IonIcon,
  IonPage,
  IonTitle,
  IonItemDivider,
  IonSkeletonText,
  IonListHeader
} from '@ionic/react'
import styled from 'styled-components';
import { IonReactRouter } from '@ionic/react-router';
import { Consumer, PanelOptions } from '../../../../features/consumers/consumers-types';
import {format, parseISO} from 'date-fns';
import { arrowBackOutline } from 'ionicons/icons';
import Labels from '../Labels'
import { useGetConsumer } from '../../../../features/consumers/consumers-resolver'
import Header from '../../../../components/header' 
import { useParams } from 'react-router'
// ---------------------------------

const MainLayout = styled.div`
  margin-top: 1rem;
  .details{
    font-size: 1.2rem;
    padding: 1rem;
  }
  .transactions-button{
    border-top: solid;
    border-width: 2px;
    border-color: var(--ion-color-tertiary)
  }
`

interface RouteParams{
  id: string
}

const SkeletonLayout = styled(IonList)`
  padding: 1rem;
  .transactions-button {
    margin: 1rem 0rem;
    padding: .5rem 0rem;
    border-top: solid;
    border-width: 2px;
    border-color: var(--ion-color-tertiary)
  }
`

function Skeleton(){
  return <IonPage>
    <SkeletonLayout>
      <IonListHeader>
        <IonSkeletonText animated style={{width: '20%'}} />
      </IonListHeader>
      <IonSkeletonText animated style={{width: '20%'}} />
      <IonSkeletonText animated style={{width: '25%'}} />
      <IonSkeletonText animated style={{width: '30%'}} />
      <IonSkeletonText animated style={{width: '20%'}} />
      <IonSkeletonText animated style={{width: '10%'}} />
      <div className='transactions-button'>
        <IonSkeletonText animated style={{width: '25%'}} />
      </div>
    </SkeletonLayout>
  </IonPage>
}

export default function ConsumerDetails(){
  const params = useParams<RouteParams>()
  const id = parseInt(params.id, 10)
  const [ 
    {
      isLoading, data: consumer, error
    },
    fetchConsumer
  ] = useGetConsumer(id)
  const route = id ? `/consumer/${id}/transactions` : `/consumers`

  useEffect(()=>{
    fetchConsumer({refresh: true})
  }, [])

  if (isLoading || !consumer){
    return <Skeleton/>
  }

  return <IonPage>
    <Header title={`Consumer Details`} backButton/>
    <IonContent>
      <MainLayout>
        <div className='details'>
          {
            <Labels
              consumer={consumer}
              fields={[
                'consumerId',
                'email',
                'createdOn',
                'firstName',
                'lastName',
                'earnedFancoins',
                'redeemedFancoins',
                'bankAccountId'
              ]}
            />
          }
        </div>
        <div className='transactions-button'>
          <IonItem
            button
            routerLink={route}
            data-testid='go-to-transactions'
          >
            transactions
          </IonItem>
        </div>
      </MainLayout>
    </IonContent>
  </IonPage>
}
