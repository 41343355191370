import { ActionCreator } from '../actions/actions-types';

export const businessPromoCodesActionTypes = {
  SET_BUSINESS_PROMO_CODES: 'setBusinessPromoCodes',
  SET_SELECTED_BUSINESS_PROMO_CODE: 'setSelectedBusinessPromoCode',
  UPDATE_SELECTED_BUSINESS_PROMO_CODE_INFO: 'updateSelectedBusinessPromoCodeInfo',
  CLEAR_BUSINESS_PROMO_CODES: 'clearBusinessPromoCodes',
  SET_BUSINESS_PROMO_CODES_SEARCH_QUERY: 'setBusinessesSearchQuery'
};

export const setBusinessPromoCodes: ActionCreator = (payload) => ({
  type: businessPromoCodesActionTypes.SET_BUSINESS_PROMO_CODES,
  payload: {
    list: payload,
  }
});

export const setSelectedBusinessPromoCode: ActionCreator = (payload) => ({
  type: businessPromoCodesActionTypes.SET_SELECTED_BUSINESS_PROMO_CODE,
  payload: {
    selected: payload,
  }
});

export const clearBusinessPromoCodes: ActionCreator = (payload) => ({
  type: businessPromoCodesActionTypes.CLEAR_BUSINESS_PROMO_CODES,
});

export const setBusinessPromoCodesSearchQuery: ActionCreator = (payload) => ({
  type: businessPromoCodesActionTypes.SET_BUSINESS_PROMO_CODES_SEARCH_QUERY,
  payload: {
    searchQuery: payload,
  }
});

export const updateSelectedBusinessPromoCodeInfo: ActionCreator = (payload) => ({
  type: businessPromoCodesActionTypes.UPDATE_SELECTED_BUSINESS_PROMO_CODE_INFO,
  payload: {
    updatedBusinessPromoCodeInfo: payload,
  }
});
