import { storesActionTypes } from './stores-actions';
import { Store } from './stores-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

type StoresStore = {
  businessId: number;
  data: Store[];
} | undefined;

export const storesInitialState = (): StoresStore => undefined;

export const storesReducer = (
  state = storesInitialState(),
  action: Action<StoresStore>,
): any => {
  switch (action.type) {
    case storesActionTypes.SET_STORES: {
      const { businessId, data } = action.payload || {};

      if (businessId && data) {
        return { businessId, data };
      }

      return state;
    }
  
    case storesActionTypes.UPDATE_STORE: {
      const { store: updatedStore } = action.payload as any;
      
      if (state?.data) {
        return {
          ...state,
          data: state.data.map(s => s.id === updatedStore.id
            ? { ...s, ...updatedStore }
            : s
          ),
        };
      }

      return state;
    }
  
    case storesActionTypes.ADD_STORE: {
      const { store: createdStore } = action.payload as any;
      
      if (state?.data) {
        return {
          ...state,
          data: [...(state.data || []), createdStore],
        };
      }

      return state;
    }

    case storesActionTypes.CLEAR_STORES:
    case actionTypes.CLEAR_STATE:
      return storesInitialState();

    default:
      return state;
  }
};
