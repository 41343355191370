import { ActionCreator } from '../actions/actions-types';

export const campaignSetsActionTypes = {
  SET_CAMPAIGN_SETS: 'setCampaignSets',
  CLEAR_CAMPAIGN_SETS: 'clearCampaignSets',
};

export const setCampaignSets: ActionCreator = (payload) => ({
  type: campaignSetsActionTypes.SET_CAMPAIGN_SETS,
  payload,
});

export const clearCampaignSets: ActionCreator = () => ({
  type: campaignSetsActionTypes.CLEAR_CAMPAIGN_SETS,
});
