import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { useAppState } from '../../../shared/AppContext';
import { useGetFinancialInstitutions } from "../../../features/financial-institutions/financial-institutions-resolver";
import {
  clearFinancialInstitutions,
  setFinancialInstitutionsSearchQuery,
} from "../../../features/financial-institutions/financial-institutions-actions";
import { usePaginationMode } from "../../../utils/hooks";

import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonAlert,
} from "@ionic/react";

import InfiniteScroll from '../../../components/InfiniteScroll';
import PaginationArrows from '../../../components/PaginationArrows';

import { FinancialInstitutionItem } from './components';

import { SearchBar, NoFinancialInstitutionsText, Centered } from './styles';

const FinancialInstitutions: React.FC = () => {
  const history = useHistory();
  const paginationMode = usePaginationMode();
  const {
    dispatch: appStateDispatch,
    state: appState
  } = useAppState();

  const [
    {
      data: { data: financialInstitutions, pagination },
      isLoading: areFinancialInstitutionsLoading,
      error: financialIntitutionalError
    },
    getFinancialInstitutions
  ] = useGetFinancialInstitutions({ paginated: true });

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [isInfiniteScrollDisabled, setIsInfiniteScrollDisabled] = useState(false);

  const [query, setQuery] = useState<string>(history.action === 'POP'
    ? appState.financialInstitutions.searchQuery
    : ''
  );

  const [usePreviousFInstitutions, setUsePreviousFInstitutions] = useState(history.action === 'POP');

  useEffect(() => {
    if(!usePreviousFInstitutions) {
      setIsInfiniteScrollDisabled(false);
      setPage(1);
      appStateDispatch(clearFinancialInstitutions());
      appStateDispatch(setFinancialInstitutionsSearchQuery(query));
      getFinancialInstitutions({
        refresh: true,
        params: {
          page: 1,
          limit,
          query,
        }
      });
    } else {
      setPage(pagination?.page || 1);
      setUsePreviousFInstitutions(false);
      getFinancialInstitutions({
        params: {
          page: pagination?.page || 1,
          limit,
          query,
        }
      });
    }
  }, [query])

  const onChangePage = (newPage: number) => {
    appStateDispatch(clearFinancialInstitutions());
    setPage(newPage);
    getFinancialInstitutions({
      refresh: true,
      params: {
        page: newPage,
        limit,
        query,
      }
    });
  }

  const onChangePageInifite = (event: any) => {
    setPage(page + 1);
    setTimeout(() => {
      getFinancialInstitutions({
        refresh: true,
        params: {
          page: page + 1,
          limit,
          query,
        }
      });

      event.target.complete();
    }, 500);

    if ((financialInstitutions?.length || 0) >= pagination.total) {
      setIsInfiniteScrollDisabled(true);
    }
  }

  const updateSearchQuery = (e: any) => {
    const value = e.detail.value || '';
    setQuery(value.trim());
  }

  const generateFIItems = () => financialInstitutions?.map(fi => (
    <FinancialInstitutionItem
      key={fi.id}
      financialInstitution={fi}
    />
  ))

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton data-testid="menu-button"></IonMenuButton>
          </IonButtons>
          <IonTitle>Financial Institutions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <SearchBar
        data-testid="search-input"
        value={query}
        onIonChange={updateSearchQuery}
        debounce={1000}
      />

      {
        financialInstitutions && (
          <IonContent>
            { generateFIItems() }

            {
              !areFinancialInstitutionsLoading && financialInstitutions?.length === 0 && (
                <NoFinancialInstitutionsText>No Financial Institutions were found.</NoFinancialInstitutionsText>
              )
            }

            {
              paginationMode === 'pagination'
              ? <PaginationArrows
                  changePage={onChangePage}
                  page={page}
                  pagination={pagination}
                />
              : <InfiniteScroll
                  changePage={onChangePageInifite}
                  disabled={isInfiniteScrollDisabled}
                />
            }
          </IonContent>
        )}

        {
          areFinancialInstitutionsLoading && page == 1 && (
            <Centered>
              <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
            </Centered>
          )
        }

        <IonAlert
          isOpen={!!financialIntitutionalError}
          data-testid="req-error-message"
          cssClass="modal-dark-mode"
          header="Network Error"
          message="There was an error processing your request. Please try again later."
          buttons={['OK']}
        />
    </IonPage>
  )
}

export default FinancialInstitutions;
