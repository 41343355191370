import { IonCheckbox } from "@ionic/react";
import React from "react";
import { BusinessEntity } from "../../../../../features/business-entities/business-entities-types";
import { Checkbox, Column, Item, ItemText, TextLabel } from "./styles";

interface BusinessEntityItemProps {
  businessEntity: BusinessEntity;
  isSelected?: boolean;
  onClick?: (id: string) => void;
}

export const BusinessEntityItem: React.FC<BusinessEntityItemProps> = ({
  businessEntity: {
    id,
    merchantName,
    streetAddress,
    city,
    state,
    businessId,
    businessName,
    sameBusinessCount
  },
  isSelected,
  onClick
}) => {  
  return (
    <Item
      button={onClick !== undefined}
      data-testid="business-entity-item"
      onClick={() => onClick && onClick(id)}
      detail={false}
      lines="full"
    >
      {isSelected !== undefined &&
        <Checkbox slot="start" checked={isSelected} />
      }
      <Column>
        <ItemText data-testid="be-item-id">
          <TextLabel>ID:</TextLabel> {id}
        </ItemText>
        <ItemText data-testid="be-item-merchant-name">
          <TextLabel>Merchant Name:</TextLabel> {merchantName || 'N/A'}
        </ItemText>
        <ItemText data-testid="be-item-street-address">
          <TextLabel>Street Address:</TextLabel> {streetAddress || 'N/A'}
        </ItemText>
        <ItemText data-testid="be-item-city">
          <TextLabel>City:</TextLabel> {city || 'N/A'}
        </ItemText>
        <ItemText data-testid="be-item-state">
          <TextLabel>State:</TextLabel> {state || 'N/A'}
        </ItemText>
        <ItemText data-testid="be-item-b-id">
          <TextLabel>Business ID:</TextLabel> {businessId}
        </ItemText>
        <ItemText data-testid="be-item-b-name">
          <TextLabel>Business Name:</TextLabel> {businessName}
        </ItemText>
        <ItemText data-testid="be-item-sb-count">
          <TextLabel># of Entities Associated with the Business:</TextLabel> {sameBusinessCount}
        </ItemText>
      </Column>
    </Item>
  )
};