import { IonContent, IonLabel } from "@ionic/react";
import styled, { css } from "styled-components";

interface ContentContainerProps {
  isDesktopPlatform: boolean;
}

export const ContentContainer = styled(IonContent)<ContentContainerProps>`
  ${props => props.isDesktopPlatform
    ? css`
      --padding-top: 30px;
      --padding-bottom: 30px;
      --padding-start: 70px;
      --padding-end: 70px;  
    `
    : css`
      --padding-top: 20px;
      --padding-bottom: 20px;
      --padding-start: 30px;
      --padding-end: 30px;
    `
  }
`;

export const TabLabel = styled(IonLabel)`
  font-size: 12px;
  margin-bottom: -5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;