import React from 'react';
import { IonButton, IonItemDivider } from '@ionic/react';
import styled from 'styled-components';
import {
  MerchantSuggestion,
  MerchantSuggestionHandler
} from '../../../features/merchant-suggestions/merchant-suggestions-types';

type MerchantDescriptionData = {
  merchantDescription: string;
  address?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  parsed: boolean;
};

const Item = styled.div`
  --background: transparent;
  --padding-start: 0;
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 auto 10px auto;

  @media (max-width: 430px) {
    /* MOBILE */
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 5px;
  }
`;

const MerchantContainer = styled.div`
  width: 300px;

  @media (max-width: 430px) {
    width: 100%;
  }
`;

const MerchantDescription = styled.p`
  margin: 0px;
`;

const StoreContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  margin: 10px 0;

  @media (max-width: 430px) {
    align-items: flex-start;
  }
`;

const Label = styled.p`
  padding: 0px;
  margin: 5px 0;
  font-weight: bold;
`;

const StoreName = styled.p`
  margin-bottom: 0px;
`;

const StoreAddressContainer = styled.div`
  min-width: 300px;
  max-width: 315px;
`;

const StoreAddress = styled.p`
  margin: 0;
  text-align: center;

  @media (max-width: 430px) {
    text-align: start;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const Divider = styled(IonItemDivider)`
  min-height: 0px;
`;

export interface MerchantSuggestionItemProps {
  merchantSuggestion: MerchantSuggestion;
  onSubmitHandler: ({
    status: MerchantSuggestionStatus,
    id: number
  }: MerchantSuggestionHandler) => void;
  isLoading: Boolean;
  updatingSuggestionId?: number;
}

export const generateMerchantDescription = ({
  merchantDescription,
  address,
  state,
  city,
  zipCode,
  parsed
}: MerchantDescriptionData): string => {
  if (parsed) {
    return [
      merchantDescription,
      address || '',
      city || '',
      state || '',
      zipCode || ''
    ].join(' ');
  } else {
    return merchantDescription;
  }
};

export const MerchantSuggestionItem: React.FC<MerchantSuggestionItemProps> = ({
  merchantSuggestion,
  onSubmitHandler,
  isLoading,
  updatingSuggestionId
}) => {
  const {
    id,
    storeName,
    storeAddress,
    storeState,
    storeCity,
    storeZipCode,
    merchantDescriptionId
  } = merchantSuggestion;
  return (
    <>
      <Item data-testid="merchant-suggestion-item">
        <MerchantContainer>
          <MerchantDescription>
            {generateMerchantDescription(merchantSuggestion)}
          </MerchantDescription>
        </MerchantContainer>
        <StoreContent>
          <Label>Suggested:</Label>
          <StoreName>{storeName}</StoreName>
          <StoreAddressContainer>
            <StoreAddress>{`${storeAddress}`}</StoreAddress>
            <StoreAddress>{`${storeCity}, ${storeState} ${storeZipCode}`}</StoreAddress>
          </StoreAddressContainer>
        </StoreContent>
        <ButtonsContainer>
          <IonButton
            onClick={() =>
              onSubmitHandler({ status: 'ACCEPT', id, merchantDescriptionId })
            }
            color="success"
            disabled={isLoading && id === updatingSuggestionId}
          >
            ACCEPT
          </IonButton>
          <IonButton
            onClick={() =>
              onSubmitHandler({ status: 'REJECT', id, merchantDescriptionId })
            }
            color="danger"
            disabled={isLoading && id === updatingSuggestionId}
          >
            REJECT
          </IonButton>
        </ButtonsContainer>
      </Item>
      <Divider />
    </>
  );
};
