import { ActionCreator } from '../actions/actions-types';

export const storeDemographicsActionTypes = {
  SET_STORE_DEMOGRAPHICS: 'setStoreDemographics',
  CLEAR_STORE_DEMOGRAPHICS: 'clearStoreDemographics',
};

export const setStoreDemographics: ActionCreator = (payload) => ({
  type: storeDemographicsActionTypes.SET_STORE_DEMOGRAPHICS,
  payload,
});

export const clearStoreDemographics: ActionCreator = () => ({
  type: storeDemographicsActionTypes.CLEAR_STORE_DEMOGRAPHICS,
});