import { storeDemographicsActionTypes } from './store-demographics-actions';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';
import { StoreDemographics } from './store-demographics-types';

type StoreDemographicsStore = {
  storeId: number;
  data: StoreDemographics;
} | undefined;

export const storeDemographicsInitialState = (): StoreDemographicsStore => undefined;

export const storeDemographicsReducer = (
  state = storeDemographicsInitialState(),
  action: Action<StoreDemographicsStore>,
): any => {
  switch (action.type) {
    case storeDemographicsActionTypes.SET_STORE_DEMOGRAPHICS: {
      return action.payload;
    }

    case storeDemographicsActionTypes.CLEAR_STORE_DEMOGRAPHICS:
    case actionTypes.CLEAR_STATE:
      return storeDemographicsInitialState();

    default:
      return state;
  }
};
