import { useGet } from '../fetch/fetch-resolver';
import {
  setStoreCategories
} from './store-categories-actions';
import {
  Category
} from './store-categories-types';

export const useGetStoreCategories = () => {
  return useGet<Category[]>({
    url: 'stores/categories',
    version: 'v2',
    action: setStoreCategories,
    selector: (state) => state.storeCategories,
  });
};

