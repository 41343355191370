import { ActionCreator } from '../actions/actions-types';

export const storeCategoriesActionTypes = {
  SET_STORE_CATEGORIES: 'setStoreCategories',
  CLEAR_STORE_CATEGORIES: 'clearStoreCategories',
};

export const setStoreCategories: ActionCreator = (payload) => ({
  type: storeCategoriesActionTypes.SET_STORE_CATEGORIES,
  payload,
});

export const clearStoreCategories: ActionCreator = () => ({
  type: storeCategoriesActionTypes.CLEAR_STORE_CATEGORIES,
});
