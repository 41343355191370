import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { useGetFinancialInstitutionsInfo, useGetResolutionsType } from "../../../features/financial-institutions/financial-institutions-resolver";
import { useIsDesktopPlatform } from "../../../utils/hooks";

import { FinancialInstitutionInfo, EditFinancialInstitutionModal } from './components';
import {
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonBackButton,
  IonContent,
  IonSpinner,
  IonAlert,
} from "@ionic/react";

import { BackButton, BackButtonContainer, Centered } from './styles';

const FinancialInstitutionsDetails: React.FC = () => {
  const { id } = useParams() as any;

  const isDesktopPlatform = useIsDesktopPlatform();

  const [{
    isLoading: isFIInfoLoading,
    error: FIInfoError,
    data: FIInfo,
  }, getFinancialInstitutionInfo ] = useGetFinancialInstitutionsInfo({ id: Number(id) })

  const [{
    isLoading: isResolutionTypesLoading,
    error: resolutionTypesError,
    data: resolutionTypes,
  }, getResolutionTypes ] = useGetResolutionsType()

  const [showEditFinancialInstitutionModal, setShowEditFinancialInstitutionModal] = useState(false);

  useEffect(() => {
    if(id) {
      getFinancialInstitutionInfo();
      getResolutionTypes();
    }
  }, [id])

  const financialLoaded = FIInfo && !isFIInfoLoading;

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              {isDesktopPlatform
                ? <IonMenuButton data-testid="menu-button"></IonMenuButton>
                : <IonBackButton defaultHref={'/financial-institutions'}></IonBackButton>
              }
            </IonButtons>
            <IonTitle>Financial Institutions</IonTitle>
          </IonToolbar>
        </IonHeader>

        {isDesktopPlatform &&
          <BackButtonContainer data-testid="back-button">
            <BackButton text="Back to all Financial Institutions" defaultHref={'/financial-institutions'} />
          </BackButtonContainer>
        }

        {(isFIInfoLoading) && (
          <Centered>
            <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
          </Centered>
        )}

        <EditFinancialInstitutionModal
          id={Number(id)}
          financialInstitutionInfo={FIInfo}
          resolutionTypes={resolutionTypes}
          isOpen={showEditFinancialInstitutionModal}
          onClose={() => setShowEditFinancialInstitutionModal(false)}
        />

        {financialLoaded && (
          <IonContent>
            <FinancialInstitutionInfo
              fiinfo={FIInfo}
              onOpenEditModal={() => setShowEditFinancialInstitutionModal(true)}
              isDesktopPlatform={isDesktopPlatform}
            />
          </IonContent>
        )}

      <IonAlert
        isOpen={!!FIInfoError || !!resolutionTypesError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />
      </IonPage>
    </>
  )
}

export default FinancialInstitutionsDetails;
