import { ActionCreator } from '../actions/actions-types';

export const storeLocalizationActionTypes = {
  SET_STORE_LOCALIZATION: 'setStoreLocalization',
  CLEAR_STORE_LOCALIZATION: 'clearStoreLocalization',
};

export const setStoreLocalization: ActionCreator = (payload) => ({
  type: storeLocalizationActionTypes.SET_STORE_LOCALIZATION,
  payload,
});

export const clearStoreLocalization: ActionCreator = () => ({
  type: storeLocalizationActionTypes.CLEAR_STORE_LOCALIZATION,
});