import styled from 'styled-components'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonPage,
  IonMenuButton
} from '@ionic/react';
import {
  person, 
  chevronBackOutline
} from 'ionicons/icons'


type HeaderProps = {
  title: string
  backButton?: boolean
}

export default function Header(props: HeaderProps){ 
  const {title, backButton} = props

  return <>
  <IonHeader>
    <IonToolbar>
      {
        backButton ? 
        <IonButtons slot='start'>
          <IonBackButton />
        </IonButtons>
        : <IonButtons slot='start'>
          <IonMenuButton />
        </IonButtons>
      }
      <IonTitle>{title}</IonTitle>
    </IonToolbar>
  </IonHeader>
</>
}

Header.defaultProps = {
  backButton: false
}
