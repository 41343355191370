import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  MerchantSuggestionHandler,
  MerchantSuggestionStatus
} from '../../features/merchant-suggestions/merchant-suggestions-types';
import {
  useMerchantSuggestionsByDescriptionId,
  usePatchMerchantSuggestions
} from '../../features/merchant-suggestions/merchant-suggestions-resolver';
import { MerchantSuggestionItem } from './components/MerchantSuggestionItem';
import { useAuth } from '../../features/auth/auth-resolver';
import { useAppState } from '../../shared/AppContext';
import { FetchError } from '../../features/fetch/fetch-types';
import {
  removeGroupedMerchantSuggestions,
  removeIndividualMerchantSuggestion
} from '../../features/merchant-suggestions/merchant-suggestions-actions';
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';

const RejectAllButton = styled(IonButton)`
  align-self: flex-end;
  margin-right: 20px;

  @media (max-width: 430px) {
    /* MOBILE */
    align-self: flex-start;
    margin-left: 15px;
  }
`;

const GroupedMerchantSuggestions: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { dispatch } = useAppState();
  const { descriptionId } = useParams() as any;

  const suggestionsByDescriptionId =
    useMerchantSuggestionsByDescriptionId(descriptionId);

  const [
    { isLoading: isLoadingPatch, error: patchError },
    patchMerchantSuggestions
  ] = usePatchMerchantSuggestions();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [submitData, setSubmitData] = useState({
    status: '' as MerchantSuggestionStatus,
    id: 0 as number | number[],
    merchantDescriptionId: ''
  });

  const onSubmit = async () => {
    const { id, status } = submitData;

    const patchRes = await patchMerchantSuggestions({
      id,
      status,
      adminId: auth.adminId
    });

    if (patchRes && !(patchRes as FetchError).errorCode) {
      // if rejecting a single item, just remove it from list, don't route back
      if (status === 'REJECT' && !Array.isArray(id)) {
        dispatch(
          removeIndividualMerchantSuggestion({
            id: submitData.id,
            descriptionId
          })
        );
      }

      // if rejecting all OR accepting, remove the whole group and route back
      if (Array.isArray(id) || status === 'ACCEPT') {
        dispatch(removeGroupedMerchantSuggestions(descriptionId));
        history.push('/merchant-suggestions');
      }

      setConfirmModalOpen(false);
    }
  };

  const generateSuggestionsList = () => {
    const nodes: React.ReactNode[] = [];

    suggestionsByDescriptionId.forEach((suggestion) => {
      nodes.push(
        <MerchantSuggestionItem
          key={`grouped-item-${suggestion.id}`}
          merchantSuggestion={suggestion}
          onSubmitHandler={(data: MerchantSuggestionHandler) => {
            setSubmitData(data);
            setConfirmModalOpen(true);
          }}
          isLoading={isLoadingPatch}
        />
      );
    });

    return nodes;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton data-testid="menu-button"></IonMenuButton>
          </IonButtons>
          <IonTitle>Merchant Suggestions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonButtons slot="start" data-testid="back-button">
        <IonBackButton defaultHref={'/merchant-suggestions'} />
        Back to all suggestions
      </IonButtons>

      <RejectAllButton
        onClick={() => {
          setSubmitData({
            status: 'REJECT',
            id: suggestionsByDescriptionId.map((suggestion) => suggestion.id),
            merchantDescriptionId: descriptionId
          });
          setConfirmModalOpen(true);
        }}
        color="danger"
        disabled={isLoadingPatch}
      >
        REJECT ALL
      </RejectAllButton>

      {suggestionsByDescriptionId && (
        <IonContent>{generateSuggestionsList()}</IonContent>
      )}

      <IonAlert
        isOpen={!!patchError}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonAlert
        isOpen={confirmModalOpen}
        cssClass="modal-dark-mode"
        header="Are you sure?"
        message="Approving this suggestion rejects all other ones in the list."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-cancel-button',
            handler: () => {
              setConfirmModalOpen(false);
            }
          },
          {
            text: 'Confirm',
            handler: onSubmit
          }
        ]}
      />
    </IonPage>
  );
};

export default GroupedMerchantSuggestions;
