import { campaignSetsActionTypes } from './campaign-sets-actions';
import { CampaignSet } from './campaign-sets-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

type CampaignSetsStore = CampaignSet[];

export const CampaignSetsInitialState = (): CampaignSetsStore => [];

export const campaignSetsReducer = (
  state = CampaignSetsInitialState(),
  action: Action<CampaignSetsStore>,
): any => {
  switch (action.type) {
    case campaignSetsActionTypes.SET_CAMPAIGN_SETS: {
      return action.payload;
    }

    case campaignSetsActionTypes.CLEAR_CAMPAIGN_SETS:
    case actionTypes.CLEAR_STATE:
      return CampaignSetsInitialState();

    default:
      return state;
  }
};
