import { formInputs } from "./form-inputs";
import { FormValues } from "./types";

export const defaultFormData = formInputs.reduce((formData, item) => {
  return item.group
    ? {
      ...formData,
      [item.group]: {
        ...formData[item.group],
        [item.name]: item.defaultValue
      }
    }
    : {
      ...formData,
      [item.name]: item.defaultValue
    };
}, {} as FormValues);