import { IonIcon, IonNote, useIonToast } from "@ionic/react";
import { copy } from "ionicons/icons";
import { useIsDesktopPlatform } from "../../../../../utils/hooks";
import { CopyButton, CopyItemContainer, Label } from "./styles";

export interface CopyItemProps {
  name: string;
  value: string;
  textToClipboard: string;
}

const CopyItem: React.FC<CopyItemProps> = ({
  name,
  value,
  textToClipboard,
}) => {
  const isDesktopPlatform = useIsDesktopPlatform();

  const [presentToast] = useIonToast();
  
  const handleClick = async () => {
    await navigator.clipboard.writeText(textToClipboard);
    presentToast({
      color: 'success',
      message: 'Text copied!',
      position: 'top',
      duration: 1500
    });
  };
  
  return <CopyItemContainer>
    <IonNote style={!isDesktopPlatform ? { fontSize: 12 } : undefined}>
      {name}
    </IonNote>
    {value.length > 0 && 
      <Label>
        {value}
      </Label>
    }
    <CopyButton onClick={handleClick}>
      {textToClipboard}
      <IonIcon slot="end" icon={copy} />
    </CopyButton>
  </CopyItemContainer>;
}

export default CopyItem;