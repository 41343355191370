import i18next, { TFunction } from 'i18next';
import _ from 'lodash';
import { Store } from '../features/stores/stores-types';
import { BaseMap } from '../types/generic';

const init = async (translation: BaseMap<string>) => {
  const client = await i18next.init({
    lng: 'en',
    resources: {
      en: { translation },
    },
  });
  return client;
};

const interpolateDeep = (
  translator: TFunction,
  values: BaseMap | string[] | string,
  data: BaseMap,
  translated: any = {},
  variable: string = ''
): BaseMap => {
  //traverse the hierarchy of a localization object and translate every parent/child variable combo
  if (_.isPlainObject(values)) {
    for (const [key, value] of Object.entries(values)) {
      if (_.isObject(value)) {
        translated[key] = {};
      }

      translated[key] = interpolateDeep(
        translator,
        value,
        data,
        translated[key],
        variable ? `${variable}.${key}` : key
      );
    }

    return translated;
  } else if (_.isArray(values)) {
    return values.map((item, i) =>
      interpolateDeep(
        translator,
        item,
        data,
        null,
        variable ? `${variable}.${i}` : String(i)
      )
    );
  } else {
    return translator(variable, data);
  }
};

export const interpolate = async (
  values: BaseMap,
  data: BaseMap<string>
): Promise<BaseMap<string>> => {
  const translator = await init(values);
  return interpolateDeep(translator, values, data);
};

export const localize = async (
  values: BaseMap,
  store: Store
) => {
  let neighborhoodPossessive: string = '';

  if (store.neighborhood && store.neighborhood.endsWith('s')) {
    neighborhoodPossessive = `${store.neighborhood}'`;
  } else if (store.neighborhood) {
    neighborhoodPossessive = `${store.neighborhood}'s`;
  }

  const data: BaseMap = {
    name: store.name,
    streetAddress: store.streetAddress,
    city: store.city,
    state: store.state,
    zipCode: store.zipCode,
    neighborhood: store.neighborhood || '',
    neighborhoodPossessive,
    phone: store.phone || '',
    facebookUrl: store.facebookUrl || '',
    websiteUrl: store.websiteUrl || '',
  };

  return interpolate(values, data);
};
