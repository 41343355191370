import { BusinessPromoCodeWithDetails } from "../../../../../features/business-promo-codes/business-promo-codes-types";
import { formatDatetime } from "../../../../../utils/format";
import { TextLabel, Column, ColumnTitle, Row, Text, SectionHeader } from "./styles";

interface PromoCodeInfoProps {
  promoCodeInfo: BusinessPromoCodeWithDetails;
}

const PromoCodeInfo: React.FC<PromoCodeInfoProps> = ({
  promoCodeInfo
}) => {
  const campaignSetInfo = promoCodeInfo.campaignSet
    ? `${promoCodeInfo.campaignSet.name} (ID: ${promoCodeInfo.campaignSet.id})`
    : 'None';

  return (
    <Row>
      <Column>
        <SectionHeader>
          <ColumnTitle>Promo Code Info</ColumnTitle>
        </SectionHeader>
        <Text data-testid="promo-code-id">
          <TextLabel>ID</TextLabel>: {promoCodeInfo.id}
        </Text>
        <Text data-testid="promo-code-name">
          <TextLabel>Name</TextLabel>: {promoCodeInfo.name}
        </Text>
        <Text data-testid="promo-code-type">
          <TextLabel>Type</TextLabel>: {promoCodeInfo.type}
        </Text>
        <Text data-testid="promo-code-value">
          <TextLabel>Value</TextLabel>: ${promoCodeInfo.value}
        </Text>
        <Text data-testid="promo-code-campaign-set">
          <TextLabel>Campaign Set</TextLabel>: {campaignSetInfo}
        </Text>
        <Text data-testid="promo-code-created-at">
          <TextLabel>Created At</TextLabel>: {formatDatetime(promoCodeInfo.createdAt)}
        </Text>
        <Text data-testid="promo-code-updated-at">
          <TextLabel>Updated At</TextLabel>: {formatDatetime(promoCodeInfo.updatedAt)}
        </Text>
      </Column>
    </Row>
  );
}

export default PromoCodeInfo;