import { appsActionTypes } from './apps-actions';
import { App } from './apps-types';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

type AppsStore = App[];

export const appsInitialState = (): AppsStore => [];

export const appsReducer = (
  state = appsInitialState(),
  action: Action<AppsStore>,
): any => {
  switch (action.type) {
    case appsActionTypes.SET_APPS: {
      return action.payload;
    }

    case appsActionTypes.CLEAR_APPS:
    case actionTypes.CLEAR_STATE:
      return appsInitialState();

    default:
      return state;
  }
};
