import {
  useState, 
  useEffect,
  ReactNode,
} from 'react'
import {
  IonLabel,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonPage,
  getPlatforms,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/react';
import styled from 'styled-components';
import PaginationArrows from './PaginationArrows'
import InfiniteScroll from './InfiniteScroll'

// --------------------------------

const VerticalLayout = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  .content-body{
    flex-grow: 1;
    min-height: 80vh
  }
`

type PaginationLayoutProps = {
  header: ReactNode | undefined
  children?: ReactNode
  page: number
  changePagePagination: (newPage: number) => void
  changePageInfinite: (event: any) => void
  isInfiniteScrollDisabled: boolean
  pagination: {
    total: number
    limit: number
    last: number
    page: number
  }
}

export default function PaginatedPage(props:PaginationLayoutProps){
  const {
    changePagePagination,
    changePageInfinite,
    page,
    pagination,
    header,
    isInfiniteScrollDisabled
  } = props
  const [queryMethod, setQueryMethod] = (
    useState<'infinite' | 'pagination'>('infinite')
  )

  useEffect(()=>{
    const platforms = getPlatforms() 
    if(platforms.length === 1 && platforms[0] === 'desktop'){
      setQueryMethod('pagination')
    }
  }, [])
  
  
  return (
    <IonPage>
      {header}
      <IonContent>
          {props.children}
          {
            queryMethod === 'pagination' ?
            <PaginationArrows
              changePage={changePagePagination}
              page={page}
              pagination={pagination}
            /> :
            <InfiniteScroll 
              changePage={changePageInfinite}
              disabled={isInfiniteScrollDisabled}
            />
          }
      </IonContent>
    </IonPage>
  )
}
