import React from 'react';
import { isEmpty } from 'lodash';
import { Redirect, Route } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import { useAuth } from '../features/auth/auth-resolver';
import { AuthDependentRouteProps } from './types';

export const AuthDependentRoute: React.FC<AuthDependentRouteProps> = (
  props: AuthDependentRouteProps
) => {
  const auth = useAuth();
  const expiresAt = DateTime.fromISO(auth?.created).plus(
    Duration.fromObject({ seconds: auth?.ttl })
  );

  const { shouldBeLoggedIn, ...rest } = props;
  const isActive = expiresAt > DateTime.local();

  if (shouldBeLoggedIn && !isActive) {
    return isEmpty(auth) ? <Redirect to="/login" /> : <Redirect to="/logout" />;
  }

  if (!shouldBeLoggedIn && isActive) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
};

export default AuthDependentRoute;
