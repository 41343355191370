import React from "react";
import { Business } from "../../../../../features/businesses/businesses-types";
import { Column, Item, ItemText } from "./styles";
import { format, parseISO } from "date-fns";

interface BusinessItemProps {
  business: Business;
  isLoading: boolean;
}

export const BusinessItem: React.FC<BusinessItemProps> = ({
  business: {
    id,
    businessName,
    contactEmail,
    contactFirstName,
    contactLastName,
    createdAt,
  },
  isLoading,
}) => { 
  const fullNameResult = contactLastName && contactFirstName
    ? `${contactFirstName} ${contactLastName}`
    : contactLastName || contactFirstName || 'N/A';

  const formattedRegistrationDate = format(parseISO(createdAt), 'MM/dd/yyyy');
  
  return (
    <>
      <Item
        data-testid="business-item"
        button
        routerLink={`/businesses/${id}`}
      >
        <Column>
          <ItemText data-testid="business-item-id">
            {id}
          </ItemText>
          <ItemText data-testid="business-item-business-name">
            {businessName}
          </ItemText>
          <ItemText data-testid="business-item-contact-full-name">
            {fullNameResult}
          </ItemText>
          <ItemText data-testid="business-item-contact-email">
            {contactEmail}
          </ItemText>
          <ItemText data-testid="business-item-registration-date">
            {formattedRegistrationDate}
          </ItemText>
        </Column>
      </Item>
    </>
  )
};