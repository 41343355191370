import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useEffect } from 'react';
import { EditableBusinessInfo } from '../../../../../features/businesses/businesses-types';

const SCROLL_DURATION = 800;

export interface FormValues {
  collinsonId: string;
  slug: string;
  businessName: string;
  corporateName: string;
  primaryEmail: string;
  contactEmail: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  businessWideOffers: boolean;
}

const businessEditSchema = yup.object().shape({
  collinsonId: yup
    .string()
    .typeError('Invalid Collinson ID'),
  slug: yup
    .string()
    .typeError('Invalid slug'),
  businessName: yup
    .string()
    .typeError('Invalid Business Name')
    .required('Business Name is required'),
  corporateName: yup
    .string()
    .typeError('Invalid Corporate Name'),
  primaryEmail: yup
    .string()
    .typeError('Invalid Primary Email')
    .required('Primary Email is required'),
  contactEmail: yup
    .string()
    .typeError('Invalid Contact Email'),
  contactFirstName: yup
    .string()
    .typeError('Invalid Contact First Name')
    .required('First Name is required'),
  contactLastName: yup
    .string()
    .typeError('Invalid Contact Last Name')
    .required('Last Name is required'),
  contactPhone: yup
    .string()
    .typeError('Invalid Contact Phone')
    .required('Contact Phone is required'),
  businessWideOffers: yup.boolean()
});

export const useBusinessEditForm = (
  setFormErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  modalContentRef: React.RefObject<HTMLIonContentElement>,
  businessCurrentInfo: EditableBusinessInfo | undefined,
) => {
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(businessEditSchema),
    reValidateMode: 'onSubmit',
  });
  
  useEffect(() => {
    const errors = Object.keys(formErrors);
    if (errors.length > 0) {
      const modalContentElement = modalContentRef.current;
      if (modalContentElement) {
        modalContentElement.scrollToTop(SCROLL_DURATION);
      }
      setFormErrorMessage('Invalid data. Please verify all fields.');
    } else {
      setFormErrorMessage('');
    }
  }, [formErrors]);


  useEffect(() => {
    if (businessCurrentInfo) {
      let businessWideOffers = true
      if (businessCurrentInfo?.businessWideOffers === false){
        // Only change it offer type if is set explicitely to
        // false, null/undefined value means the entry 
        // doesn't exist yet and we should default 
        // it to true:
        businessWideOffers = false
      }

      reset({
        collinsonId: businessCurrentInfo?.collinsonId || '',
        slug: businessCurrentInfo?.slug || '',
        businessName: businessCurrentInfo?.businessName || '',
        corporateName: businessCurrentInfo?.corporateName || '',
        primaryEmail: businessCurrentInfo?.primaryEmail || '',
        contactEmail: businessCurrentInfo?.contactEmail || '',
        contactFirstName: businessCurrentInfo?.contactFirstName || '',
        contactLastName: businessCurrentInfo?.contactLastName || '',
        contactPhone: businessCurrentInfo?.contactPhone || '',
        businessWideOffers: businessWideOffers
      });
    }
  }, [businessCurrentInfo]);
  
  return {
    control,
    handleSubmit,
    reset,
  };
};
