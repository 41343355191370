import { IonButton, IonItem, IonThumbnail } from "@ionic/react";
import styled from "styled-components";

export const Row = styled.div`
--background: transparent;
--padding-start: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 95%;
  margin: 0px auto 10px auto;
  padding: 10px 5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
`;

export const Text = styled.p`
  padding: 0px;
  margin: 5px 0;
  overflow-wrap: break-word;
`;

export const HeaderText = styled(Text)`
  font-size: 22px;
`;

export const ColumnTitle = styled.p`
  font-size: 32px;
  margin: 0;
  margin-bottom: 5px;
`;

export const TextLabel = styled.span`
  font-weight: bold;
`;

export const StoreItem = styled(IonItem)`
  --background: transparent;
  --padding-start: 0;
  --inner-border-width: 0;

  margin-bottom: 25px;

  ::part(native) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LogoContainer = styled(IonThumbnail)`
  width: 120px;
  height: 120px;
  --border-radius: 5px;
`;

export const StoreButton = styled(IonButton).attrs({
  color: 'light',
})<{ isDesktopPlatform: boolean }>`
  --padding-start: 10px;
  --padding-end: 10px;
  margin: 5px 10px 5px 0;
  height: ${props => props.isDesktopPlatform ? '45px' : '35px'};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;