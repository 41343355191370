import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useEffect } from 'react';
import { EditableBusinessInfo } from '../../../../../features/business-entities/business-entities-types';

const SCROLL_DURATION = 800;

export type FormValues = {
  expectedLocationsCount?: string;
};

const businessEditSchema = yup.object().shape({
  expectedLocationsCount: yup
    .number()
    .typeError('Invalid expected locations count')
    .required('Insert the expected locations count'),
});

export const useBusinessEditForm = (
  setFormErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  modalContentRef: React.RefObject<HTMLIonContentElement>,
  businessCurrentInfo: EditableBusinessInfo | undefined,
) => {
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(businessEditSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      expectedLocationsCount: '0',
    }
  });

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      const modalContentElement = modalContentRef.current;
      if (modalContentElement) {
        modalContentElement.scrollToTop(SCROLL_DURATION);
      }

      const errorMessage = Object.values(formErrors).map((error) => error.message).join('. ');
      setFormErrorMessage(errorMessage.toString());
    } else {
      setFormErrorMessage('');
    }
  }, [formErrors]);

  useEffect(() => {
    if (businessCurrentInfo) {
      reset({
        expectedLocationsCount: businessCurrentInfo?.expectedLocationsCount?.toFixed(0) || '0',
      });
    }
  }, [businessCurrentInfo]);
  
  return {
    control,
    handleSubmit,
    reset,
  };
};