import { IonItem, IonThumbnail } from "@ionic/react";
import styled from "styled-components";

export const SelectedBrandItemContainer = styled(IonItem)`
  --background: transparent;
  --padding-start: 16px;
  --inner-border-width: 0;
  --padding-top: 5px;
  --padding-bottom: 5px;
`;

export const Thumbnail = styled(IonThumbnail)`
  margin-right: 20px;
  --border-radius: 5px;
`;