import { IonSearchbar } from "@ionic/react";
import styled from "styled-components";

export const SearchBar = styled(IonSearchbar)`
  margin-bottom: auto;
`;

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const NoFinancialInstitutionsText = styled.p`
  padding: 0;
  margin: 20px 10px 10px 25px;
  margin-left: 25px;
  overflow-wrap: break-word;
  font-size: 20px;
`
