import { IonCheckbox, IonInput, IonItem, IonLabel, IonModal, IonSelect, IonTextarea } from "@ionic/react";
import styled from "styled-components";

export const Modal = styled(IonModal)`
  --width: 80vw;
  --height: 80vh;

  @media (max-width: 765px) {
    --width: 100vw;
    --height: 100vh;
  }
`;

export const ModalContent = styled.form`
  padding: 30px 100px;
  overflow: scroll;

  @media (max-width: 1100px) {
    padding: 20px 50px;
  }

  @media (max-width: 500px) {
    padding: 20px 30px;
  }
`;

export const FormItem = styled.div`
  display: flex;
  margin-bottom: 15px;

  @media (max-width: 715px) {
    flex-direction: column;
  }
`;

export const FormLabel = styled.label`
  color: var(--ion-color-medium-shade);
  font-weight: bold;
  width: 260px;

  @media (max-width: 715px) {
    margin-bottom: 10px;
  }
`;

interface InputProps {
  error?: boolean;
}

export const FormInput = styled(IonInput)<InputProps>`
  background: var(--ion-color-light-shade);
  border: 1px solid var(${props => props.error
    ? '--ion-color-danger'
    : '--ion-color-medium-shade'
  });
  border-radius: 3px;
`;

export const FormTextArea = styled(IonTextarea).attrs({
  rows: 3
})<InputProps>`
  background: var(--ion-color-light-shade);
  border: 1px solid var(${props => props.error
    ? '--ion-color-danger'
    : '--ion-color-medium-shade'
  });
  border-radius: 3px;
  margin-bottom: 15px;
  margin-top: 0;
`;

export const CheckboxItem = styled(IonItem).attrs({
  lines: "none"
})`
  --background: transparent;
  --padding-start: 0;
  --inner-padding-end: 0;

  margin-right: 20px;
`;

export const Checkbox = styled(IonCheckbox)`
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: auto;
`;

export const CheckboxLabel = styled(IonLabel)`
  margin-top: 0;
  margin-bottom: auto;
`;

export const Select = styled(IonSelect)<InputProps>`
  background: var(--ion-color-light-shade);
  border: 1px solid var(${props => props.error
    ? '--ion-color-danger'
    : '--ion-color-medium-shade'
  });
  border-radius: 3px;
  width: 200px;

  &:not(:last-child) {
    margin-right: 20px;

    @media (max-width: 715px) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 825px) {
    width: 150px;
  }

  @media (max-width: 715px) {
    width: 100%;
  }
`;