import React, { useEffect, useState } from 'react';
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { SearchBar, SubmitButton, Text, Centered, BackButtonContainer, BackButton } from './styles';
import { useAppState } from '../../../shared/AppContext';
import { useIsDesktopPlatform, usePaginationMode } from '../../../utils/hooks';
import { useGetBusinessEntities, useGetBusinessEntityInfo } from '../../../features/business-entities/business-entities-resolver';
import { clearBusinessEntities } from '../../../features/business-entities/business-entities-actions';
import { BusinessEntityItem } from './components/BusinessEntityItem';
import PaginationArrows from '../../../components/PaginationArrows';
import InfiniteScroll from '../../../components/InfiniteScroll';
import MergeConfirmationModal from './components/MergeConfirmationModal';
import { BusinessEntity } from '../../../features/business-entities/business-entities-types';
import { useParams } from 'react-router-dom';

const MergeBusinesses = () => {
  const { id } = useParams() as any;

  const isDesktopPlatform = useIsDesktopPlatform();
  
  const {
    dispatch: appStateDispatch,
  } = useAppState();

  const [{
    error: businessEntityInfoError,
    data: businessEntityInfo,
  }, getBusinessEntityInfo] = useGetBusinessEntityInfo({ id });
  
  const [
    {
      data: { data: businessEntities, pagination },
      isLoading: areBusinessEntitiesLoading,
      error: businessesEntitiesError
    },
    getBusinessEntities
  ] = useGetBusinessEntities({ paginated: true });

  const paginationMode = usePaginationMode();

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [query, setQuery] = useState('');
  const [isInfiniteScrollDisabled, setIsInfiniteScrollDisabled] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [selectedBusinessEntities, setSelectedBusinessEntities] = useState<BusinessEntity[]>([]);

  useEffect(() => {
    if (id) {
      getBusinessEntityInfo();
    }
  }, [id]);

  useEffect(() => {
    appStateDispatch(clearBusinessEntities());
    const defaultPage = pagination?.page || 1;
    setPage(defaultPage);
    getBusinessEntities({
      refresh: true,
      params: {
        page: defaultPage,
        limit,
        query,
        orderAlphabetically: true,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const changePage = (newPage: number) => {
    appStateDispatch(clearBusinessEntities());
    setPage(newPage);
    getBusinessEntities({
      refresh: true,
      params: {
        page: newPage,
        limit,
        query,
        orderAlphabetically: true,
      }
    });
  }

  const changePageInfinite = (event: any) =>{
    setPage(page + 1);
    setTimeout(() => {
      getBusinessEntities({
        refresh: true,
        params: {
          page: page + 1,
          limit,
          query,
          orderAlphabetically: true,
        }
      });
      event.target.complete();
    }, 500);

    if ((businessEntities?.length || 0) >= pagination?.total) {
      setIsInfiniteScrollDisabled(true);
    }
  }

  const handleBusinessEntityClick = (id: string) => {
    const isSelected = selectedBusinessEntities.find(be => be.id === id);
    if (isSelected) {
      setSelectedBusinessEntities(sbe => sbe.filter(be => be.id !== id));
    } else {
      setSelectedBusinessEntities(sbe => [...sbe, businessEntities?.find(be => be.id === id) as BusinessEntity]);
    }
  };

  const generateBusinessEntitiesList = () => businessEntities
    ?.filter(businessEntity => businessEntity.businessId !== businessEntityInfo?.businessId)
    .map(businessEntity => (
      <BusinessEntityItem
        key={businessEntity.id}
        businessEntity={businessEntity}
        isSelected={!!selectedBusinessEntities.find(be => be.id === businessEntity.id)}
        onClick={handleBusinessEntityClick}
      />
    ));

  const updateSearchQuery = (e: any) => {
    const value = e.detail.value || '';
    setQuery(value.trim());
  };
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {isDesktopPlatform
              ? <IonMenuButton data-testid="menu-button"></IonMenuButton>
              : <IonBackButton defaultHref={`/business-entities/${id}/business`} />
            }
          </IonButtons>
          <IonTitle>Connect other Entities</IonTitle>
        </IonToolbar>
      </IonHeader>

      {isDesktopPlatform &&
        <BackButtonContainer data-testid="back-button">
          <BackButton text="Back" defaultHref={`/business-entities/${id}/business`} />
        </BackButtonContainer>
      }

      <IonContent>
        <SearchBar
          data-testid="search-input"
          value={query}
          onIonChange={updateSearchQuery}
          debounce={1000}
        />

        {generateBusinessEntitiesList()}

        {!areBusinessEntitiesLoading && (businessEntities?.length || 0) > 0 && (
          <Centered>
            {
              pagination && paginationMode === 'pagination'
                ? <PaginationArrows 
                  changePage={changePage}
                  page={page} 
                  pagination={pagination}
                />
                : <InfiniteScroll 
                  changePage={changePageInfinite}
                  disabled={isInfiniteScrollDisabled}
                />
            }
          </Centered>
        )}

        {
          areBusinessEntitiesLoading && page === 1 && (
            <Centered>
              <IonSpinner data-testid="page-spinner-be-list" title="page-spinner" name="crescent" />
            </Centered>
          )
        }

        {
          !areBusinessEntitiesLoading && businessEntities?.length === 0 && (
            <Text style={{ marginBottom: 'auto' }}>No entities were found</Text>
          )
        }
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <Centered>
            <SubmitButton
              disabled={selectedBusinessEntities.length === 0}
              onClick={() => setShowConfirmationModal(true)}
            >
              Merge Businesses
            </SubmitButton>
          </Centered>
        </IonToolbar>
      </IonFooter>

      <IonAlert
        isOpen={!!businessesEntitiesError || !!businessEntityInfoError}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <MergeConfirmationModal
        sourceBusinessEntityId={id}
        targetBusinessId={businessEntityInfo?.businessId}
        businessEntities={selectedBusinessEntities}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      />
    </IonPage>
  );
};

export default MergeBusinesses;
