import React, { useEffect, useRef, useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast
} from '@ionic/react';
import { Modal } from './styles';
import { useAppState } from '../../../../../shared/AppContext';
import {
  useGetStoreDemographics,
  usePatchStoreDemographics
} from '../../../../../features/store-demographics/store-demographics-resolver';
import { SubmitHandler } from 'react-hook-form';
import { FormValues, useStoreDemographicsForm } from './form-validation';
import {
  setStoreDemographics
} from '../../../../../features/store-demographics/store-demographics-actions';
import Form from './components/Form';
import { Store } from '../../../../../features/stores/stores-types';

interface StoreDemographicsModalProps {
  store: Store | undefined;
  onClose: () => void;
}

const StoreDemographicsModal: React.FC<StoreDemographicsModalProps> = ({
  store,
  onClose
}) => {
  const { dispatch: appStateDispatch } = useAppState();
  
  const [presentToast] = useIonToast();

  const [{
    data: storeDemographics,
    error: getStoreDemographicsError,
    isLoading: areStoreDemographicsLoading
  }, getAllBrands] = useGetStoreDemographics(Number(store?.id));

  const [{ isLoading: isPatchStoreDemographicsLoading }, patchStoreDemographics] =
    usePatchStoreDemographics(Number(store?.id));

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const modalContentRef = useRef<HTMLIonContentElement>(null);

  const {
    control,
    handleSubmit,
  } = useStoreDemographicsForm(setFormErrorMessage, modalContentRef, storeDemographics);

  useEffect(() => {
    if (store) {
      getAllBrands();
    }
  }, [store]);

  const handleClose = () => {
    onClose();
  }

  const handleSaveStoreDemographics: SubmitHandler<FormValues> = async (formValues) => {
    try {
      const response = await patchStoreDemographics({
        demographics: formValues
      });
        
      if (response.errorCode) {
        throw new Error(response.message);
      }
      
      presentToast({
        color: 'success',
        message: `Store demographics successfully updated`,
        position: 'top',
        duration: 3000
      });

      appStateDispatch(setStoreDemographics({
        id: store?.id,
        data: formValues
      }));
    } catch (err) {
      setFormErrorMessage('Could not update store demographics. Try again later');
    }
  };
  
  const isModalLoading = areStoreDemographicsLoading || isPatchStoreDemographicsLoading;
  
  return (
    <Modal isOpen={!!store} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{`${store?.name} Demographics`}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Form
          control={control}
          isLoading={isModalLoading}
          onSubmit={(e) => handleSubmit(handleSaveStoreDemographics)(e)}
        />
      </IonContent>

      <IonToast
        isOpen={formErrorMessage.length > 0}
        color="danger"
        onDidDismiss={() => setFormErrorMessage('')}
        message={formErrorMessage}
        position="top"
        buttons={['OK']}
      />

      <IonAlert
        isOpen={!!getStoreDemographicsError}
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />

      <IonLoading
        isOpen={isModalLoading}
      />
    </Modal>
  );
};

export default StoreDemographicsModal;
