import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { StoreLocalization } from '../../../../../features/store-localization/store-localization-types';
import _, { merge } from 'lodash';
import { FormValues } from './types';
import { defaultFormData } from './default-values';

const SCROLL_DURATION = 800;

const storeSubmitionSchema = yup.object().shape({
  industry: yup.object().shape({
    presentParticipleAdverbPreposition: yup.string().required(),
    presentParticipleAdverbPrepositions: yup.array()
      .of(yup.string().required())
      .length(2)
      .required(),
    verbPrepositionPronoun: yup.string().required(),
    presentParticiplePrepositionPronoun: yup.string().required(),
    verbAdverbPreposition: yup.string().required(),
    verbPreposition: yup.string().required(),
    verbAdverbPrepositionPronoun: yup.string().required(),
  }).required(),
  consumer: yup.object().shape({
    noun: yup.string().required(),
    nounPlural: yup.string().required(),
  }).required(),
  transaction: yup.object().shape({
    noun: yup.string().required(),
    nouns: yup.array()
      .of(yup.string().required())
      .length(2)
      .required(),
    nounPlural: yup.string().required(),
    verb: yup.string().required(),
    verbs: yup.array()
      .of(yup.string().required())
      .length(2)
      .required(),
    presentParticiple: yup.string().required(),
    verbPastTense: yup.string().required(),
    preposition: yup.string().required(),
    prepositions: yup.array()
      .of(yup.string().required())
      .length(2)
      .required(),
    verbNounPlural: yup.string().required(),
    verbPrepositionPronoun: yup.string().required(),
    nounPronounVerb: yup.string().required(),
    nounPluralPronounVerb: yup.string().required(),
    presentParticiplePreposition: yup.string().required(),
    verbPronoun: yup.string().required(),
  }).required(),
  verbPhrase1: yup.string().required(),
  verbPhrase2: yup.string().required(),
  verbPhrase3: yup.string().required(),
  verbPhrase4: yup.string().required(),
  verbPhrase5: yup.string().required(),
  verbPhrase6: yup.string().required(),
  verbPhrase7: yup.string().required(),
  verbPhrase8: yup.string().required(),
  verbPhrase9: yup.string().required(),
  sentence1: yup.string().required(),
  sentence2: yup.string().required(),
  adjective: yup.string().required(),
  establishmentNoun: yup.string().required(),
  productNoun: yup.string().required(),
  verb: yup.string().required(),
  verbWithUs: yup.string().required(),
  inStoreVerb: yup.string().required(),
  ctaVerb: yup.string().required(),
  ctaLocation: yup.string().required(),
  ctaVerbLocation: yup.string().required(),
  colorfillHexCode: yup.string().required(),
});

export const useStoreLocalizationForm = (
  setFormErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  contentContainerRef: React.RefObject<HTMLIonContentElement>,
  storeLocalizationData: StoreLocalization | undefined
) => {
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(storeSubmitionSchema),
    reValidateMode: 'onSubmit',
    defaultValues: defaultFormData
  });

  const [dataBuffer, setDataBuffer] = useState<FormValues>(defaultFormData);

  useEffect(() => {
    const errors = Object.keys(formErrors);
    if (errors.length > 0) {
      const modalContentElement = contentContainerRef.current;
      if (modalContentElement) {
        modalContentElement.scrollToTop(SCROLL_DURATION);
      }
      setFormErrorMessage('Invalid data. Please verify all fields.');
    } else {
      setFormErrorMessage('');
    }
  }, [formErrors]);

  useEffect(() => {
    if (storeLocalizationData) {      
      const mergedData = merge({}, defaultFormData, storeLocalizationData) as FormValues;
      reset(mergedData);
    } else {
      reset(defaultFormData);
    }
  }, [storeLocalizationData]);

  const formValues = watch();

  useEffect(() => {
    if (!_.isEqual(formValues, dataBuffer)) {
      setDataBuffer(formValues);
    }
  }, [formValues]);
  
  return {
    control,
    handleSubmit,
    reset,
    setValue,
    formValues: dataBuffer,
  };
};