import { format, parseISO } from "date-fns";
import { BusinessWithDetails } from "../../../../../features/businesses/businesses-types";
import { EditBusinessButton, EditBusinessIcon } from "../../styles";
import { TextLabel, Column, ColumnTitle, Row, Text, SectionHeader } from "./styles";

interface BusinessInfoProps {
  businessDetails: BusinessWithDetails;
  onOpenEditModal: () => void;
  isDesktopPlatform: boolean;
}

const BusinessInfo: React.FC<BusinessInfoProps> = ({
  businessDetails: {
    business,
    payment,
  },
  onOpenEditModal,
  isDesktopPlatform
}) => {
  const contactFullname = `${business.contactFirstName} ${business.contactLastName}`;
  
  const lastLoginOnDate = business.lastLoginOn
    ? format(parseISO(business.lastLoginOn), 'MM/dd/yyyy')
    : 'N/A';

  return (
    <Row>
      <Column>
        <SectionHeader>
          <ColumnTitle>Business Info</ColumnTitle>
          <EditBusinessButton
            isDesktopPlatform={isDesktopPlatform}
            onClick={onOpenEditModal}
            data-testid="edit-business-info-btn"
          >
            {isDesktopPlatform
              ? 'Edit'
              : <EditBusinessIcon isDesktopPlatform={false} />
            }
          </EditBusinessButton>
        </SectionHeader>
        <Text data-testid="business-details-b-id">
          <TextLabel>ID</TextLabel>: {business.id}
        </Text>
        <Text data-testid="business-details-b-cid">
          <TextLabel>Collinson ID</TextLabel>: {business.collinsonId || 'N/A'}
        </Text>
        <Text data-testid="business-details-b-slug">
          <TextLabel>Slug:</TextLabel> {business.slug || 'N/A'}
        </Text>
        <Text data-testid="business-details-b-bname">
          <TextLabel>Business name</TextLabel>: {business.businessName}
        </Text>
        <Text data-testid="business-details-b-cname">
          <TextLabel>Corporate name</TextLabel>: {business.corporateName || 'N/A'}
        </Text>
        <Text data-testid="business-details-b-pmail">
          <TextLabel>Primary email</TextLabel>: {business.primaryEmail || 'N/A'}
        </Text>
        <Text data-testid="business-details-b-cmail">
          <TextLabel>Contact email</TextLabel>: {business.contactEmail || 'N/A'}
        </Text>
        <Text data-testid="business-details-b-cfname">
          <TextLabel>Contact fullname</TextLabel>: {contactFullname.length ? contactFullname : 'N/A'}
        </Text>
        <Text data-testid="business-details-b-cphone">
          <TextLabel>Contact number</TextLabel>: {business.contactPhone || 'N/A'}
        </Text>
        <Text data-testid="business-details-b-acterms">
          <TextLabel>Accepted terms</TextLabel>: {typeof business.acceptedTerms === 'number'
            ? business.acceptedTerms ? 'Yes' : 'No'
            : 'N/A'
          }
        </Text>
        <Text data-testid="business-details-b-llogin">
          <TextLabel>Last login</TextLabel>: {lastLoginOnDate}
        </Text>
        <Text data-testid="business-details-b-offertype">
          <TextLabel>OfferType</TextLabel>: {(
            business?.businessWideOffers === false
          )  ? "Store Specific" : "Business Wide"}
        </Text>
      </Column>

      <Column>
        <ColumnTitle>Payment Info</ColumnTitle>
        <Text data-testid="business-details-p-ptotal">
          <TextLabel>Purchase total</TextLabel>: {
            payment.purchaseTotal
              ? `$${Number(payment.purchaseTotal).toFixed(2)}`
              : 'N/A'
            }
        </Text>
        <Text data-testid="business-details-p-pmethod">
          <TextLabel>Payment method</TextLabel>: {payment.paymentMethod || 'N/A'}
        </Text>
        <Text data-testid="business-details-p-pcode">
          <TextLabel>Promo code</TextLabel>: {
            payment.promoCode
              ? `${payment.promoCode.name}: $${Number(payment.promoCode.value).toFixed(2)} (${payment.promoCode.type})`
              : 'N/A'
          }
        </Text>
      </Column>
    </Row>
  );
}

export default BusinessInfo;
