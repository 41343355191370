import { businessesActionTypes } from './businesses-actions';
import { Business, BusinessWithDetails } from './businesses-types';
import { PaginatedResponse } from '../../types/api';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

interface BusinessesStore {
  list: PaginatedResponse<Business[]>;
  searchQuery: string;
  selected: BusinessWithDetails | undefined;
};

export const businessesInitialState = (): BusinessesStore =>
  ({ 
    list: {
      data: undefined,
      pagination: {
        page: 0,
        last: 0,
        limit: 0,
        total: 0
      }
    } as PaginatedResponse<Business[]>,
    searchQuery: '',
    selected: undefined,
  });

export const businessesReducer = (
  state = businessesInitialState(),
  action: Action<BusinessesStore>,
): any => {
  switch (action.type) {
    case businessesActionTypes.SET_BUSINESSES: {
      const { pagination, data } = action.payload.list;

      if (pagination.page === 1) {
        return {
          ...state,
          list: { data, pagination}
        };
      }

      if (pagination.page <= pagination.last) {        
        const merged = (
          data ? 
          state.list.data?.concat(data) || data : 
          state.list.data
        )

        return {
          ...state,
          list: {
            data: merged,
            pagination
          }
        };
      }
      return state;
    }

    case businessesActionTypes.SET_SELECTED_BUSINESS: {
      const { selected } = action.payload;

      if (selected) {
        return {
          ...state,
          selected,
        };
      }

      return state;
    }
  
    case businessesActionTypes.UPDATE_SELECTED_BUSINESS_INFO: {
      const { updatedBusinessInfo } = action.payload as any;

      const { selected } = state;
      
      if (selected) {
        return {
          ...state,
          selected: {
            ...selected,
            business: {
              ...selected.business,
              ...updatedBusinessInfo
            }
          },
        };
      }

      return state;
    }

    case businessesActionTypes.SET_BUSINESSES_SEARCH_QUERY: {
      const { searchQuery } = action.payload;

      if (searchQuery !== undefined) {
        return {
          ...state,
          searchQuery,
        };
      }

      return state;
    }

    case businessesActionTypes.CLEAR_BUSINESSES:
    case actionTypes.CLEAR_STATE:
      return {
        ...state,
        list: businessesInitialState().list,
      };

    default:
      return state;
  }
};
