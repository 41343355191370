import { IonText } from "@ionic/react";
import CopyItem from "../CopyItem";
import { Column, ContentContainer } from "../../styles";
import { getStoreParams } from "./utils";
import { useIsDesktopPlatform } from "../../../../../utils/hooks";
import { Store } from "../../../../../features/stores/stores-types";

interface StoreDataProps {
  store: Store;
}

const StoreParams: React.FC<StoreDataProps> = ({ store }) => {
  const isDesktopPlatform = useIsDesktopPlatform();
  
  return (
    <ContentContainer isDesktopPlatform={isDesktopPlatform}>
      <IonText>
        <h2>Params</h2>
        <h5>You can use params in your variable text to bring in database values using the below notation.</h5>
      </IonText>
      
      <Column>
        {getStoreParams(store).map(item =>
          <CopyItem
            key={`store-${item.name}`}
            {...item}
          />)
        }
      </Column>
    </ContentContainer>
  )
};

export default StoreParams;