import React, {ReactNode, useState, useEffect} from 'react';
import {
  IonItem, 
  IonLabel, 
  IonButton, 
  IonContent,
  IonList,
  IonItemDivider, 
  IonCol, 
  IonIcon,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton
} from '@ionic/react';
import styled from 'styled-components';
import { 
  Consumer, 
  ConsumerTransaction,
  PanelOptions 
} from '../../../../features/consumers/consumers-types';
import {format, parseISO} from 'date-fns';
import { arrowBackOutline } from 'ionicons/icons';
import Labels from '../Labels';
import {
  useGetConsumerTransactions
} from '../../../../features/consumers/consumers-resolver' ;
import {
  clearConsumerTransactions
} from '../../../../features/consumers/consumers-actions';
import {useAppState} from '../../../../shared/AppContext'
import { useParams } from 'react-router';
import Header from '../../../../components/header';
import Skeleton from './skeleton';
import Transaction from './transaction'
import PaginatedPage from '../../../../components/paginatedPage'
// ---------------------------------

const FieldLayout = styled.div`
  .label{
    color: var(--ion-color-tertiary);
    &:after{
      content: ': ';
    }
  }
  .value{
    color: var(--ion-color-dark);
  }
`
function Field(
  props: {
    label: string, 
    value: string | number | null
  }){
  const {value, label} = props
  if (!value) return null;
  return <FieldLayout>
    <IonLabel>
      <span className='label'>{label}</span>
      <span className='value'>{value}</span>
    </IonLabel>
  </FieldLayout>
}

const FieldsLayout = styled.div`
  display: grid;
  flex-direction: column;
  border-style: solid;
  border-color: #555;
  border-width: 2px;
`;

function Fields(props: {transaction: ConsumerTransaction}){
  const {transaction} = props  
  const allFields = Object.keys(transaction)

  return (
    <FieldsLayout>
      {
        !allFields ? null :
        allFields.map(
          (k, i)=>{
            return <Field 
              key={`${i}-${transaction[k]?.transactionId || Math.random()}`} 
              label={k} value={JSON.stringify(transaction[k], null, 2)}
            />    
        })
      }
    </FieldsLayout>
  )
}

const TransactionsLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

interface RouteParams {
  id: string
}

export default function ConsumerTransactions(){
  const {dispatch: appStateDispatch} = useAppState()
  const { id } =  useParams<RouteParams>();
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [
    isInfiniteScrollDisabled, 
    setIsInfiniteScrollDisabled
  ] = useState(false)
  const [
    {isLoading, error, data:{pagination, data: transactions}},
    getConsumerTransactions
  ] = useGetConsumerTransactions({
    paginated: true, consumerId: parseInt(id, 10)
  })

  useEffect(()=>{
    getConsumerTransactions({
      refresh: true, 
      params: {page, limit}
    })
  }, [])

  const changePage = (newPage: number) =>{
    appStateDispatch(clearConsumerTransactions())
    setPage(newPage)
    getConsumerTransactions({
      refresh: true,
      params: { page: newPage, limit }
    })
  }

  const changePageInfinite = (event: any) =>{
    setPage(page + 1)
    setTimeout(()=>{
      getConsumerTransactions({
        refresh: true,
        params: {
          page: page + 1,
          limit
        }
      })
      event.target.complete()
    }, 500)
    
    const txs = transactions?.length || []
    if(txs >= pagination?.total){
      setIsInfiniteScrollDisabled(true)
    }

  }

  if(isLoading) return <Skeleton />

  return (
    <PaginatedPage
      header={<Header title={`Consumer: ${id}`} backButton/>}
      page={page}
      pagination={pagination}
      changePagePagination={changePage}
      changePageInfinite={changePageInfinite}
      isInfiniteScrollDisabled={isInfiniteScrollDisabled}
    >
      <TransactionsLayout>
        {
          !transactions ? null :
          transactions.map((tx:ConsumerTransaction)=>(
            //<Fields transaction={tx}/>
            <Transaction transaction={tx} key={tx.id}/>
            )
          )
        }
      </TransactionsLayout>
    </PaginatedPage>
  )
}
