import { IonRouterLink } from "@ionic/react";
import { BusinessEntityWithDetails } from "../../../../../features/business-entities/business-entities-types";
import { TextLabel, Column, ColumnTitle, Row, Text, SectionHeader } from "./styles";

interface BusinessEntityInfoProps {
  businessEntityDetails: BusinessEntityWithDetails;
}

const BusinessEntityInfo: React.FC<BusinessEntityInfoProps> = ({
  businessEntityDetails,
}) => {  
  return (
    <Row>
      <Column>
        <SectionHeader>
          <ColumnTitle>Business Entity Info</ColumnTitle>
        </SectionHeader>
        <Text data-testid="be-id">
          <TextLabel>ID</TextLabel>: {businessEntityDetails.id}
        </Text>
        <Text data-testid="be-merchant-name">
          <TextLabel>Merchant Name</TextLabel>: {businessEntityDetails.merchantName || 'N/A'}
        </Text>
        <Text data-testid="be-legal-corp-name">
          <TextLabel>Legal Corporate Name</TextLabel>: {businessEntityDetails.legalCorporateName || 'N/A'}
        </Text>
        <Text data-testid="be-dba-name">
          <TextLabel>DBA Name</TextLabel>: {businessEntityDetails.dbaName || 'N/A'}
        </Text>
        <Text data-testid="be-street-address">
          <TextLabel>Street Address</TextLabel>: {businessEntityDetails.streetAddress || 'N/A'}
        </Text>
        <Text data-testid="be-city">
          <TextLabel>City</TextLabel>: {businessEntityDetails.city || 'N/A'}
        </Text>
        <Text data-testid="be-state">
          <TextLabel>State</TextLabel>: {businessEntityDetails.state || 'N/A'}
        </Text>
        <Text data-testid="be-country">
          <TextLabel>Country</TextLabel>: {businessEntityDetails.country || 'N/A'}
        </Text>
        <Text data-testid="be-postal-code">
          <TextLabel>Postal Code</TextLabel>: {businessEntityDetails.postalCode || 'N/A'}
        </Text>
        <Text data-testid="be-business-id">
          <IonRouterLink routerLink={`/business-entities/${businessEntityDetails.id}/business`}>
            <TextLabel>Business ID</TextLabel>: {businessEntityDetails.businessId}
          </IonRouterLink>
        </Text>
        <Text data-testid="be-business-name">
          <IonRouterLink routerLink={`/business-entities/${businessEntityDetails.id}/business`}>
            <TextLabel>Business Name</TextLabel>: {businessEntityDetails.businessName}
          </IonRouterLink>
        </Text>
        <Text data-testid="be-sole-proprietor-name">
          <TextLabel>Sole Proprietor Name</TextLabel>: {businessEntityDetails.soleProprietorName || 'N/A'}
        </Text>
        <Text data-testid="be-phone">
          <TextLabel>Phone</TextLabel>: {businessEntityDetails.phone || 'N/A'}
        </Text>
        <Text data-testid="be-plink-category-0">
          <TextLabel>Plink Category 0</TextLabel>: {businessEntityDetails.plinkCategory0}
        </Text>
        <Text data-testid="be-plink-category-1">
          <TextLabel>Plink Category 1</TextLabel>: {businessEntityDetails.plinkCategory1}
        </Text>
        <Text data-testid="be-plink-category-2">
          <TextLabel>Plink Category 2</TextLabel>: {businessEntityDetails.plinkCategory2}
        </Text>
        <Text data-testid="be-plink-category-3">
          <TextLabel>Plink Category 3</TextLabel>: {businessEntityDetails.plinkCategory3}
        </Text>
        <Text data-testid="be-aggregated-merchant-name">
          <TextLabel>Aggregated Merchant Name</TextLabel>: {businessEntityDetails.aggregatedMerchantName || 'N/A'}
        </Text>
      </Column>
    </Row>
  );
}

export default BusinessEntityInfo;