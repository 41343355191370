import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useGetBusinessEntityInfo } from '../../../features/business-entities/business-entities-resolver';
import BusinessInfo from './components/BusinessInfo';
import { BackButton, BackButtonContainer, Centered, MergeBusinessEntityButton, MergeBusinessEntityIcon } from './styles';
import { useIsDesktopPlatform } from '../../../utils/hooks';
import BusinessEntitiesList from './components/BusinessEntitiesList';
import EditBusinessModal from './components/EditBusinessModal ';

const BusinessDetails: React.FC = () => {
  const { id } = useParams() as any;

  const isDesktopPlatform = useIsDesktopPlatform();

  const [{
    isLoading: isBusinessEntityInfoLoading,
    error: businessEntityInfoError,
    data: businessEntityInfo,
  }, getBusinessEntityInfo] = useGetBusinessEntityInfo({ id });

  const [entitiesCount, setEntitiesCount] = useState(0);
  const [showEditBusinessModal, setShowEditBusinessModal] = useState(false);

  useEffect(() => {
    if (id) {
      getBusinessEntityInfo();
    }
  }, [id]);

  const businessInfoLoaded = businessEntityInfo && !isBusinessEntityInfoLoading;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {isDesktopPlatform
              ? <IonMenuButton data-testid="menu-button"></IonMenuButton>
              : <IonBackButton defaultHref={`/business-entities/${id}`} />
            }
          </IonButtons>
          <IonTitle>Business</IonTitle>
          <MergeBusinessEntityButton
            isDesktopPlatform={isDesktopPlatform}
            routerLink={`/business-entities/${id}/business/merge`}
          >
            <MergeBusinessEntityIcon isDesktopPlatform={isDesktopPlatform} />
            {isDesktopPlatform && 'Connect other Entities'}
          </MergeBusinessEntityButton>
        </IonToolbar>
      </IonHeader>

      {isDesktopPlatform &&
        <BackButtonContainer data-testid="back-button">
          <BackButton text="Back" defaultHref={`/business-entities/${id}`} />
        </BackButtonContainer>
      }

      {(isBusinessEntityInfoLoading) && (
        <Centered>
          <IonSpinner data-testid="page-spinner-b-details" title="page-spinner" name="crescent" />
        </Centered>
      )}

      {(businessInfoLoaded) && (
        <IonContent>
          {businessInfoLoaded &&
            <BusinessInfo
              businessEntityDetails={businessEntityInfo}
              entitiesCount={entitiesCount}
              onOpenEditModal={() => setShowEditBusinessModal(true)}
              isDesktopPlatform={isDesktopPlatform}
            />
          }
          
          <BusinessEntitiesList
            businessId={businessEntityInfo.businessId}
            setEntitiesCount={setEntitiesCount}
          />
        </IonContent>
      )}

      <EditBusinessModal
        businessId={businessEntityInfo?.businessId}
        businessInfo={{ expectedLocationsCount: businessEntityInfo?.businessExpectedLocationsCount || 0}}
        isOpen={showEditBusinessModal}
        onClose={() => setShowEditBusinessModal(false)}
      />

      <IonAlert
        isOpen={!!businessEntityInfoError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />
    </IonPage>
  );
};

export default BusinessDetails;
