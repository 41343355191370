import styled from 'styled-components';
import { bagAddOutline, pencilOutline } from 'ionicons/icons';
import { IonBackButton, IonButton, IonButtons, IonIcon } from '@ionic/react';

export const BackButtonContainer = styled(IonButtons).attrs({
  slot: 'start'
})`
  margin-top: 10px;
  margin-bottom: auto;
  margin-left: 2.5%;
`;

export interface ButtonProps {
  isDesktopPlatform: boolean;
}

export const EditFIIcon = styled(IonIcon).attrs<ButtonProps>(props => ({
  size: props.isDesktopPlatform ? 'large' : 'medium',
  slot: 'start',
  icon: bagAddOutline
}))<ButtonProps>`
  margin: ${props => props.isDesktopPlatform ? '10px 15px 10px 0' : '0'};
  icon: pencilOutline;
`;

export const EditFIButton = styled(IonButton).attrs({
  color: 'light',
  slot: 'end'
})<ButtonProps>`
  font-size: 12px;
  height: ${props => props.isDesktopPlatform ? '30px' : '20px'};
`;

export const BackButton = styled(IonBackButton)`
  margin-bottom: 10px;
`;

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
