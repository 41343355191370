import styled from 'styled-components';


export const Centered = styled.div`
width: 90%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 0 auto;
`;
