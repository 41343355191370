import React, {ReactNode, useState} from 'react';
import {
  IonItem, IonLabel, IonButton, IonList,
  IonItemDivider, IonCol, IonIcon
} from '@ionic/react'
import styled from 'styled-components';
import {Consumer} from '../../../features/consumers/consumers-types';
import {format, parseISO} from 'date-fns';
import {
  chevronForwardOutline, 
  chevronDownOutline
} from 'ionicons/icons';

// ---------------------------------

const formatDate = (dte:string | undefined)=>{
  if (!dte){
    return 'N/A'
  }
  return format(parseISO(dte), 'MM/dd/yyyy')
}


const LabelsLayout = styled.div`
  display: flex;
  flex-direction: column;
`

type LabelsProps = {
  consumer: any
  fields: string[]
  useFullName: boolean
}

export default function Labels(props: LabelsProps){ 
  const {consumer, fields, useFullName} = props
  const dateFields = ['createOn']

  let info: {[key: string]: any} = {}
  let filtered_fields = fields
  if (useFullName){
    filtered_fields = fields.filter(
      (f) => !(f === 'firstName' || f === 'firstName')
    )
    info.fullName = (
      consumer?.first_name && consumer?.last_name 
      ? `${consumer.first_name} ${consumer.last_name}`
      : null
    )
  }  

  filtered_fields.forEach((fld)=>{
    // Requires to be formatted as date?
    if (dateFields.some((element) => fld === element)){
      info[fld] = formatDate(consumer[fld])
    }
    // No format apply:
    else{
      info[fld] = consumer[fld]
    }
  })

  // Remove null values:
  Object.keys(info).forEach((k)=>{
    if (!info[k]) delete info[k]
  })

  return (
    <LabelsLayout>
      {
        Object.keys(info).map(
          (k, i)=> (
            <IonLabel 
              key={`${i}-${k}-${info.consumer_id}`}>
              {info[k]}
            </IonLabel>
          )
        )
      }
    </LabelsLayout>
  )
} 
Labels.defaultProps = {
  useFullName: true
}

