import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import {
  StoreDemographics
} from '../../../../../features/store-demographics/store-demographics-types';

const SCROLL_DURATION = 800;

export interface FormValues {
  territoryRadius: number;
  gender: number;
  ageMax: number;
  ageMin: number;
  interests: string;
  latitude: number;
  longitude: number;
}

const storeDemographicsSchema = yup.object().shape({
  territoryRadius: yup
    .number()
    .nullable()
    .typeError('Invalid radius')
    .transform((parsedValue, value) => value === "" ? null : parsedValue),
  gender: yup
    .number()
    .nullable()
    .typeError('Invalid gender')
    .transform((parsedValue, value) => value === "" ? null : parsedValue),
  ageMax: yup
    .number()
    .nullable()
    .typeError('Invalid max age')
    .transform((parsedValue, value) => value === "" ? null : parsedValue),
  ageMin: yup
    .number()
    .nullable()
    .typeError('Invalid min age')
    .transform((parsedValue, value) => value === "" ? null : parsedValue)
    .when('ageMax', {
      is: null,
      then: (schema) => schema,
      otherwise: (schema) => schema.max(yup.ref('ageMax')),
    }),
  interests: yup
    .string()
    .nullable()
    .typeError('Invalid interests')
    .transform((parsedValue, value) => value === "" ? null : parsedValue),
  latitude: yup
    .number()
    .nullable()
    .typeError('Invalid latitude')
    .transform((parsedValue, value) => value === "" ? null : parsedValue),
  longitude: yup
    .number()
    .nullable()
    .typeError('Invalid longitude')
    .transform((parsedValue, value) => value === "" ? null : parsedValue),
});

export const useStoreDemographicsForm = (
  setFormErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  modalContentRef: React.RefObject<HTMLIonContentElement>,
  storeDemographics: StoreDemographics | undefined,
) => {
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(storeDemographicsSchema),
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    const errors = Object.keys(formErrors);
    if (errors.length > 0) {
      const modalContentElement = modalContentRef.current;
      if (modalContentElement) {
        modalContentElement.scrollToTop(SCROLL_DURATION);
      }
      setFormErrorMessage('Invalid data. Please verify all fields.');
    } else {
      setFormErrorMessage('');
    }
  }, [formErrors]);

  useEffect(() => {
    if (storeDemographics) {
      reset(storeDemographics as FormValues);
    }
  }, [storeDemographics]);
  
  return {
    control,
    handleSubmit,
  };
};