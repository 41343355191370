import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextFieldInputAutoComplete from '../../components/form/TextFieldInputAutoComplete';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonPage,
  IonRow,
  IonSpinner,
  IonText
} from '@ionic/react';
import styled from 'styled-components';
import { useLogin } from '../../features/auth/auth-resolver';
import { FetchError } from '../../features/fetch/fetch-types';

type FormValues = {
  email: string;
  password: string;
};

const Container = styled(IonPage)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
`;

const StyledButton = styled(IonButton)`
  font-weight: bold;
  margin: 2em auto;
  width: 14em;
`;

const InputErrorText = styled(IonText)`
  align-self: start;
`;

const Login: React.FC = () => {
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [{ isLoading }, doLogin] = useLogin();

  const history = useHistory();

  const loginSchema = yup.object().shape({
    email: yup.string().required('Required'),
    password: yup.string().required('Required')
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const logInRes = await doLogin({
      username: data.email,
      password: data.password
    });

    if (logInRes && (logInRes as FetchError).errorCode) {
      setErrorMessage(logInRes.message);
      setErrors(true);
    } else {
      history.push('/businesses');
    }
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <TextFieldInputAutoComplete
          name="email"
          type="email"
          control={control}
          placeholder="Email"
        />
        <InputErrorText color="danger">
          {formErrors.email && formErrors.email.message}
        </InputErrorText>
        <TextFieldInputAutoComplete
          name="password"
          type="password"
          control={control}
          placeholder="Password"
        />
        <InputErrorText color="danger">
          {formErrors.password && formErrors.password.message}
        </InputErrorText>
        <IonRow>
          <IonCol className="ion-text-center">
            <StyledButton type="submit" expand="block" disabled={isLoading}>
              LOG IN
            </StyledButton>
          </IonCol>
        </IonRow>
      </StyledForm>
      <IonAlert
        isOpen={errors}
        cssClass="modal-dark-mode"
        header="Error logging in"
        message={errorMessage}
        buttons={['OK']}
      />
    </Container>
  );
};

export default Login;
